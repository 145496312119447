import React from 'react';
import { connect } from 'react-redux';
import { asyncComponent } from '@peloton/code-splitting';
import type { State as EnvState } from '@peloton/env';
import { getIsProdEnv } from '@peloton/env';
import { useStudioKeyValueData } from '@studio/copy';

type Props = {
  isProd: boolean;
};

const PanelComponent = asyncComponent(
  () =>
    import('./feature-toggles/Panel' /* webpackChunkName: "StudioFeatureTogglePanel" */),
);

const FeatureTogglePanel: React.FC<React.PropsWithChildren<Props>> = ({ isProd }) => {
  const flyoutAriaLabel = useStudioKeyValueData('toggleViewer.ariaLabel');

  return isProd ? null : <PanelComponent flyoutAriaLabel={flyoutAriaLabel} />;
};

const mapStateToProps = (state: EnvState) => ({
  isProd: getIsProdEnv(state),
});

export default connect(mapStateToProps)(FeatureTogglePanel);
