import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { Image } from '@ecomm/image';
import { useStudioImageData } from '@studio/copy';
import { Layout } from '@studio/layout';
import { MetadataTags } from '@studio/metadata';
import AmenitiesSection from './AmenitiesSection';
import { trackViewedHomePage } from './analytics';
import HomeHero from './HomeHero';
import InstructorsVideo from './InstructorsVideo';
import StudioInformation from './StudioInformation';

const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
  const {
    image: {
      mobile: { url: imageSrc, description },
    },
  } = useStudioImageData('studioSite.pgHome.Amenities');

  React.useEffect(() => {
    trackViewedHomePage();
  }, []);

  return (
    <Layout background="offWhite">
      <MetadataTags canonicalLink={Links.studioHome} id="studioSite.pgHome" />
      <HomeHero />
      <>
        <StudioInformation />
        <StyledImage
          src={imageSrc}
          alt={description}
          breakpointOptions={{
            mobile: {
              src: imageSrc,
              width: 380,
            },
            tablet: {
              src: imageSrc,
              width: 768,
            },
            desktop: {
              src: imageSrc,
              width: 1024,
            },
          }}
        />
        <AmenitiesSection />
        <InstructorsVideo />
      </>
    </Layout>
  );
};

export default Page;

const StyledImage = styled(Image)`
  width: 100%;
`;
