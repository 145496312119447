import type { EnvironmentFlags as TEnvironmentFlags } from '@peloton/env';
import { isCIEnv } from '@peloton/env';

export type EnvironmentFlags = TEnvironmentFlags;

const isProdEnv = (hostname: string): boolean =>
  process.env.REACT_APP_API_ENV === 'prod' ||
  (hostname.indexOf('studio.') === 0 && !isUatEnv(hostname));

const isUatEnv = (hostname: string): boolean =>
  !isStagingEnv(hostname) &&
  (process.env.REACT_APP_API_ENV === 'uat' ||
    hostname.indexOf('uat-studio') === 0 ||
    hostname.indexOf('.uat.') !== -1 ||
    hostname.indexOf('.test.') !== -1 ||
    hostname.indexOf('.secret.') !== -1 ||
    hostname.indexOf('.xtest.') !== -1);

const isStagingEnv = (hostname: string): boolean =>
  process.env.REACT_APP_API_ENV === 'staging' || hostname.indexOf('--lit') !== -1;

const isStageNEnv = (hostname = window.location.hostname): boolean => {
  const regExMatch = /--api-core-(.*)--stage/.exec(hostname);
  return regExMatch && regExMatch[1] ? true : false;
};

const isUatProdOrProdTestEnv = (): boolean => false; // no uat-prod-staging or prod-test environment exists

const isLocalEnv = (hostname: string): boolean =>
  process.env.REACT_APP_API_ENV === 'local' ||
  hostname.indexOf('local-studio') === 0 ||
  hostname.indexOf('local-uikit') === 0;

const environmentFlags = (hostname = window.location.hostname): EnvironmentFlags => {
  return {
    isProd: isProdEnv(hostname),
    isProdTest: isUatProdOrProdTestEnv(),
    isStaging: isStagingEnv(hostname),
    isStageN: isStageNEnv(hostname),
    isLocal: isLocalEnv(hostname),
    isUat: isUatEnv(hostname),
    isUatProd: isUatProdOrProdTestEnv(),
    isCI: isCIEnv(),
    isTest: false,
  };
};

export default environmentFlags;
