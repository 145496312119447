import { brand, Icon, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { GreaterThan, LessThan } from '@peloton/responsive';
import { media } from '@peloton/styles';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { useStudioKeyValueData } from '@studio/copy';
import { UpdatedPelotonStudios } from '@studio/icons';
import { PanelNav } from '@studio/panel-nav';
import HeaderAccountSection from './HeaderAccountSection';
import HeaderButton from './HeaderButton';
import HeaderClassDropdown from './HeaderClassDropdown';
import HeaderExperienceDropdown from './HeaderExperienceDropdown';

const HeaderNav: React.FC<React.PropsWithChildren<unknown>> = React.memo(() => {
  const [panelNavOpen, setPanelNavOpen] = React.useState(false);
  const handleRequestClose = () => setPanelNavOpen(false);
  const handleNavOpen = (event: React.SyntheticEvent) => {
    (event.target as HTMLButtonElement).focus();
    setPanelNavOpen(true);
  };
  const logoText = useStudioKeyValueData('header.logo');
  const panelNavButtonLabel = useStudioKeyValueData('header.panelNavButtonLabel');
  const panelNavLabel = useStudioKeyValueData('header.panelNavLabel');
  const skipNavLabel = useStudioKeyValueData('header.skipNavLabel');

  return (
    <NavContainer data-test-id="headerNav">
      <SkipNav href="#main-content">{skipNavLabel}</SkipNav>
      <LogoLink to={Links.studioHome} aria-label={logoText} isActive={() => false}>
        <UpdatedStyledPelotonStudios />
      </LogoLink>
      <NavLinks>
        <GreaterThan breakpoint="desktop">
          <GreaterThan breakpoint="desktop">
            <HeaderExperienceDropdown />
          </GreaterThan>
          <HeaderAccountSection />
        </GreaterThan>
        <HeaderClassDropdown />
        <LessThan breakpoint="desktopLarge">
          <li>
            <HeaderButton
              aria-label={panelNavButtonLabel}
              onClick={handleNavOpen}
              data-test-id="panelNavButton"
            >
              <Icon primaryColor="white" name="mobileMenu" />
            </HeaderButton>
          </li>
        </LessThan>
      </NavLinks>
      <PanelNav
        isOpen={panelNavOpen}
        ariaLabel={panelNavLabel}
        handleRequestClose={handleRequestClose}
        data-test-id="panelNav"
      />
    </NavContainer>
  );
});

HeaderNav.displayName = 'HeaderNav';

const NavContainer = styled.nav`
  align-items: center;
  background-color: ${brand.darkest};
  color: ${white};
  display: flex;
  height: 52px;
  justify-content: space-between;
  padding-left: 15px;
  position: sticky;
  top: 0;
  z-index: 2;

  ${media.tablet`
    padding-left: 30px;
    height: 66px;
  `}

  ${media.desktopLarge`
    padding-right: 30px;
  `}
  padding-top: 9px;
  padding-bottom: 9px;
  ${media.tablet`
    padding-top: 12px;
    padding-bottom: 12px;`}
  ${media.desktopLarge`
    padding-top: 16px;
    padding-bottom: 16px;`}

  svg {
    fill: currentColor;
    pointer-events: none;
  }
` as React.ComponentType<React.PropsWithChildren<unknown>>;

const NavLinks = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const SkipNav = styled.a`
  background: #181a1d;
  color: #fff;
  position: absolute;
  transform: translateY(-1000%);
  &:focus {
    transform: translateY(0%);
  }
`;

const LogoLink = styled(Link)`
  align-items: center;
  display: flex;
` as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const UpdatedStyledPelotonStudios = styled(UpdatedPelotonStudios)`
  fill: ${white};
  height: 8px;
  width: 117px;
  ${media.tablet`
    height: 16px;
    width: 228px;
  `}
`;

export default HeaderNav;
