import { useEffect, useState } from 'react';
import { fetchSessionStatus } from './api';

function useClientIP() {
  const [ip, setIp] = useState<string | undefined>(undefined);
  useEffect(() => {
    const session = async () => {
      try {
        const res = await fetchSessionStatus();
        setIp(res?.data?.clientIpAddress || '');
      } catch (e) {
        setIp('');
      }
    };

    session();
  }, []);

  return ip;
}

export default useClientIP;
