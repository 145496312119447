import React, { useEffect } from 'react';
import { useStudioOAuth } from '@studio/auth';

export const generateRedirectPath = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const redirect = queryParams.get('redirectTo') || '';
  let redirectUrl;

  try {
    redirectUrl = new URL(redirect);
  } catch (_) {
    redirectUrl = new URL(window.location.origin);
  }

  return redirectUrl.pathname + redirectUrl.search;
};

function Login() {
  const { loginWithRedirect } = useStudioOAuth(generateRedirectPath(), true);
  const queryParams = new URLSearchParams(window.location.search);
  const loginHint = queryParams.get('login_hint') || '';

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        login_hint: loginHint,
      },
    });
  }, [loginWithRedirect, loginHint]);

  return <></>;
}

export default Login;
