import React from 'react';
import { Redirect } from 'react-router';
import {
  toLocaleFromHostname,
  theUserIsInTheUnitedStates,
} from '@peloton/internationalize';
import OAuthRedirect from '@studio/auth/OAuthRedirect';
import SignupRedirect from '@studio/auth/SignupRedirect';
import { HomePage } from '@studio/pg-home';
import { Login } from '@studio/pg-login';
import { Logout } from '@studio/pg-logout';
import { SupportPage } from '@studio/pg-support';
import { TeamUpAccount } from '@studio/pg-teamup-account';
import { TeamUpSchedule } from '@studio/pg-teamup-schedule';
import { UnsubscribePage } from '@studio/pg-unsubscribe';
import { RegionSlug } from '@studio/regions';
import type { RouteTree } from './Routes';

export const routeTree: RouteTree = [
  {
    path: '/account',
    component: () => {
      /** Clumsy naming helps for reasoning about centralized intl configs */
      const locale = toLocaleFromHostname();
      if (theUserIsInTheUnitedStates(locale)) {
        return <Redirect to={'/new-york/account'} />;
      } else {
        return <Redirect to={'/london/account'} />;
      }
    },
  },
  {
    path: '/email-unsubscribe',
    component: UnsubscribePage,
  },
  {
    path: '/schedule',
    component: () => {
      /** Clumsy naming helps for reasoning about centralized intl configs */
      const locale = toLocaleFromHostname();
      if (theUserIsInTheUnitedStates(locale)) {
        return <Redirect to={'/new-york/schedule'} />;
      } else {
        return <Redirect to={'/london/schedule'} />;
      }
    },
  },
  {
    path: '/callback',
    component: OAuthRedirect,
  },
  {
    path: '/signup/callback',
    component: SignupRedirect,
  },
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/support',
    component: SupportPage,
  },
  {
    path: '/forgot-password',
    component: () => {
      window.location.href = 'https://members.onepeloton.com/forgot-password';
      return null;
    },
  },
  {
    path: `/${RegionSlug.NewYork}/schedule`,
    component: () => <TeamUpSchedule {...{ region: RegionSlug.NewYork }} />,
  },
  {
    path: `/${RegionSlug.London}/schedule`,
    component: () => <TeamUpSchedule {...{ region: RegionSlug.London }} />,
  },
  {
    path: `/${RegionSlug.NewYork}/account`,
    component: () => <TeamUpAccount {...{ region: RegionSlug.NewYork }} />,
  },
  {
    path: `/${RegionSlug.London}/account`,
    component: () => <TeamUpAccount {...{ region: RegionSlug.London }} />,
  },
  {
    path: '/teamup/login',
    component: Login,
  },
  {
    path: '/teamup/logout',
    component: Logout,
  },
];
