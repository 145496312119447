import { useDecision } from '@optimizely/react-sdk';
import { Body, Eyebrow, spacing, Button } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { media } from '@peloton/styles';
import { useStudioValuePropData, useStudioKeyValueData } from '@studio/copy';
import { InquiryModal } from '@studio/inquiry-form';

const AmenitiesSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [showGroupInquiryForm] = useDecision('studio_group_inquiry_form', {
    autoUpdate: true,
  });
  const groupInquiryFormEnabled = showGroupInquiryForm?.enabled;
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const { title: loungeTitle, body: loungeBody } = useStudioValuePropData(
    'studioSite.pgHome.valueProps.lounge',
  );
  const { title: lockersTitle, body: lockersBody } = useStudioValuePropData(
    'studioSite.pgHome.valueProps.lockers',
  );
  const { title: equipmentTitle, body: equipmentBody } = useStudioValuePropData(
    'studioSite.pgHome.valueProps.equipment',
  );
  const { title: cafeTitle, body: cafeBody } = useStudioValuePropData(
    'studioSite.pgHome.valueProps.cafe',
  );
  const inquireButtonText = useStudioKeyValueData('studioSite.pgHome.inquireButton');

  return (
    <>
      <ContainerUpdated>
        <AmenityGrid data-test-id="tu-update-amenity-container">
          <AmenityGroupUpdated>
            <Amenity description={loungeBody} header={loungeTitle} />
          </AmenityGroupUpdated>
          <AmenityGroupUpdated>
            <Amenity description={lockersBody} header={lockersTitle} />
          </AmenityGroupUpdated>
          <AmenityGroupUpdated>
            <Amenity description={equipmentBody} header={equipmentTitle} />
          </AmenityGroupUpdated>
          <AmenityGroupUpdated>
            <Amenity description={cafeBody} header={cafeTitle} />
          </AmenityGroupUpdated>
        </AmenityGrid>
        {groupInquiryFormEnabled && (
          <BookButtonContainer>
            <Button
              type="button"
              size="small"
              onClick={() => setIsModalOpen(true)}
              data-test-id="inquire-button"
              style={{
                maxWidth: '100%',
                width: '260px',
                padding: '10px',
                height: 'auto',
              }}
            >
              {inquireButtonText}
            </Button>
          </BookButtonContainer>
        )}
      </ContainerUpdated>
      {groupInquiryFormEnabled && (
        <InquiryModal setIsOpen={setIsModalOpen} isOpen={isModalOpen} />
      )}
    </>
  );
};

type AmenityProps = {
  description: string;
  header: string;
};

const Amenity: React.FC<React.PropsWithChildren<AmenityProps>> = ({
  description,
  header,
}) => (
  <AmenityContainerUpdated data-test-id="tu-update-amenity">
    <HeaderUpdated is="h3" size="medium">
      {header}
    </HeaderUpdated>
    <DescriptionUpdated size="small">
      <Markdown content={description} />
    </DescriptionUpdated>
  </AmenityContainerUpdated>
);

export default AmenitiesSection;

const ContainerUpdated = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 50px;
  padding-top: 60px;
  max-width: 1120px;
  margin: 0 auto;
`;

const AmenityGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  justify-items: center;

  ${media.tabletXLarge`
    grid-template-columns: 2fr 2fr;
  `}

  ${media.desktopLarge`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 36px;
  `}
`;

const AmenityGroupUpdated = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const AmenityContainerUpdated = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
`;

const BookButtonContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const HeaderUpdated = styled(Eyebrow)`
  padding-bottom: ${spacing[12]};
`;

const DescriptionUpdated = styled(Body)`
  width: 260px;

  ${media.tabletXLarge`
    width: 210px;
  `}
`;
