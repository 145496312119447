import type { IconNames } from '@pelotoncycle/design-system';
import { useValuePropQuery } from '../hooks.generated';
import type { ValueProp } from '../models.generated';

export type StudioValuePropDataType = {
  body: string;
  iconName: IconNames;
  title: string;
};

const toTextWithFallback = (
  text: string | undefined,
  key: string,
  hasNoValuePropText: boolean,
) => {
  if (hasNoValuePropText) {
    return `Studio Value Prop: ${key}`;
  } else if (!text) {
    return '';
  } else {
    return text;
  }
};

export const transformStudioValuePropData = (
  response: any,
  key: ValueProp,
): StudioValuePropDataType => {
  const body = response.data?.valueProp?.body;
  const iconName = response.data?.valueProp?.svgIcon?.iconComponent ?? undefined;
  const title = response.data?.valueProp?.title;

  const hasNoValuePropText = !title && !body;

  return {
    title: toTextWithFallback(title, key, hasNoValuePropText)!,
    body: toTextWithFallback(body, key, hasNoValuePropText)!,
    iconName,
  };
};

const useStudioValuePropData = (id: ValueProp): StudioValuePropDataType => {
  const res = useValuePropQuery({ variables: { id } });

  return transformStudioValuePropData(res, id);
};

export default useStudioValuePropData;
