import styled from 'styled-components';
import type { PeloLink } from '@peloton/external-links';
import { media } from '@peloton/styles';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';

const NavLink = styled(Link)`
  cursor: pointer;
  text-transform: capitalize;
  margin-right: 12px;

  ${media.tablet`
    margin-right: 30px;
  `}
` as React.ComponentType<
  React.PropsWithChildren<LinkProps & { to?: PeloLink; teamupenabled?: boolean }>
>;

export default NavLink;
