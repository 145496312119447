import { Label } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { media } from '@peloton/styles';
import { unorphan } from '@peloton/text';
import { InlineLink } from '@ecomm/links';
import { useStudioKeyValueData } from '@studio/copy';

const BANNER_TEXT_LENGTH_THRESHOLD = 3;

const HeaderBanner: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const bannerLabel = useStudioKeyValueData('header.bannerLabel');
  const bannerText = useStudioKeyValueData('layout.headerBanner');
  const isBannerOn = bannerText.length > BANNER_TEXT_LENGTH_THRESHOLD;

  if (isBannerOn) {
    return (
      <BannerUpdated
        role="banner"
        aria-label={bannerLabel}
        is="p"
        size="responsive"
        weight="medium"
        data-test-id="tu-update-banner"
      >
        <Markdown
          content={unorphan(2, bannerText)}
          markdown={{ renderers: { link: InlineLink } }}
        />
        {children}
      </BannerUpdated>
    );
  }

  return <></>;
};

export default HeaderBanner;

const BannerUpdated = styled(Label)`
  padding: 16px 20px;
  text-align: center;

  ${media.tabletXLarge`
    padding: 16px 40px;
  `}
`;
