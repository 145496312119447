import { useCtaWithUrlQuery } from '../hooks.generated';
import type { CtaWithUrl } from '../models.generated';

export type StudioCtaWithUrlDataType = {
  key: CtaWithUrl;
  targetBlank: boolean;
  text: string;
  url: string;
};

export const transformCtaWithUrlData = (
  response: any,
  key: CtaWithUrl,
): StudioCtaWithUrlDataType => {
  const text = response.data?.ctaWithUrl?.text;
  const targetBlank = response.data?.ctaWithUrl?.targetBlank;
  const url = response.data?.ctaWithUrl?.link?.url;

  const fallBackText = `CtaWithUrl: ${key}`;

  return {
    key,
    targetBlank: !!targetBlank,
    text: text || fallBackText,
    url: url || fallBackText,
  };
};

const useStudioCtaWithUrlData = (id: CtaWithUrl): StudioCtaWithUrlDataType => {
  const res = useCtaWithUrlQuery({ variables: { id } });

  return transformCtaWithUrlData(res, id);
};

export default useStudioCtaWithUrlData;
