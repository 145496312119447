const deDE = require('./de-DE.generated.json');
const enAU = require('./en-AU.generated.json');
const enCA = require('./en-CA.generated.json');
const enGB = require('./en-GB.generated.json');
const enUS = require('./en-US.generated.json');

export default {
  'de-DE': deDE,
  'en-AU': enAU,
  'en-CA': enCA,
  'en-GB': enGB,
  'en-US': enUS,
};
