import type { EnvironmentFlags } from '@peloton/env';
import { Vendor } from '@ecomm/vendor-keys/redux';

enum DriftKey {
  Prod = 'iwmz4u839bya',
}

enum SegmentKey {
  Prod = 'sWf5filtuddYPRacjQVm0AcNcljv24CY',
  UAT = 'Hse0BUdAO8LciDcwjUNTiBTnHI1HPbgZ',
}

const getKeys = ({ isProd }: EnvironmentFlags) => {
  return {
    [Vendor.Drift]: DriftKey.Prod,
    segment: isProd ? SegmentKey.Prod : SegmentKey.UAT,
  };
};

export default getKeys;
