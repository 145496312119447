import { Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import { connect } from 'react-redux';
import type { UserSelectorState } from '@peloton/auth';
import { isUserSignedIn } from '@peloton/auth';
import { Links } from '@peloton/links';
import { useStudioOAuth } from '@studio/auth';
import { useStudioKeyValueData } from '@studio/copy';
import { DarkLink } from '@studio/panel-nav/links';
import { RegionSlug } from '@studio/regions';

type Props = {
  handleRequestClose: () => void;
  isLoggedIn: boolean;
};

const PanelAccountSection: React.FC<React.PropsWithChildren<Props>> = ({
  handleRequestClose,
  isLoggedIn,
}) => {
  const headerMyNYClassesText = useStudioKeyValueData('studioSite.header.myNYClasses');
  const headerMyLondonClassesText = useStudioKeyValueData(
    'studioSite.header.myLondonClasses',
  );
  const logOutText = useStudioKeyValueData('header.logOut');
  const logInText = useStudioKeyValueData('header.logIn');
  const { loginWithRedirect, logout } = useStudioOAuth();

  return isLoggedIn ? (
    <>
      <DarkLink
        data-test-id="navPanelMyNYClassesLink"
        onClick={handleRequestClose}
        to={Links.toStudioAccount(RegionSlug.NewYork)}
      >
        <Eyebrow is="span" size="medium">
          {headerMyNYClassesText}
        </Eyebrow>
      </DarkLink>

      <DarkLink
        data-test-id="navPanelMyLondonClassesLink"
        onClick={handleRequestClose}
        to={Links.toStudioAccount(RegionSlug.London)}
      >
        <Eyebrow is="span" size="medium">
          {' '}
          {headerMyLondonClassesText}
        </Eyebrow>
      </DarkLink>
      <DarkLink
        data-test-id="navPanelLogOutLink"
        onClick={() => {
          handleRequestClose();
          logout({});
        }}
      >
        <Eyebrow is="span" size="medium">
          {' '}
          {logOutText}
        </Eyebrow>
      </DarkLink>
    </>
  ) : (
    <DarkLink
      data-test-id="navPanelLogInLink"
      onClick={() => {
        handleRequestClose();
        loginWithRedirect();
      }}
    >
      <Eyebrow is="span" size="medium">
        {logInText}
      </Eyebrow>
    </DarkLink>
  );
};

const mapStateToProps = (state: UserSelectorState) => ({
  isLoggedIn: isUserSignedIn(state),
});
export default connect(mapStateToProps)(PanelAccountSection);
