import { oneLine } from '@peloton/text';

const _focusableElementsQuery = (shouldIncludeElementsWithTabIndex: boolean) =>
  oneLine(`
    ${shouldIncludeElementsWithTabIndex ? '[tabindex]:not([tabindex="-1"]),' : ''}
    button:not([disabled]),
    [href],
    input:not([disabled]),
    select:not([disabled]),
    textarea:not([disabled])
  `);

const _isActiveElementLastFocusable = (
  focusableElements: HTMLElement[],
  indexOfActiveElement: number,
) => {
  return focusableElements.length === indexOfActiveElement + 1;
};

const _indexOfNextElementToFocus = (
  focusableElements: HTMLElement[],
  indexOfActiveElement: number,
) => {
  const isActiveElementLastFocusableElement = _isActiveElementLastFocusable(
    focusableElements,
    indexOfActiveElement,
  );

  return isActiveElementLastFocusableElement ? 0 : indexOfActiveElement + 1;
};

const _focusableElementsInContainer = (
  containerQuerySelector: string,
  shouldIncludeElementsWithTabIndex: boolean,
): HTMLElement[] =>
  Array.from(
    document.querySelectorAll(
      `${containerQuerySelector} ` +
        _focusableElementsQuery(shouldIncludeElementsWithTabIndex),
    ),
  );

/**
 * If the passed element is focusable, that element will be focused.
 * If not, the first focusable child of the element will be focused.
 * If neither the element nor its children are focusable, nothing will happen.
 * @param element The element to focus, or within which an element will be focused.
 */
const passFocusToElement = (element: HTMLElement) => {
  const focusableElementsQuery = _focusableElementsQuery(false);

  if (element.matches(focusableElementsQuery)) {
    element.focus();
  } else {
    const focusableChild = element.querySelector(focusableElementsQuery);
    if (focusableChild instanceof HTMLElement) {
      focusableChild.focus();
    }
  }
};

const passFocusToNextInQuerySelector = (
  containerQuerySelector: string,
  { shouldIncludeElementsWithTabIndex = false },
) => {
  const focusableElements = _focusableElementsInContainer(
    containerQuerySelector,
    shouldIncludeElementsWithTabIndex,
  );

  if (focusableElements.length === 1) return;

  const indexOfActiveElement = focusableElements.findIndex(
    element => element === document.activeElement,
  );
  const indexOfNextElementToFocus = _indexOfNextElementToFocus(
    focusableElements,
    indexOfActiveElement,
  );

  const nextElementToFocus = focusableElements[indexOfNextElementToFocus];

  nextElementToFocus.focus();
};

export default {
  passFocusToElement,
  passFocusToNextInQuerySelector,
};
