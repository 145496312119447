import type { Locale } from '@peloton/internationalize';
import { toLocaleFromHostname } from '@peloton/internationalize';

const defaultState = toLocaleFromHostname(window.location.hostname);

const reducer = (state: Locale = defaultState) => state;

export type ReducerState = {
  locale: Locale;
};

export default reducer;
