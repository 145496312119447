import { always, and, cond, equals, isNil, mergeDeepLeft, path } from 'ramda';
import type { BreakpointOptions } from '@ecomm/image';
import type { HeroSize } from './Hero';

export type VideoOptions = {
  videoProps: {
    videoSrc: string;
    videoPoster: string;
    videoPosterOptions: BreakpointOptions;
  };
  mobileVideoOverrides?: {
    videoSrc: string;
    videoPoster: string;
  };
};

// Merge default video options with provided values
const mergeVideoOptions = (videoOptions: VideoOptions) =>
  mergeDeepLeft(videoOptions.videoProps, {
    videoPosterOptions: {
      mobile: {
        width: 960,
        quality: 'auto:low',
      },
    },
  });

// override desktop video and poster urls on mobile
export const resolveVideoSrc = (
  videoOptions: VideoOptions | undefined,
  isMobile: boolean,
) =>
  and(!isNil(path(['mobileVideoOverrides'], videoOptions)), isMobile)
    ? mergeDeepLeft(
        {
          videoProps: {
            videoSrc: path(['mobileVideoOverrides', 'videoSrc'], videoOptions),
            videoPoster: path(['mobileVideoOverrides', 'videoPoster'], videoOptions),
          },
        },
        videoOptions,
      )
    : videoOptions;

const VIDEO_SETTINGS = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
};

export const resolveVideoOptions = (
  videoOptions: VideoOptions | undefined,
  minimumVideoBreakpoint: string,
) =>
  videoOptions
    ? {
        minimumVideoBreakpoint: minimumVideoBreakpoint,
        videoProps: mergeVideoOptions(videoOptions),
        ...VIDEO_SETTINGS,
      }
    : {};

const BREAKPOINT_OPTIONS = {
  short: {
    mobile: { height: 300 },
    tablet: { height: 527 },
    desktop: { height: 577 },
  },
  standard: {
    mobile: { height: 370 },
    tablet: { height: 550 },
    desktop: { height: 760 },
  },
  viewport: {
    mobile: { height: 720 },
    tablet: { height: 1000 },
    desktop: { height: 1200 },
  },
};

// Get default breakpointOptions for specified HeroSize
const defaultBreakpointOptions = (heroSize: HeroSize) =>
  cond([
    [equals('short'), always(BREAKPOINT_OPTIONS.short)],
    [equals('standard'), always(BREAKPOINT_OPTIONS.standard)],
    [() => true, always(BREAKPOINT_OPTIONS.viewport)],
  ])(heroSize);

// Merge default breakpointOptions with breakpointOverrides if provided
export const resolveBreakpointOptions = (
  options: BreakpointOptions | undefined,
  heroSize: HeroSize,
) => ({
  breakpointOptions: options
    ? mergeDeepLeft(options, defaultBreakpointOptions(heroSize))
    : defaultBreakpointOptions(heroSize),
});
