import React from 'react';
import { BreakpointEither } from '@peloton/responsive';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';
import WelcomeSection from './WelcomeSection';

const StudioInformation: React.FC<React.PropsWithChildren<unknown>> = () => (
  <>
    <WelcomeSection />
    <BreakpointEither breakpoint="desktopLarge">
      <MobileLayout />
      <DesktopLayout />
    </BreakpointEither>
  </>
);

export default StudioInformation;
