import { driftExists, startInteraction } from '@peloton/drift';

const studioInteractionId = 119843;

export const openChat = () => {
  try {
    if (driftExists()) {
      startInteraction(studioInteractionId);
    }
  } catch (e) {
    if (e instanceof TypeError) {
      console.warn('Chat has not been initialized');
    } else {
      throw e;
    }
  }
};
