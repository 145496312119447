import { css, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const fadeInOut = keyframes`
  from {opacity: 0}
  20% {opacity: 1}
  50% {opacity: 1}
  to {opacity: 0}
`;

export const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const slideUp = (distance: string = '100vh') => keyframes`
  from {
    opacity: 0;
    transform: translateY(${distance});
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const fadeInAnimation = css`
  animation: ${fadeIn} 0.6s ease-in forwards;
`;

export const fadeInOutAnimation = css`
  animation: ${fadeInOut} 5s ease-in forwards;
`;

// when used with the <ScrolledIntoView> components, this gets you the cascading text animation style that you see across the marketing site
export const cascadingContent = css`
  & > * {
    opacity: 0;
    transition: opacity 1s ease 200ms, transform 1s ease 200ms,
      background-color 250ms ease 0ms, color 250ms ease 0ms !important;
    transform: translate3d(0, 20px, 0) !important;
  }

  &.scrolled-into-view > * {
    opacity: 1;
    transform: translate3d(0, 0, 0) !important;
  }
`;
