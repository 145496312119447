import { AuthEnv } from '@peloton/auth/authClient';
import { ApiEnvs } from '@peloton/external-links';

export const toGlobalLogoutEnv = (apiEnv: ApiEnvs) => {
  switch (apiEnv) {
    case ApiEnvs.Prod:
      return AuthEnv.Prod;
    case ApiEnvs.Qa1:
    default:
      return AuthEnv.Stage;
  }
};
