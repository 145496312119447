import type { AxiosInstance } from 'axios';
import React, { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import {
  deAuthenticate,
  errorHandler,
  loadAuthUser,
  loadAuthUserFailure,
  useOauthErrorHandler,
} from '@peloton/auth';
import { useErrorReporter } from '@peloton/error-reporting';
import { redirectToNotFound } from '@peloton/navigation';
import useStudioOAuth from '@studio/auth/useStudioOAuth';

export const useAuthorizationHeader = (client?: AxiosInstance) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useStudioOAuth();
  useEffect(() => {
    if (!client) {
      return;
    }

    const attachAuthorizationHeaderWithOauth = client.interceptors.request.use(
      async config => {
        if (isAuthenticated) {
          try {
            const accessToken = await getAccessTokenSilently();
            config.headers['Authorization'] = `Bearer ${accessToken}`;
          } catch {
            // There was an error getting the access token (probably either because of a timeout or the user not being logged in)
            // In either case, we can let this request go through without an authorization header
          }
        }
        return config;
      },
    );

    return () => client.interceptors.request.eject(attachAuthorizationHeaderWithOauth);
  }, [getAccessTokenSilently, isAuthenticated, client]);

  return { isAuthenticated, isLoading };
};

export const useInvalidateTokenHeader = (client?: AxiosInstance) => {
  const dispatch = useDispatch();
  const { logout } = useStudioOAuth();
  const deAuth = useCallback(() => dispatch(deAuthenticate()), [dispatch]);
  const notFoundRedirect = useCallback(() => dispatch(redirectToNotFound()), [dispatch]);
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  const oauthEnabled = true;

  const oauthErrorHandler = useOauthErrorHandler(
    errorHandler(() => logout({}), notFoundRedirect, deAuth),
    client,
    oauthEnabled,
  );

  React.useEffect(() => {
    if (!client) {
      return;
    }

    // handles an edge case documented here: https://pelotoncycle.atlassian.net/wiki/spaces/UP/pages/41504866434/Test+scenarios+for+web+apps+integrated+with+OAuth
    client.interceptors.response.use(config => {
      if (config.headers['invalidate-token'] === 'True') {
        reportError(new Error('Token mismatch detected'));
        logout({});
      }
      return config;
    }, oauthErrorHandler);
    // eslint-disable-next-line
  }, [client]);
};

export const useLoadAuthorizedUser = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isLoading } = useStudioOAuth();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isAuthenticated) {
      dispatch(loadAuthUser());
    } else {
      dispatch(loadAuthUserFailure());
    }
  }, [isAuthenticated, isLoading, dispatch]);
};
