export type ContentMeta = {
  key: string;
  type: string;
  field: string;
  parentKey?: { key: string; field: string };
  srcEncodedKey?: string;
  tags?: string[];
};

const matchers = {
  fieldNameMatcher: /🚏(.*)/,
  contentTypeMatcher: /📓(.*)(?=🚏)/,
  contentKeyMatcher: /🔑(.*)(?=📓)/,
};

const matchFields = (
  encodedKey: string,
): {
  contentKey: string;
  contentType: string;
  fieldName: string;
} | null => {
  const { contentKeyMatcher, contentTypeMatcher, fieldNameMatcher } = matchers;
  const contentKey = encodedKey.match(contentKeyMatcher);
  const contentType = encodedKey.match(contentTypeMatcher);
  const fieldName = encodedKey.match(fieldNameMatcher);

  if (contentKey && contentType && fieldName) {
    return {
      contentKey: contentKey[0].replace(/🔑/gi, ''),
      contentType: contentType[0].replace(/📓/gi, ''),
      fieldName: fieldName[0].replace(/🚏/gi, ''),
    };
  }
  return null;
};

const copyXrayParser = (fullEncodedKey: string) => {
  let parent: { key: string; field: string };
  const numberOfKeys = fullEncodedKey.match(/🛑/g);
  const delimiter = '🛑';

  return fullEncodedKey
    .split(delimiter)
    .reduce<ContentMeta[]>((content, encodedKey, index) => {
      const extractedFields = matchFields(encodedKey);

      if (extractedFields) {
        const { contentKey, contentType, fieldName } = extractedFields;
        if (contentKey === 'undefined') {
          return content;
        }

        const parsedEntry: ContentMeta = {
          type: contentType,
          field: fieldName,
          key: contentKey,
          tags: [],
          // srcEncodedKey: fullEncodedKey,
        };
        // parsing a nested field
        if (index !== 0) {
          parsedEntry.parentKey = {
            key: parent.key,
            field: parent.field,
          };
        }
        // there are nested fields so set the parent
        if (numberOfKeys && numberOfKeys.length > 0) {
          parent = {
            key: contentKey,
            field: fieldName,
          };
        }
        content.push(parsedEntry);
      }
      return content;
    }, []);
};

const parseCopyXray = (content: string[]): ContentMeta[] => {
  const uniqueSet = new Set();

  return content
    .flatMap(copyXrayParser)
    .sort((contentA, contentB) => {
      const contentTypeA = contentA.type.toUpperCase();
      const contentTypeB = contentB.type.toUpperCase();

      if (contentTypeA < contentTypeB) {
        return -1;
      }
      if (contentTypeA > contentTypeB) {
        return 1;
      }

      return 0;
    })
    .filter(sortedContent => {
      const { type, key } = sortedContent;
      const aggregateKey = `${type}:${key}`;
      if (!uniqueSet.has(aggregateKey)) {
        uniqueSet.add(aggregateKey);
        return true;
      }
      return false;
    });
};

export default parseCopyXray;
