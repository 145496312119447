import { pathOr } from 'ramda';
import { useFaqQuery } from '../hooks.generated';
import type { Faq } from '../models.generated';

type TFaq = {
  key: Faq;
  question: string;
  answer: string;
};

export type StudioFaqType = {
  category: string;
  faqs: TFaq[];
};

export const transformStudioFaqData = (response: object, id: Faq): StudioFaqType => {
  const title = pathOr('', ['data', 'faq', 'title'], response);
  const rawQuestions = pathOr([], ['data', 'faq', 'pairsCollection', 'items'], response);
  const filteredQuestions = rawQuestions.filter((el: TFaq) => Boolean(el));
  const faqsWithFallbacks: TFaq[] = filteredQuestions.map((qAndA: TFaq) => {
    const { key } = qAndA;
    const { question, answer } = qAndA;
    const fallbackText = `Q and A: ${key}`;

    return {
      key,
      question: question || fallbackText,
      answer: answer || fallbackText,
    };
  });

  return { category: title || `Studio FAQ: ${id}`, faqs: faqsWithFallbacks };
};

const useStudioFaqData = (id: Faq): StudioFaqType => {
  const res = useFaqQuery({ variables: { id } });

  return transformStudioFaqData(res, id);
};

export default useStudioFaqData;
