import type { EnvironmentFlags } from '../environmentFlags';
import environmentFlags from '../environmentFlags';

const defaultState = environmentFlags();

const reducer = (state: EnvironmentFlags = defaultState) => state;

export type ReducerState = {
  flags: EnvironmentFlags;
};

export default reducer;
