import { datadogRum } from '@datadog/browser-rum';
import React from 'react';

export type DatadogRUMContext = {
  datadogRum: typeof datadogRum | undefined;
};

const DatadogRUMContext = React.createContext<DatadogRUMContext>({
  datadogRum: undefined,
});

export const DatadogRUMContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [ddRum, setDdRum] = React.useState<DatadogRUMContext['datadogRum']>();

  React.useEffect(() => {
    datadogRum.init({
      applicationId: '55cb4eeb-5f4c-4dce-afe7-38032c66cbdc',
      clientToken: 'pub031e91b19e37de868a3398645e0246df',
      site: 'datadoghq.com',
      service: 'studio-reservation-system',
      env: process.env.MONITORING_ENV,
      version: process.env.COMMIT_REF,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();

    setDdRum(datadogRum);
  }, []);

  return (
    <DatadogRUMContext.Provider value={{ datadogRum: ddRum }}>
      {children}
    </DatadogRUMContext.Provider>
  );
};

export const useDatadogRum = () => React.useContext(DatadogRUMContext).datadogRum;
