import { toClient } from '@peloton/api';
import { toApiEnv } from '@peloton/env';
import { toExtLinkEnv } from '@peloton/external-links';
import { environmentFlags } from '@studio/env';

const apiEnv = toApiEnv(window.location.hostname, environmentFlags());

export const client = toClient({
  env: toExtLinkEnv({ api: apiEnv }),
});
