export const peloton = '/';

export const accessibility = '/accessibility';
export const app = '/app';
export const appMembership = '/app-membership';
export const bike = '/bike';
export const bikePlus = '/bike-plus';
export const bikes = '/bikes';
export const bikeAccessories = '/shop/accessories/bike';
export const bikeClasses = '/bikes/classes';
export const bikeInstructors = '/bike/instructors';
export const bikeQuestions = '/bike/questions';
export const bikeQuestionSubmit = '/bike/questions/submit';
export const bikeReviews = '/bike/reviews';
export const bikeReviewsSubmit = '/bike/reviews/submit';
export const bikePlusReviews = '/bike-plus/reviews';
export const bikesReviews = '/bikes/reviews';
export const bikePlusReviewsSubmit = '/bike-plus/reviews/submit';
export const bikePlusQuestions = '/bike-plus/questions';
export const bikePlusQuestionSubmit = '/bike-plus/questions/submit';
export const bikeCompare = '/bikes/compare';
export const refurbishedBikeReviews = '/refurbished-bike/reviews';
export const refurbishedBikeSubmit = '/refurbished-bike/reviews/submit';
export const refurbishedBikePlusReviews = '/refurbished-bike-plus/reviews';
export const refurbishedBikePlusSubmit = '/refurbished-bike-plus/reviews/submit';
export const caApplicantPrivacy = '/california-applicant-privacy';
export const caPrivacyNotice = '/us-states-privacy-notice';
export const calculator = '/calculator';
export const careers = '/careers';
export const cart = '/cart';
export const cfuBikeSignup = '/bike/sign-up';
export const cfuBikePlusSignup = '/bike-plus/sign-up';
export const cfuBikesSignup = '/bikes/sign-up';
export const chaseSapphire = '/chase-sapphire';
export const checkout = '/checkout';
export const checkoutReview = '/checkout/review';
export const failedCreditCheck = '/failed-faas-credit-check';
export const classes = '/classes';
export const contact = '/company/contact';
export const deliveryWait = '/while-you-wait';
export const digital = '/digital';
export const digitalCheckout = '/digital/checkout';
export const digitalCheckoutMonthToMonth = '/digital/checkout/digital-mtm';
export const digitalCheckout30Day = '/digital/checkout/digital-30d';
export const digitalCheckout60Day = '/digital/checkout/digital-gp-60d';
export const downloadApp = '/app/download';
export const equipmentRemoval = '/equipment-removal';
export const financing = '/bike/financing';
export const globalFinancing = '/financing';
export const germanLaunch = '/launch';
export const guide = '/guide';
export const guideSpecs = '/guide/specs';
export const guideAnnounce = '/guide-announce';
export const guideQuestions = '/guide/questions';
export const guideQuestionSubmit = '/guide/questions/submit';
export const guideReviews = '/guide/reviews';
export const guideReviewsSubmit = '/guide/reviews/submit';
export const homeTrial = '/bike/home-trial';
export const impressum = '/impressum';
export const instructors = '/instructors';
export const instructorsBike = '/instructors/bike';
export const instructorsTread = '/instructors/tread';
export const instructorsYoga = '/instructors/yoga';
export const ipPolicy = '/ip-policy';
export const legalPatents = '/legal/patents';
export const membership = '/membership';
export const membershipTerms = '/membership-terms';
export const myMembership = '/mymembership';
export const offers = '/offers';
export const opcTerms = '/rental-terms';
export const orderConfirmationPage = `/checkout/success/?order_id=`;
export const orderhistory = '/orderhistory';
export const press = '/press';
export const privacy = '/privacy-policy';
export const reasons = '/reasons';
export const referrals = '/settings/referrals';
export const register = '/register';
export const registerFreeTrial = '/register/free-trial';
export const row = '/row';
export const rowReviews = '/row/reviews';
export const rowReviewsSubmit = '/row/reviews/submit';
export const rowQuestionsSubmit = '/row/questions/submit';
export const rowSignUp = '/row/sign-up';
export const rowSpecs = '/row/specs';
export const rowWhy = '/row/why-row';
export const shopRow = '/shop/row';
export const security = '/security';
export const shippingReservationPage = '/shipping-reservation/';
export const shopAccessories = '/shop/accessories';
export const shopBike = '/shop/bike';
export const shopBikePlus = '/shop/bike-plus';
export const shopBikeWorks = '/shop/bike/the-works-package';
export const shopGuide = '/shop/guide';
export const shopIntlBikeWorks = '/shop/bike/the-intl-works-package';
export const shopTread = '/shop/tread';
export const shopTreadEssentials = '/shop/tread/essentials-tread-package';
export const shopTreadPlus = '/shop/tread-plus';
export const showrooms = '/showrooms';
export const story = '/company';
export const team = '/company/team';
export const tos = '/terms-of-service';
export const tread = '/tread';
export const treadAccessories = '/shop/accessories/tread-bootcamp';
export const treadClasses = '/tread/classes';
export const treadFinancing = '/tread/financing';
export const treadInstructors = '/tread/instructors';
export const treadLeadGen = '/tread/sign-up';
export const treadPreorder = '/tread/preorder';
export const treadQuestions = '/tread/questions';
export const treadQuestionSubmit = '/tread/questions/submit';
export const treadReviews = '/tread/reviews';
export const treadReviewsSubmit = '/tread/reviews/submit';
export const treadSignUp = '/tread/sign-up';
export const treadsSignUp = '/treads/sign-up';
export const treadPlus = '/tread-plus';
export const treadPlusSignUp = '/tread-plus/sign-up';
export const treadPlusReviews = '/tread-plus/reviews';
export const treadPlusReviewsSubmit = '/tread-plus/reviews/submit';
export const treadSpecs = '/tread/specs';
export const valueCalculator = '/calculator';
export const valueCalculatorMonthlyCost = '/calculator/monthly-cost';
export const valueCalculatorResults = '/calculator/results';
export const waysToTry = '/try';
export const healthDataPolicy = '/consumer-health-data-policy';
