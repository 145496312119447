import React from 'react';
import type { match as Match, RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import { page } from '@peloton/analytics';

export type Props = RouteComponentProps & { children?: React.ReactNode };

class TrackedPage extends React.Component<Props> {
  componentDidMount() {
    trackPageView(this.props.match);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      trackPageView(this.props.match);
    }
  }

  render() {
    return this.props.children;
  }
}

const trackPageView = (match: Match) => {
  page({
    name: match.path,
    properties: {
      pathname: match.url,
      urlParams: match.params,
    },
  });
};

export default withRouter(TrackedPage);
