import { brand, slate } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import type { PeloLink } from '@peloton/external-links';
import { hoverTransition } from '@peloton/styles';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { l2 } from '@ecomm/typography';

export const DarkLink = styled(Link)`
  ${l2}
  display: block;

  & + & {
    margin-top: 30px;
  }
` as React.ComponentType<React.PropsWithChildren<LinkProps & { to?: PeloLink }>>;

export const LightLink = styled(Link)`
  ${l2}
  display: block;

  & + & {
    margin-top: 30px;
  }

  ${hoverTransition({
    property: 'color',
    from: slate[50],
    to: brand.darkest,
  })}
` as React.ComponentType<React.PropsWithChildren<LinkProps & { to?: PeloLink }>>;

export const LinkSection = styled.div`
  padding-bottom: 30px;

  & + & {
    border-top: 1px solid rgba(168, 172, 177, 0.3);
    padding-top: 30px;
  }
`;
