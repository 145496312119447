import React from 'react';

export type BaseModalProps = {
  isOpen?: boolean;
  contentLabel: string;
  closeHandler: () => void;
  openHandler?: () => void;
  children?: React.ReactNode;
};

abstract class BaseModal<P extends BaseModalProps> extends React.Component<P> {
  static defaultProps = {
    isOpen: true,
  };

  public savedScrollPosition: number;

  public componentDidMount() {
    // Save the current scroll position in case the implementer is not controlling `isOpen`
    this.saveScrollPosition();
  }

  public getSnapshotBeforeUpdate(prevProps: P) {
    // Save the current scroll position in case the implementer changed isOpen from false to true
    if (!prevProps.isOpen && this.props.isOpen) {
      this.saveScrollPosition();
    }
    return this.savedScrollPosition;
  }

  public componentDidUpdate(prevProps: P) {
    if (prevProps.isOpen && !this.props.isOpen) {
      // Apply the saved scroll position in case the implementer changed isOpen from true to false
      setTimeout(this.applySavedScrollPosition, 400);
    }
  }

  public componentWillUnmount() {
    // only apply the saved scroll position if the modal is unmounted while open
    if (this.props.isOpen) {
      this.applySavedScrollPosition();
    }
  }

  abstract handleAfterOpen(): void;

  abstract handleRequestClose(): void;

  public applySavedScrollPosition = () => {
    requestAnimationFrame(() => window.scrollTo({ top: this.savedScrollPosition }));
    document.body.style.width = '';
    document.body.style.position = '';
    document.body.style.top = '';
  };

  private saveScrollPosition = () => {
    this.savedScrollPosition = window.scrollY || document.documentElement.scrollTop;
  };
}

export default BaseModal;
