import React from 'react';
import { useSelector } from 'react-redux';
import { toFormattedText } from '@peloton/copy';
import { getLocale } from '@peloton/env';
import type { PeloLink } from '@peloton/external-links';
import { getExtLinkEnv, toHref } from '@peloton/external-links';
import { toISOLang } from '@peloton/internationalize';
import { useStudioKeyValueData, useStudioMetadataData } from '@studio/copy';
import type { Metadata } from '@studio/copy/models.generated';
import MetadataTagsView from './MetadataTagsView';
import toLinks from './toLinks';

type Props = {
  canonicalLink: PeloLink;
  id: Metadata;
  values?: Record<string, string>;
};

const MetadataTags: React.FC<React.PropsWithChildren<Props>> = ({
  canonicalLink,
  id,
  values,
}) => {
  const companyPrefix = useStudioKeyValueData('companyPrefix');
  const { description, image, keywords, ogtype, title } = useStudioMetadataData(id);
  const extLinkEnv = useSelector(getExtLinkEnv);
  const locale = useSelector(getLocale);

  const formattedDescription = toFormattedText(description, values) as string;
  const formattedPageTitle = toFormattedText(title, values) as string;
  const imageUrl = image?.url || '/images/studioImage.jpg';
  const joinedKeywords = keywords.join(',');
  const lang = toISOLang(useSelector(getLocale));
  const linkElements = toLinks({ canonicalLink, extLinkEnv });
  const ogUrl = toHref(canonicalLink, extLinkEnv);
  const prefixedPageTitle = `${companyPrefix}${formattedPageTitle}`;

  return (
    <MetadataTagsView
      description={formattedDescription}
      imageUrl={imageUrl}
      keywords={joinedKeywords}
      lang={lang}
      linkElements={linkElements}
      locale={locale}
      ogType={ogtype}
      ogUrl={ogUrl}
      title={prefixedPageTitle}
    />
  );
};

export default MetadataTags;
