import type { PeloLink } from '@peloton/external-links/models';
import { toWWWLink } from '@peloton/external-links/models';
import * as paths from './wwwPaths';

export const peloton = toWWWLink(paths.peloton);

export const accessibility = toWWWLink(paths.accessibility);
export const app = toWWWLink(paths.app);
export const appMembership = toWWWLink(paths.appMembership);
export const appDownload = toWWWLink(paths.downloadApp);
export const bike = toWWWLink(paths.bike);
export const bikePlus = toWWWLink(paths.bikePlus);
export const bikes = toWWWLink(paths.bikes);
export const bikeAccessories = toWWWLink(paths.bikeAccessories);
export const bikeClasses = toWWWLink(paths.bikeClasses);
export const bikeInstructors = toWWWLink(paths.bikeInstructors);
export const bikeQuestions = toWWWLink(paths.bikeQuestions);
export const bikeQuestionSubmit = toWWWLink(paths.bikeQuestionSubmit);
export const bikeReviews = toWWWLink(paths.bikeReviews);
export const bikeReviewsSubmit = toWWWLink(paths.bikeReviewsSubmit);
export const bikePlusQuestions = toWWWLink(paths.bikePlusQuestions);
export const bikePlusQuestionSubmit = toWWWLink(paths.bikePlusQuestionSubmit);
export const bikePlusReviews = toWWWLink(paths.bikePlusReviews);
export const bikesReviews = toWWWLink(paths.bikesReviews);
export const bikePlusReviewsSubmit = toWWWLink(paths.bikePlusReviewsSubmit);
export const refurbishedBikeReviews = toWWWLink(paths.refurbishedBikeReviews);
export const refurbishedBikeReviewsSubmit = toWWWLink(paths.refurbishedBikeSubmit);
export const refurbishedBikePlusReviews = toWWWLink(paths.refurbishedBikePlusReviews);
export const refurbishedBikePlusReviewsSubmit = toWWWLink(
  paths.refurbishedBikePlusSubmit,
);
export const bikeCompare = toWWWLink(paths.bikeCompare);
export const caApplicantPrivacy = toWWWLink(paths.caApplicantPrivacy);
export const caPrivacyNotice = toWWWLink(paths.caPrivacyNotice);
export const calculator = toWWWLink(paths.calculator);
export const careers = toWWWLink(paths.careers);
export const cart = toWWWLink(paths.cart);
export const cfuBikeSignup = toWWWLink(paths.cfuBikeSignup);
export const cfuBikePlusSignup = toWWWLink(paths.cfuBikePlusSignup);
export const cfuBikesSignup = toWWWLink(paths.cfuBikesSignup);
export const chaseSapphire = toWWWLink(paths.chaseSapphire);
export const checkout = toWWWLink(paths.checkout);
export const checkoutReview = toWWWLink(paths.checkoutReview);
export const classes = toWWWLink(paths.classes);
export const contact = toWWWLink(paths.contact);
export const deliveryWait = toWWWLink(paths.deliveryWait);
export const digital = toWWWLink(paths.digital);
export const digitalCheckout = toWWWLink(paths.digitalCheckout);
export const digitalCheckoutMonthToMonth = toWWWLink(paths.digitalCheckoutMonthToMonth);
export const digitalCheckout30Day = toWWWLink(paths.digitalCheckout30Day);
export const digitalCheckout60Day = toWWWLink(paths.digitalCheckout60Day);
export const equipmentRemoval = toWWWLink(paths.equipmentRemoval);
export const financing = toWWWLink(paths.financing);
export const globalFinancing = toWWWLink(paths.globalFinancing);
export const germanLaunch = toWWWLink(paths.germanLaunch);
export const guide = toWWWLink(paths.guide);
export const guideSpecs = toWWWLink(paths.guideSpecs);
export const guideAnnounce = toWWWLink(paths.guideAnnounce);
export const guideQuestions = toWWWLink(paths.guideQuestions);
export const guideReviews = toWWWLink(paths.guideReviews);
export const guideReviewsSubmit = toWWWLink(paths.guideReviewsSubmit);
export const guideQuestionSubmit = toWWWLink(paths.guideQuestionSubmit);
export const homeTrial = toWWWLink(paths.homeTrial);
export const impressum = toWWWLink(paths.impressum);
export const instructors = toWWWLink(paths.instructors);
export const instructorsBike = toWWWLink(paths.instructorsBike);
export const instructorsTread = toWWWLink(paths.instructorsTread);
export const instructorsYoga = toWWWLink(paths.instructorsYoga);
export const ipPolicy = toWWWLink(paths.ipPolicy);
export const legalPatents = toWWWLink(paths.legalPatents);
export const membership = toWWWLink(paths.membership);
export const membershipTerms = toWWWLink(paths.membershipTerms);
export const myMembership = toWWWLink(paths.myMembership);
export const offers = toWWWLink(paths.offers);
export const opcTerms = toWWWLink(paths.opcTerms);
export const orderConfirmationPage = (orderId: string) =>
  toWWWLink(`/checkout/success/?order_id=${orderId}`);
export const orderhistory = toWWWLink(paths.orderhistory);
export const press = toWWWLink(paths.press);
export const privacy = toWWWLink(paths.privacy);
export const reasons = toWWWLink(paths.reasons);
export const referrals = toWWWLink(paths.referrals);
export const register = toWWWLink(paths.register);
export const registerFreeTrial = toWWWLink(paths.registerFreeTrial);
export const row = toWWWLink(paths.row);
export const rowReviews = toWWWLink(paths.rowReviews);
export const rowReviewsSubmit = toWWWLink(paths.rowReviewsSubmit);
export const rowQuestionsSubmit = toWWWLink(paths.rowQuestionsSubmit);
export const rowSignUp = toWWWLink(paths.rowSignUp);
export const rowSpecs = toWWWLink(paths.rowSpecs);
export const rowWhy = toWWWLink(paths.rowWhy);
export const shopRow = toWWWLink(paths.shopRow);
export const security = toWWWLink(paths.security);
export const shippingReservationPage = toWWWLink(paths.shippingReservationPage);
export const shopAccessories = toWWWLink(paths.shopAccessories);
export const shopBike = toWWWLink(paths.shopBike);
export const shopBikePlus = toWWWLink(paths.shopBikePlus);
export const shopBikeWorks = toWWWLink(paths.shopBikeWorks);
export const shopIntlBikeWorks = toWWWLink(paths.shopIntlBikeWorks);
export const shopGuide = toWWWLink(paths.shopGuide);
export const shopTread = toWWWLink(paths.shopTread);
export const shopTreadEssentials = toWWWLink(paths.shopTreadEssentials);
export const shopTreadPlus = toWWWLink(paths.shopTreadPlus);
export const showrooms = toWWWLink(paths.showrooms);
export const story = toWWWLink(paths.story);
export const team = toWWWLink(paths.team);
export const tos = toWWWLink(paths.tos);
export const tread = toWWWLink(paths.tread);
export const treadAccessories = toWWWLink(paths.treadAccessories);
export const treadClasses = toWWWLink(paths.treadClasses);
export const treadFinancing = toWWWLink(paths.treadFinancing);
export const treadInstructors = toWWWLink(paths.treadInstructors);
export const treadLeadGen = toWWWLink(paths.treadLeadGen);
export const treadPreorder = toWWWLink(paths.treadPreorder);
export const treadQuestions = toWWWLink(paths.treadQuestions);
export const treadQuestionSubmit = toWWWLink(paths.treadQuestionSubmit);
export const treadReviews = toWWWLink(paths.treadReviews);
export const treadReviewsSubmit = toWWWLink(paths.treadReviewsSubmit);
export const treadPlusReviews = toWWWLink(paths.treadPlusReviews);
export const treadPlusReviewsSubmit = toWWWLink(paths.treadPlusReviewsSubmit);
export const treadSpecs = toWWWLink(paths.treadSpecs);
export const valueCalculator = toWWWLink(paths.valueCalculator);
export const valueCalculatorMonthlyCost = toWWWLink(paths.valueCalculatorMonthlyCost);
export const valueCalculatorResults = toWWWLink(paths.valueCalculatorResults);
export const waysToTry = toWWWLink(paths.waysToTry);
export const healthDataPolicy = toWWWLink(paths.healthDataPolicy);

export const appendPackageSlug = (link: PeloLink, packageSlug: string): PeloLink => ({
  ...link,
  path: `${link.path.replace(/\/$/, '')}/${packageSlug}`,
});
