import { Eyebrow, black, brand, grey, white } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React, { createRef, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';
import { BreakpointEither } from '@peloton/responsive';
import { hover, media, defaultTransition } from '@peloton/styles';
import { Chevron, Orientation } from '@ecomm/icons';
import { buttonCaretTeamUp } from '@studio/styles';
import MenuItem from './MenuItem';
import type { MenuItemButton, MenuItemLink } from './menuListItems';

export type MenuItemType = MenuItemLink | MenuItemButton;

type Props = {
  buttonText: string;
  dropdownWidth: string;
  menuItems: MenuItemType[];
  testId?: string;
  ButtonComponent?: React.ForwardRefExoticComponent<
    DropdownButtonProps & React.RefAttributes<HTMLButtonElement>
  >;
};

const hyphenate = (value: string) => value?.toLowerCase()?.replace(/\s/g, '-');

const NULL_MENU_ITEM = -1;
const FIRST_MENU_ITEM = 0;
const SPACE = ' ';

const HeaderDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  buttonText,
  dropdownWidth,
  menuItems,
  testId,
  ButtonComponent = DropdownButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusedItem, setFocusedItem] = useState(NULL_MENU_ITEM);

  const menuItemCount = menuItems.length;

  const menuButtonRef = createRef<HTMLButtonElement>();
  const menuContainerRef = createRef<HTMLElement>();
  const lastMenuItem = menuItemCount - 1;

  const menuItemRefs = useMemo(() => {
    const tempRefs: React.RefObject<HTMLElement>[] = [];

    while (tempRefs.length < menuItemCount) {
      tempRefs.push(createRef<HTMLElement>());
    }
    return tempRefs;
  }, [menuItemCount]);

  useEffect(() => {
    if (focusedItem > NULL_MENU_ITEM) {
      menuItemRefs[focusedItem]?.current?.focus();
    }
  }, [focusedItem, menuItemRefs]);

  useEffect(() => {
    const handleClickOutsideMenu = (e: MouseEvent) => {
      if (
        menuContainerRef?.current?.contains(e.target as Node) ||
        menuButtonRef?.current?.contains(e.target as Node)
      ) {
        return;
      }

      resetMenuState();
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutsideMenu);
    }
    return () => document.removeEventListener('click', handleClickOutsideMenu);
  }, [isOpen, menuButtonRef, menuContainerRef]);

  const handleMenuButtonClick = () => setIsOpen(!isOpen);

  const handleMenuButtonKeyDown = (e: React.KeyboardEvent): void =>
    menuButtonKeyDownActions[e.key] && menuButtonKeyDownActions[e.key](e);

  const handleMenuItemKeyDown = (e: React.KeyboardEvent): void =>
    menuItemKeyDownActions[e.key] && menuItemKeyDownActions[e.key](e);

  const menuButtonKeyDownActions = {
    [SPACE]: openMenuSelectFirstItem,
    ArrowDown: openMenuSelectFirstItem,
    ArrowUp: openMenuSelectLastItem,
    Enter: openMenuSelectFirstItem,
    Tab: resetMenuState,
  };

  const menuItemKeyDownActions = {
    ArrowDown: navigateMenuItems,
    ArrowUp: navigateMenuItems,
    End: goToLastMenuItem,
    Enter: activateMenuItemCloseMenu,
    Escape: closeMenuRefocusButton,
    Home: goToFirstMenuItem,
    Tab: tabAwayFromOpenMenu,
  };
  const dropdownId = `${hyphenate(buttonText)}-dropdown`;
  const buttonId = `${hyphenate(buttonText)}-menu-button`;
  return (
    <StyledListItem>
      <ButtonComponent
        aria-controls={dropdownId}
        aria-expanded={isOpen}
        aria-haspopup="true"
        data-test-id={testId}
        id={buttonId}
        text={buttonText}
        isOpen={isOpen}
        onClick={handleMenuButtonClick}
        onKeyDown={handleMenuButtonKeyDown}
        ref={menuButtonRef}
      />
      {isOpen && (
        <BreakpointEither breakpoint="tablet">
          <Dropdown
            aria-labelledby={buttonId}
            id={dropdownId}
            ref={menuContainerRef}
            role="menu"
            width={dropdownWidth}
            topPos={'40px'}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={item.dataTestId}
                handleMenuItemKeyDown={handleMenuItemKeyDown}
                item={item}
                menuItemRef={menuItemRefs[index]}
              />
            ))}
          </Dropdown>
          <Dropdown
            aria-labelledby={buttonId}
            id={dropdownId}
            ref={menuContainerRef}
            role="menu"
            width={dropdownWidth}
            topPos={ButtonComponent === DropdownButton ? '42px' : '50px'}
          >
            {menuItems.map((item, index) => (
              <MenuItem
                key={item.dataTestId}
                handleMenuItemKeyDown={handleMenuItemKeyDown}
                item={item}
                menuItemRef={menuItemRefs[index]}
              />
            ))}
          </Dropdown>
        </BreakpointEither>
      )}
    </StyledListItem>
  );

  function activateMenuItemCloseMenu(e: React.KeyboardEvent) {
    e.preventDefault();
    menuItemRefs[focusedItem]?.current?.click();
    closeMenuRefocusButton();
  }

  function closeMenuRefocusButton() {
    menuButtonRef?.current?.focus();
    resetMenuState();
  }

  function goToFirstMenuItem(e: React.KeyboardEvent) {
    e.preventDefault();
    setFocusedItem(FIRST_MENU_ITEM);
  }

  function goToLastMenuItem(e: React.KeyboardEvent) {
    e.preventDefault();
    setFocusedItem(lastMenuItem);
  }

  function navigateMenuItems(e: React.KeyboardEvent) {
    e.preventDefault();
    const { key } = e;
    const nextIndex = (focusedItem + 1) % menuItemCount;
    const previousIndex = (menuItemCount + focusedItem - 1) % menuItemCount;

    if (key === 'ArrowDown') {
      setFocusedItem(nextIndex);
    }
    if (key === 'ArrowUp') {
      setFocusedItem(previousIndex);
    }
  }

  function openMenuSelectFirstItem(e: React.KeyboardEvent) {
    e.preventDefault();
    setIsOpen(true);
    setFocusedItem(FIRST_MENU_ITEM);
  }

  function openMenuSelectLastItem(e: React.KeyboardEvent) {
    e.preventDefault();
    setIsOpen(true);
    setFocusedItem(lastMenuItem);
  }

  function resetMenuState() {
    setIsOpen(false);
    setFocusedItem(NULL_MENU_ITEM);
  }

  function tabAwayFromOpenMenu(e: React.KeyboardEvent) {
    if (e.shiftKey) {
      closeMenuRefocusButton();
    } else {
      resetMenuState();
    }
  }
};

export default HeaderDropdown;

const MenuButton = styled.button`
  align-items: center;
  display: flex;

  ${hover`
    color: ${grey[50]}
  `}

  ${buttonCaretTeamUp()}
  text-transform: capitalize;
  height: 25px;
`;

const StyledListItem = styled.li`
  position: relative;

  ${media.desktopLarge`
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  `}
`;

const StyledChevron = styled(Chevron)`
  ${defaultTransition('transform')}
  margin-left: 5px;
  width: 8px;
  color: ${grey[50]};
`;

const Dropdown = (styled.ul<{ topPos: string }>`
  border-radius: 3px;
  box-shadow: 0 30px 70px 0 ${rgba(black, 0.11)};
  background: ${white};
  color: ${brand.darkest};
  padding: 40px;
  position: absolute;
  top: ${props => props.topPos};
  right: 0px;
  z-index: 2;
` as any) as React.ComponentType<
  React.PropsWithChildren<
    {
      ref?: React.RefObject<HTMLElement>;
      width: string;
      topPos?: string;
    } & React.HTMLAttributes<any>
  >
>;

export type DropdownButtonProps = {
  id: string;
  text: string;
  isOpen: boolean;
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
};

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ text, isOpen, ...rest }, ref) => (
    <MenuButton {...rest} ref={ref}>
      <Eyebrow is="span" size="small">
        {text}
      </Eyebrow>
      <StyledChevron orientation={isOpen ? Orientation.Up : Orientation.Down} />
    </MenuButton>
  ),
);
DropdownButton.displayName = 'DropdownButton';
