import React from 'react';
import { Redirect } from 'react-router';
import { useOauth } from '@peloton/auth';
import { loginErrors } from '@peloton/auth/OauthProvider';
import { useLocale } from '@peloton/internationalize';
import { trackViewedCallbackPage } from './analytics';
import { LOCAL_STORAGE_REDIRECT_PATH_KEY } from './constants';
import { getOauthFragment } from './oAuthFragment';

/*
  Universal-login page accepts error codes and returns human readable messages

  Error Codes:
  10501 -> User Already Exists / Peloton
  10502 -> User Already Signed Up / Google
  10503 -> User Already Signed Up / Apple
  20101 -> User Does Not Have Google / Apple Account
*/
export const getErrorMessage = (errorDescription: string) => {
  const match = loginErrors.exec(errorDescription);
  if (!match) {
    return 'Error logging in, please try again.';
  }
  const [code] = match;
  return code;
};

const didLoginFail = (errorDescription: string | null) =>
  errorDescription && loginErrors.test(errorDescription);

const OAuthRedirect = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const errorDescription = queryParams.get('error_description');
  const loginFailed = didLoginFail(errorDescription);
  const { loginWithRedirect } = useOauth();
  const locale = useLocale();

  React.useEffect(() => {
    trackViewedCallbackPage('OAuthRedirect');
  }, []);

  React.useEffect(() => {
    if (loginFailed && errorDescription) {
      loginWithRedirect({
        fragment: getOauthFragment(locale, getErrorMessage(errorDescription)),
        authorizationParams: {
          prompt: 'login',
        },
      });
    }
  }, [loginWithRedirect]);

  if (loginFailed) {
    return null;
  }

  const redirectPath = localStorage.getItem(LOCAL_STORAGE_REDIRECT_PATH_KEY) || '/';
  localStorage.removeItem(LOCAL_STORAGE_REDIRECT_PATH_KEY);

  return <Redirect to={redirectPath} />;
};

export default OAuthRedirect;
