import React from 'react';
import { Links } from '@peloton/links';
import { useStudioKeyValueData } from '@studio/copy';
import { RegionSlug } from '@studio/regions';
import type { MenuItemType } from './HeaderDropdown';
import HeaderDropdown from './HeaderDropdown';

type Props = {
  onLogOutClick: () => void;
};

const HeaderAccountDropdown: React.FC<React.PropsWithChildren<Props>> = ({
  onLogOutClick,
}) => {
  const headerAccount = useStudioKeyValueData('header.account');
  const headerLogout = useStudioKeyValueData('header.logOut');
  const headerMyNYClassesText = useStudioKeyValueData('studioSite.header.myNYClasses');
  const headerMyLondonClassesText = useStudioKeyValueData(
    'studioSite.header.myLondonClasses',
  );
  const updatedAccountMenuItems: MenuItemType[] = [
    {
      dataTestId: 'myNYClasses',
      linkText: headerMyNYClassesText,
      to: Links.toStudioAccount(RegionSlug.NewYork),
    },
    {
      dataTestId: 'myLondonClasses',
      linkText: headerMyLondonClassesText,
      to: Links.toStudioAccount(RegionSlug.London),
    },
    {
      dataTestId: 'logOutButton',
      linkText: headerLogout,
      onClick: () => onLogOutClick(),
    },
  ];

  return (
    <HeaderDropdown
      buttonText={headerAccount}
      testId={'AccountMenuButton'}
      dropdownWidth="135px"
      menuItems={updatedAccountMenuItems}
    />
  );
};

export default HeaderAccountDropdown;
