import { brand, slate } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { hr3, b3, reg } from '@ecomm/typography';
import { Layout } from '@studio/layout';
import { StudioPageError } from '@studio/layout/PageError';
import { flexCenterColumn } from '@studio/styles';

const NotFoundPage = () => {
  const {
    errorReporter: { reportError },
  } = useErrorReporter();
  reportError(new StudioPageError());

  return (
    <Container>
      <Title>Oops, that page could not be&nbsp;found.</Title>
      <Subtitle>
        Go back to the previous page, try again, or visit our&nbsp;
        <SupportLink to={Links.studioSupport}>FAQs</SupportLink>
      </Subtitle>
    </Container>
  );
};

const Container = styled(Layout)`
  ${flexCenterColumn}
  justify-content: center;
  text-align: center;
  padding: 20px;
  min-height: 480px;
`;

const Subtitle = styled.p`
  ${b3}
  ${reg}
  color: ${slate[50]};
  margin-top: 20px;
  ${media.tablet`
    margin-top: 30px;
  `}
`;

const SupportLink = (styled(Link)({
  color: brand.primary,
}) as any) as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const Title = styled.h1`
  ${hr3}
  margin: 0 auto;
`;

export default NotFoundPage;
