import { Eyebrow, Icon } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import type { ExtLinkEnv } from '@peloton/external-links';
import { toHref, withExtLinkEnv } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { l2, L2 } from '@ecomm/links';

import SocialLinks from './SocialLinks';

export type Props = {
  largeChatText: React.ReactNode;
  emailAddress?: React.ReactNode;
  largeEmailText?: React.ReactNode;
  extLinkEnv: ExtLinkEnv;
  supportPath?: string;
  isMobile: boolean;
  mobileSocialLinks: boolean;
  onChatRequest: () => void;
  largePhoneText?: React.ReactNode;
  smallChatText: React.ReactNode;
  smallEmailText?: React.ReactNode;
  smallPhoneText?: React.ReactNode;
  smallSupportText: React.ReactNode;
  supportPhoneNumber?: React.ReactNode;
  largeSupportText: React.ReactNode;
};

const ContactSection: React.FC<React.PropsWithChildren<Props>> = ({
  largeChatText,
  extLinkEnv,
  emailAddress,
  largeEmailText,
  supportPath,
  isMobile,
  mobileSocialLinks,
  onChatRequest,
  largePhoneText,
  smallChatText,
  smallEmailText,
  smallPhoneText,
  smallSupportText,
  supportPhoneNumber,
  largeSupportText,
}) => {
  const emailText = isMobile ? smallEmailText : largeEmailText;
  const phoneText = isMobile ? smallPhoneText : largePhoneText;
  const chatText = isMobile ? smallChatText : largeChatText;
  const supportText = isMobile ? smallSupportText : largeSupportText;
  return (
    <SectionContainer data-test-id={'updatedBaseContactSection'}>
      <LinkList key="contact info">
        {emailAddress && (
          <li key="email">
            <ContactLink href={`mailto:${emailAddress}`}>
              <StyledIcon name="email" height={18} />
              <Eyebrow is="span" size="medium">
                {emailText}{' '}
              </Eyebrow>
            </ContactLink>
          </li>
        )}
        {supportPhoneNumber && (
          <li key="phone">
            <ContactLink href={`tel:${supportPhoneNumber}`}>
              <StyledIcon name="phone" height={18} />
              <Eyebrow is="span" size="medium">
                {phoneText}
              </Eyebrow>
            </ContactLink>
          </li>
        )}
        {smallChatText !== 'norender' && largeChatText !== 'norender' && (
          <li key="chat">
            <ContactButton onClick={() => onChatRequest()}>
              <StyledIcon name="chat" height={18} />
              <Eyebrow is="span" size="medium">
                {chatText}{' '}
              </Eyebrow>
            </ContactButton>
          </li>
        )}
        <li key="support">
          <ContactLink
            href={supportPath || toHref(Links.support, extLinkEnv)}
            target="_blank"
            rel="noopener"
          >
            <StyledIcon name="help" height={18} />
            <Eyebrow is="span" size="medium">
              {supportText}{' '}
            </Eyebrow>
          </ContactLink>
        </li>
      </LinkList>
      {(mobileSocialLinks && <SocialLinks />) || (!isMobile && <SocialLinks />)}
    </SectionContainer>
  );
};

export default withExtLinkEnv(ContactSection);

const StyledIcon = styled(Icon)`
  padding-right: 12px;
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  min-width: 20%;
  margin-top: 40px;

  ${media.tablet`
    flex: initial;
    margin-top: 0;
  `}

  ${media.desktopLarge`
    flex-direction: column;
    margin-top: 0;
  `}
`;

const contactStyles = `
  display: flex;
  margin-bottom: 10px;
`;

const ContactButton = styled.button`
  ${l2()}

  ${contactStyles}
`;

const ContactLink = styled(L2)`
  ${contactStyles}
`;

const LinkList = styled.ul`
  ${media.desktopLarge`
    margin-right: 0;
  `}
`;
