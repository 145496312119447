import { ToggleStatus } from '@ecomm/feature-toggle';
import type { ToggleDefinitions } from '@studio/feature-toggles';

const features: ToggleDefinitions = {
  copyXray: {
    status: ToggleStatus.Disabled,
  },
  previewCopy: {
    status: ToggleStatus.Disabled,
  },
};

export default features;
