import type { ReportError } from '@peloton/error-reporting';
import { useErrorReporter } from '@peloton/error-reporting';
import { useKeyValueQuery } from '../hooks.generated';
import type { KeyValue } from '../models.generated';
import genericTransformKeyValueData from './genericTransformKeyValueData';

export const transformKeyValueData = (
  response: object,
  id: KeyValue,
  reportError: ReportError,
) => genericTransformKeyValueData(response, 'keyValue', 'Key Value', id, reportError);

const useKeyValueData = (id: KeyValue, query = useKeyValueQuery): string => {
  const {
    errorReporter: { reportError },
  } = useErrorReporter();
  const res = query({ variables: { id } });

  return transformKeyValueData(res, id, reportError);
};

export default useKeyValueData;
