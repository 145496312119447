import { black } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { ChatStyles } from '@peloton/drift';
import { Links } from '@peloton/links';
import { Peloton } from '@peloton/logos';
import { white } from '@ecomm/colors';
import { InlineLink } from '@ecomm/links';
import { ContentContainer, InnerContent, Title, Subtitle } from './shared';

/*
 * This error page is the backup to the regular ErrorPage
 *
 * It is a cut down version of the regular error page, with
 * the intent of minimizing exposure to possibly erroring code
 * ie the provider store or internationlization.  It should be
 * used at the highest level error boundary and rarely seen.
 */
const SimpleErrorPage = () => (
  <Container>
    <ChatStyles />
    <Header>
      <StyledLogo height={35} />
    </Header>
    <ErrorContent />
  </Container>
);

export default SimpleErrorPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Header = styled.div`
  background-color: ${white};
  box-shadow: 0 3px 5px 0 ${rgba(black, 0.1)};
  z-index: 90004;
  position: relative;
  width: 100%;
`;

const StyledLogo = styled(Peloton)`
  margin: 15px 0px 5px 0px;
`;

const ErrorContent: React.FC<React.PropsWithChildren<unknown>> = () => (
  <ContentContainer>
    <InnerContent>
      <Title>An error has occurred. Please try again&nbsp;later.</Title>
      <Subtitle>
        Go back to the previous page, try again, or visit our&nbsp;
        <InlineLink href={Links.studioSupport.path}>FAQs</InlineLink>.
      </Subtitle>
    </InnerContent>
  </ContentContainer>
);
