import { useDecision } from '@optimizely/react-sdk';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initChat } from '@peloton/drift';
import type { Locale } from '@peloton/internationalize';
import { getDriftKey } from '@ecomm/vendor-keys';
import { environmentFlags } from '@studio/env';

type Props = {
  locale: Locale;
};

const ChatbotContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  locale,
}) => {
  const envFlags = environmentFlags();
  const dispatch = useDispatch();
  const driftKey = useSelector(getDriftKey);
  const [chatBot] = useDecision('drift_chatbot', {
    autoUpdate: true,
  });
  const isChatBotOn = chatBot?.enabled;

  useEffect(() => {
    if (isChatBotOn && !envFlags.isLocal && !envFlags.isCI) {
      dispatch(initChat(driftKey, locale));
    }
  }, [dispatch, driftKey, envFlags.isCI, envFlags.isLocal, isChatBotOn, locale]);

  return <>{children}</>;
};

export default ChatbotContainer;
