import React from 'react';
import styled from 'styled-components';
import { MenuListItem, UpdatedLinkText } from './styles';

export type MenuItemButton = {
  dataTestId: string;
  onClick: () => void;
  linkText: string;
};

type Props = {
  item: MenuItemButton;
  handleMenuItemKeyDown: (e: React.KeyboardEvent) => void;
  menuItemRef: React.RefObject<HTMLElement>;
};

const MenuListItemWithButton: React.FC<React.PropsWithChildren<Props>> = ({
  item: { dataTestId, linkText, onClick },
  handleMenuItemKeyDown,
  menuItemRef,
}) => {
  return (
    <MenuListItem role="none" key={dataTestId}>
      <LogOutButton
        data-test-id={dataTestId}
        onClick={onClick}
        onKeyDown={handleMenuItemKeyDown}
        ref={menuItemRef}
        role="menuitem"
        tabIndex={-1}
      >
        <UpdatedLinkText is="span" size="medium">
          {linkText}
        </UpdatedLinkText>
      </LogOutButton>
    </MenuListItem>
  );
};

export default MenuListItemWithButton;

const LogOutButton = (styled.button`
  display: block;
  &:focus {
    outline: auto;
  }
` as any) as React.ComponentType<
  React.PropsWithChildren<
    { ref?: React.RefObject<HTMLElement> } & React.HTMLAttributes<any>
  >
>;
