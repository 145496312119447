import { Headline, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getIsBreakpointGreaterThan } from '@peloton/responsive';
import type { ResponsiveSelectorState } from '@peloton/responsive';
import { media } from '@peloton/styles';
import { Modal, ModalWidth } from '@ecomm/modal';
import { useStudioKeyValueData } from '@studio/copy';
import InquiryForm from './InquiryForm';

type InquiryModalType = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAboveTablet: boolean;
  isAboveMobile: boolean;
};

const InquiryModal: React.FC<React.PropsWithChildren<InquiryModalType>> = ({
  isOpen,
  setIsOpen,
  isAboveTablet,
  isAboveMobile,
}) => {
  const groupBookingTitleCopy = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.groupBookingTitle',
  );

  const modalStyle = () => {
    if (isAboveTablet) {
      return '100px';
    } else if (isAboveMobile) {
      return '160px';
    } else {
      return '10px';
    }
  };

  return (
    <StyledModal
      contentLabel={'contentLabel'}
      width={ModalWidth.Wide}
      isOpen={isOpen}
      closeHandler={() => setIsOpen(false)}
      reactModalStyle={{
        overlay: { scrollBehavior: 'unset' },
        content: { marginLeft: modalStyle(), marginRight: modalStyle() },
      }}
      data-test-id="studio-zendesk-modal"
    >
      <StyledHeadline size="small">{groupBookingTitleCopy}</StyledHeadline>
      <InquiryForm setIsOpen={setIsOpen} />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${media.smallScreen`
    padding: 26px 20px 42px 20px;
  `}
  ${media.tablet`
    padding: 55px 80px 33px 80px;
  `}
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: ${spacing[32]};
`;

const mapStateToProps = (state: ResponsiveSelectorState) => ({
  isAboveMobile: getIsBreakpointGreaterThan(state, 'tabletXLarge'),
  isAboveTablet: getIsBreakpointGreaterThan(state, 'desktop'),
});

export default connect(mapStateToProps)(InquiryModal);
