export const SANDBOX_PSL_GROUP_BOOKING_FIELD_ID = 15211029005972;
export const SANDBOX_PSNY_GROUP_BOOKING_FIELD_ID = 15212377845268;
export const SANDBOX_PSL_GROUP_ID = 17333808486548;
export const SANDBOX_PSNY_GROUP_ID = 17333787829140;
export const PROD_PSL_GROUP_BOOKING_FIELD_ID = 15731767501460;
export const PROD_PSNY_GROUP_BOOKING_FIELD_ID = 15731827970708;
export const PROD_PSL_GROUP_ID = 6849552141844;
export const PROD_PSNY_GROUP_ID = 360008686331;

export const LONDON_LOCATION = 'london';
export const NY_LOCATION = 'new york';
export const ENGLISH_COMMUNITY_GROUP = 'community group';
export const GERMAN_COMMUNITY_GROUP = 'community gruppe';
export const ENGLISH_CORPORATE_GROUP = 'corporate';
export const GERMAN_CORPORATE_GROUP = 'corporate';
export const ENGLISH_CELEBRATION_GROUP = 'celebration group';
export const GERMAN_CELEBRATION_GROUP = 'feierliche gruppe';
export const ENGLISH_OTHER_GROUP = 'other';
export const GERMAN_OTHER_GROUP = 'andere';

export const STRENGTH_OPTION_GERMAN = 'kraft';
export const STRENGTH_OPTION = 'strength';
