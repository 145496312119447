import { brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { InlineLink } from '@ecomm/links';
import { b6 } from '@ecomm/typography';
import { useStudioKeyValueData } from '@studio/copy';

export default function PrivacyPolicy() {
  const privacyPolicy = useStudioKeyValueData('studioSite.footer.privacyPolicy');

  return (
    <StyledDivider data-test-id="privacyPolicy">
      <Markdown content={privacyPolicy} markdown={{ renderers: { link: InlineLink } }} />
    </StyledDivider>
  );
}

const StyledDivider = styled.div`
  margin-top: 20px;
  padding-right: 20px;
  display: block;
  ${b6}
  color: ${brand.darkest};
`;
