import React from 'react';
import type { Breakpoint } from '@peloton/responsive';
import { BreakpointEither } from '@peloton/responsive';
import type { BreakpointOptions } from '@ecomm/image';
import type { Image } from '@ecomm/models';
import type { Props as BackgroundImageProps } from './BackgroundImage';
import BackgroundImage from './BackgroundImage';
import type { Props as BackgroundVideoProps } from './BackgroundVideo';
import BackgroundVideo from './BackgroundVideo';

export type Props = Pick<
  BackgroundImageProps,
  Exclude<keyof BackgroundImageProps, 'src'>
> &
  Pick<
    BackgroundVideoProps,
    Exclude<keyof BackgroundVideoProps, 'src' | 'posterBreakpointOptions'>
  > & {
    /**
     * A url to an image to be shown below desktop width or when
     * a video is not provided.
     */
    imageSrc: Image;
    videoProps?: {
      /**
       * A url to a video asset to show at desktop and above.
       */
      videoSrc: string;
      /**
       * A url to an image to be shown at and above desktop width
       * while waiting for a video to load.
       */
      videoPoster: Image;
      videoPosterOptions: BreakpointOptions;
    };
    sideOfPlayPauseToggle?: string;
    verticalPositionOfPlayPauseToggle?: 'top' | 'bottom';
  };

type BreakpointProps = {
  minimumVideoBreakpoint?: Breakpoint;
};

/**
 * A component which will show media at the full width and height of its
 * `position: relative` parent, mimicking `object-fit: cover`.
 *
 * Below the desktop breakpoint, only the image will be shown.
 *
 * Any children passed will be shown on top of the image or video.
 */
const BackgroundMedia: React.FC<React.PropsWithChildren<Props & BreakpointProps>> = ({
  minimumVideoBreakpoint = 'desktop',
  imageSrc,
  videoProps,
  sideOfPlayPauseToggle,
  verticalPositionOfPlayPauseToggle,
  lazy = false,
  ...props
}) => (
  <BreakpointEither breakpoint={minimumVideoBreakpoint}>
    <BackgroundImage src={imageSrc} lazy={lazy} {...props} />
    {videoProps ? (
      <BackgroundVideo
        lazy={lazy}
        poster={videoProps.videoPoster}
        posterBreakpointOptions={videoProps.videoPosterOptions}
        src={videoProps.videoSrc}
        sideOfPlayPauseToggle={sideOfPlayPauseToggle}
        verticalPositionOfPlayPauseToggle={verticalPositionOfPlayPauseToggle}
        {...props}
      />
    ) : (
      <BackgroundImage src={imageSrc} lazy={lazy} {...props} />
    )}
  </BreakpointEither>
);

export default BackgroundMedia;
