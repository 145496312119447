import { useDecision } from '@optimizely/react-sdk';
import {
  Eyebrow,
  Icon,
  white,
  spacing,
  Link as DSLink,
  Support,
  Button,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { useStudioCtaWithUrlData, useStudioKeyValueData } from '@studio/copy/helpers';
import type { IconProps } from '@studio/icons';
import { InquiryModal } from '@studio/inquiry-form';

type Props = {
  renderIcon?: React.ComponentType<React.PropsWithChildren<IconProps>>;
  childIcon?: JSX.Element;
};

const BaseCard: React.FC<React.PropsWithChildren<Props>> = ({ children, childIcon }) => {
  return (
    <ContainerUpdate data-test-id="tu-update-support-card">
      <IconContainer aria-hidden="true">{childIcon}</IconContainer>
      <Content>{children}</Content>
    </ContainerUpdate>
  );
};

const StudioQuestionsCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const header = useStudioKeyValueData('support.questionCard.header');
  const emailCta = useStudioKeyValueData('support.questionCard.emailCta');
  const emailLink = useStudioKeyValueData('support.questionCard.emailLink');
  const requestHeader = useStudioKeyValueData(
    'studioSite.support.questionCard.RequestHeader',
  );
  const chatCta = useStudioKeyValueData('support.questionCard.chatCta');
  const chatLink = useStudioKeyValueData('support.questionCard.chatLink');

  return (
    <BaseCard childIcon={<Icon height={28} name={'chat'} />}>
      <>
        <HeaderUpdated size="medium" is="h3" data-test-id="tu-update-support-card">
          {header}
        </HeaderUpdated>
        <StyledTextContainer>
          <Support is="p" size="large">
            {emailCta}
          </Support>
          <StyledLinkUpdated href={`mailto:${emailLink}`} variant="body">
            {emailLink}
          </StyledLinkUpdated>
        </StyledTextContainer>
        <HeaderUpdated size="medium" is="h3" data-test-id="tu-update-support-card">
          {requestHeader}
        </HeaderUpdated>
        <StyledTextContainer>
          <Support is="p" size="large">
            {chatCta}
          </Support>
          <StyledLinkUpdated href={`mailto:${chatLink}`} variant="body">
            {chatLink}
          </StyledLinkUpdated>
        </StyledTextContainer>
      </>
    </BaseCard>
  );
};

const GroupBookingsAndEventsCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const header = useStudioKeyValueData('support.emailCard.header');
  const inquireButtonCopy = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.inquireButton',
  );
  const inquireInfoCopy = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.inquireInfoCopy',
  );
  const eventLink = useStudioKeyValueData('support.emailCard.eventLink');

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [showGroupInquiryForm] = useDecision('studio_group_inquiry_form', {
    autoUpdate: true,
  });

  const showGroupInquiryButton =
    showGroupInquiryForm?.enabled &&
    showGroupInquiryForm?.variables?.show_group_inquiry_button;
  // in order to style mail tags within contentful copy
  const InjectTagStyling = () => {
    const text = inquireInfoCopy;
    const nyEmail = useStudioKeyValueData('studioSite.support.groupBookingsForm.nyEmail');
    const londonEmail = useStudioKeyValueData(
      'studioSite.support.groupBookingsForm.londonEmail',
    );

    const nyEmailIdx = text.indexOf(nyEmail);
    const londonEmailIdx = text.indexOf(londonEmail);
    let email = '';
    let emailIdx = 0;

    if (nyEmailIdx !== -1) {
      email = nyEmail;
      emailIdx = nyEmailIdx;
    } else if (londonEmailIdx !== -1) {
      email = londonEmail;
      emailIdx = londonEmailIdx;
    }

    const firstSegment = text.substring(0, emailIdx);
    const secondSegment = text.substring(emailIdx + email.length + 1);

    return (
      <>
        <Support is="p" size="large" data-test-id="tu-update-support-card">
          {firstSegment}{' '}
          <StyledLinkUpdated href={`mailto:${email}`}>{email}</StyledLinkUpdated>{' '}
          {secondSegment}{' '}
        </Support>
      </>
    );
  };

  return (
    <BaseCard childIcon={<Icon height={28} name={'email'} />}>
      <InquiryModal setIsOpen={setIsModalOpen} isOpen={isModalOpen} />
      <>
        <HeaderUpdated size="medium" is="h3" data-test-id="tu-update-support-card">
          {header}
        </HeaderUpdated>
        <StyledTextContainer>
          {showGroupInquiryButton ? (
            <>
              <StyledTextContainer>
                <Button
                  size="small"
                  width="adaptive"
                  onClick={() => setIsModalOpen(true)}
                >
                  {inquireButtonCopy}
                </Button>
              </StyledTextContainer>
              <InjectTagStyling />
            </>
          ) : (
            <StyledLinkUpdated href={`mailto:${eventLink}`}>
              {eventLink}
            </StyledLinkUpdated>
          )}
        </StyledTextContainer>
      </>
    </BaseCard>
  );
};

const StudioInformationCard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const header = useStudioKeyValueData('support.infoCard.header');
  const { targetBlank, text, url } = useStudioCtaWithUrlData(
    'studioSite.studioStreetAddress',
  );
  const phoneCta = useStudioKeyValueData('support.infoCard.phoneCta');
  const phoneNumber = useStudioKeyValueData('support.infoCard.phoneNumber');
  const phoneLink = useStudioKeyValueData('support.infoCard.phoneLink');

  return (
    <BaseCard childIcon={<Icon height={32} name={'pin'} />}>
      <>
        <HeaderUpdated size="medium" is="h3" data-test-id="tu-update-support-card">
          {header}
        </HeaderUpdated>
        <StyledTextContainer>
          <StyledLinkUpdated
            target={targetBlank ? '_blank' : '_self'}
            href={Links.studioLocation(url).href}
            hasUnderline={false}
          >
            <Markdown content={text} />
          </StyledLinkUpdated>
        </StyledTextContainer>
        <Support is="p" size="large">
          {phoneCta}
        </Support>
        <StyledLinkUpdated href={`tel:${phoneLink}`}>{phoneNumber}</StyledLinkUpdated>
      </>
    </BaseCard>
  );
};

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  margin: 0 auto 20px;
  width: 40px;
`;

const Content = styled.div`
  text-align: center;
`;

const ContainerUpdate = styled.div`
  background-color: ${white};
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 40px 20px;
  width: 280px;

  p {
    display: block;
  }

  ${media.tablet`
    width: 360px;
    margin-left: 4px;
    margin-right: 4px;
  `}

  ${media.desktopLarge`
    padding-bottom: 50px;
    padding-top: 50px;
    width: 300px;
    margin-left: 7px;
    margin-right: 7px;
  `}
`;

const HeaderUpdated = styled(Eyebrow)`
  margin-bottom: ${spacing[24]};
`;

const StyledLinkUpdated = styled(DSLink)`
  span {
    font-weight: 300;
    font-size: 13px;
  }
`;

const StyledTextContainer = styled.div`
  margin-bottom: ${spacing[24]};
`;

export { GroupBookingsAndEventsCard, StudioInformationCard, StudioQuestionsCard };
