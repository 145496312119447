import React from 'react';
import styled from 'styled-components';
import type { Breakpoint } from '@peloton/responsive';
import type { Omit } from '@peloton/types';
import ViewportWrapper from '@peloton/viewport-wrapper';
import type { BackgroundMediaProps } from '@ecomm/background-media';
import type { BreakpointOptions } from '@ecomm/image';

export type HeroSize = 'viewport' | 'standard' | 'short';

type HeroContentProps = {
  heroSize: HeroSize;
};

export type HeroProps = Omit<BackgroundMediaProps, 'breakpointOptions'> &
  HeroContentProps & {
    minimumVideoBreakpoint?: Breakpoint;
    breakpointOptions?: BreakpointOptions;
    overlayOpacity?: number;
  };

// TODO: pass heroSize down to child components
const Hero: React.FC<React.PropsWithChildren<HeroContentProps>> = ({
  heroSize,
  ...props
}) =>
  heroSize === 'viewport' ? (
    <StyledViewportWrapper>
      <HeroWrapper {...props} />
    </StyledViewportWrapper>
  ) : (
    <HeroWrapper {...props} />
  );

export default Hero;

const HeroWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <div className="hero-wrapper">{children}</div>
);

const StyledViewportWrapper = styled(ViewportWrapper)`
  min-height: 370px;

  .hero-wrapper {
    height: 100%;
  }
`;
