import React from 'react';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import Overlay from './Overlay';
import type { EncodedKeyParts } from './toEncodedKey';
import { KEY_REGEX } from './toEncodedKey';
import walkTheDOM from './walkTheDOM';
import { POPUP_CSS_CLASS, XrayPopup } from './XrayPopup';

// supported types: text, href, src, style,
const CSS_CLASS = 'overlay-xray';
const SELECTED_CSS_CLASS = 'overlay-xray-selected';
const DATA_ATTR = 'data-contentful-key';

type ClickHandler = (e: PointerEvent) => void;

const addAttributeAndClassToNode = (
  node: Element | null,
  encodedText: string,
  onElementClick: ClickHandler,
) => {
  const match = encodedText.match(KEY_REGEX);
  if (match && match.length > 0) {
    node?.setAttribute(DATA_ATTR, match.join(''));
    node?.addEventListener('click', onElementClick);
    node?.classList.add(CSS_CLASS);
  }
};

const mutateNode = (onElementClick: ClickHandler) => (node: Node) => {
  if (node instanceof Text && node.textContent && KEY_REGEX.test(node.textContent)) {
    if (node.parentElement?.classList.contains(POPUP_CSS_CLASS)) {
      return;
    }
    addAttributeAndClassToNode(node.parentElement, node.textContent, onElementClick);

    node.textContent = node.textContent.replace(KEY_REGEX, '');
  } else if (node instanceof Element) {
    if (node.hasAttribute('href')) {
      const href = node.getAttribute('href');
      if (href && href.match(KEY_REGEX)) {
        addAttributeAndClassToNode(node, href, onElementClick);

        node.setAttribute('href', href.replace(KEY_REGEX, ''));
      }
    }
    if (node.hasAttribute('src')) {
      const src = node.getAttribute('src');
      if (src && src.match(KEY_REGEX)) {
        addAttributeAndClassToNode(node.parentElement, src, onElementClick);

        node.setAttribute('src', src.replace(KEY_REGEX, ''));
      }
    }
    if (node.hasAttribute('srcset')) {
      const srcset = node.getAttribute('srcset');
      if (srcset && srcset.match(KEY_REGEX)) {
        addAttributeAndClassToNode(node.parentElement, srcset, onElementClick);

        node.setAttribute('srcset', srcset.replace(KEY_REGEX, ''));
      }
    }
    if (node.hasAttribute('style')) {
      const style = node.getAttribute('style');
      if (style && style.match(KEY_REGEX)) {
        addAttributeAndClassToNode(node, style, onElementClick);

        node.setAttribute('style', style.replace(KEY_REGEX, ''));
      }
    }
  }
};

const removeSelectedClassFromAllElements = () => {
  const selected = document.querySelector(`.${SELECTED_CSS_CLASS}`);
  if (selected) {
    selected.classList.remove(SELECTED_CSS_CLASS);
  }
};

const cleanDOM = (onElementClick: ClickHandler) => {
  document.querySelectorAll(`.${CSS_CLASS}`).forEach(node => {
    node.classList.remove(CSS_CLASS);
    node.removeAttribute(DATA_ATTR);
    node.removeEventListener('click', onElementClick, { capture: true });
  });
  removeSelectedClassFromAllElements();
};

// TODO: dynamically add an overlay
// - cuid className (`xray-${cuid()}`)?
// - add .className:before selector/style to stylesheet?

// TODO: dynamically remove all xray-* classNames & styles when copyXray is disabled

const contentfulAttributeExists = (el: Element | null) => el?.hasAttribute(DATA_ATTR);

const getContentfulAttribute = (el: Element | null) => el?.getAttribute(DATA_ATTR);

const getContentfulMetadata = (target: Element) => {
  let el: Element | null = null;
  let key: string | null = null;
  if (contentfulAttributeExists(target)) {
    el = target;
    key = getContentfulAttribute(target)!;
  } else if (contentfulAttributeExists(target?.parentElement)) {
    el = target?.parentElement;
    key = getContentfulAttribute(target?.parentElement)!;
  }

  return { el, key };
};

// NOTE: "≈" is the character for alt and x.
const altAndXKeysPressed = (ev?: KeyboardEvent) => ev && ev.key === '≈';

const parseHierarchyFromKey = (attributeKey: string): EncodedKeyParts[] => {
  const keyHierarchy: EncodedKeyParts[] = [];

  // Global regexes are stateful, so we need to create a new instance to hold the state every time
  const re = new RegExp(KEY_REGEX);

  let match = re.exec(attributeKey);
  while (match) {
    if (match.groups) {
      const { parentType, parentKey, field } = match.groups;

      keyHierarchy.push({ parentType, parentKey, field });
    }

    // Advance the regex to the next match, or null
    match = re.exec(attributeKey);
  }

  return keyHierarchy;
};
const COPY_X_RAY_TOGGLE_NAME = 'copyXray';
const COPY_X_RAY_EXPORT_TOGGLE_NAME = 'copyXrayExport';
const CopyXray: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isToggleActive = useIsToggleActive();
  const isCopyXrayActive = isToggleActive(COPY_X_RAY_TOGGLE_NAME);
  const isCopyXrayExportActive = isToggleActive(COPY_X_RAY_EXPORT_TOGGLE_NAME);
  const [selectedKeyHierarchy, setSelectedKeyHierarchy] = React.useState<
    EncodedKeyParts[] | null
  >(null);
  const onElementClick = React.useCallback((e: PointerEvent) => {
    const { key, el } = getContentfulMetadata(e.target as Element);

    if (key && el) {
      e.preventDefault();
      e.stopImmediatePropagation();

      setSelectedKeyHierarchy(parseHierarchyFromKey(key));

      removeSelectedClassFromAllElements();
      el.classList.add(SELECTED_CSS_CLASS);
    }
  }, []);

  React.useEffect(() => {
    if (isCopyXrayActive) {
      displayXray(onElementClick);
    } else {
      cancelAnimationFrame(xrayCancelToken);
      setSelectedKeyHierarchy(null);
      removeXray(onElementClick);
    }

    return () => {
      cancelAnimationFrame(xrayCancelToken);
      setSelectedKeyHierarchy(null);
      removeXray(onElementClick);
    };
  }, [isCopyXrayActive, onElementClick]);

  React.useEffect(() => {
    const keydownHandler = (ev: KeyboardEvent) => {
      if (altAndXKeysPressed(ev)) {
        (window as any).__setToggle(COPY_X_RAY_TOGGLE_NAME, !isCopyXrayActive);
      }
    };
    document.addEventListener('keydown', keydownHandler);

    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [isCopyXrayActive]);

  return (
    <>
      <XrayPopup
        selectedKeyHierarchy={selectedKeyHierarchy}
        allowExport={isCopyXrayExportActive}
      />
      <Overlay />
    </>
  );
};

export default CopyXray;

let xrayCancelToken: number;
const displayXray = (onElementClick: ClickHandler) => {
  xrayCancelToken = requestAnimationFrame(() => {
    try {
      walkTheDOM(document.body, mutateNode(onElementClick));
    } finally {
      displayXray(onElementClick);
    }
  });
};

const removeXray = (onElementClick: ClickHandler) => {
  try {
    cleanDOM(onElementClick);
  } catch (error) {
    console.error('CopyXRay: Unable to clean DOM');
  }
};
