import React from 'react';
import useViewportHeight from './useViewportHeight';

/**
 * Creates a div element that is the size of the actual viewport.
 * Meant as a fix for Safari Mobile, whose `vh` does not shrink to account
 * for the address bar on top and toolbar on bottom.
 */
export const ViewportWrapper: React.FC<
  React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>
> = props => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const viewportHeight = useViewportHeight(containerRef);

  return (
    <div
      {...props}
      style={{
        height: viewportHeight,
        ...props.style,
      }}
      ref={containerRef}
    />
  );
};
