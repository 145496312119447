import React from 'react';
import { connect } from 'react-redux';
import { Links } from '@peloton/links';
import type { ResponsiveSelectorState } from '@peloton/responsive';
import { getIsBreakpointLessThan } from '@peloton/responsive';
import { openChat } from '@studio/chat';
import { useStudioKeyValueData } from '@studio/copy';
import UpdatedBaseContactSection from './BaseContactSection';

export type OwnProps = {
  isMobile: boolean;
};

const SocialMedia: React.FC<React.PropsWithChildren<OwnProps>> = ({ isMobile }) => {
  const emailAddress = useStudioKeyValueData('footer.contactUs.email');
  const updatedEmailAddress = useStudioKeyValueData('studioSite.footer.contactUs.email');
  const updatedMobileEmailText = useStudioKeyValueData(
    'studioSite.footer.contactUs.mobileEmailText',
  );

  const mobileChatText = useStudioKeyValueData('footer.contactUs.mobileChatText');
  const desktopChatText = useStudioKeyValueData('footer.contactUs.desktopChatText');

  const mobileSupportText = useStudioKeyValueData('footer.contactUs.mobileSupportText');
  const desktopSupportText = useStudioKeyValueData('footer.contactUs.desktopSupportText');

  return (
    <UpdatedBaseContactSection
      largeChatText={desktopChatText}
      emailAddress={emailAddress}
      largeEmailText={updatedEmailAddress}
      isMobile={isMobile}
      mobileSocialLinks={true}
      onChatRequest={openChat}
      smallChatText={mobileChatText}
      smallEmailText={updatedMobileEmailText}
      smallSupportText={mobileSupportText}
      supportPath={Links.studioSupport.path}
      largeSupportText={desktopSupportText}
    />
  );
};

const mapStateToProps = (state: ResponsiveSelectorState) => ({
  isMobile: getIsBreakpointLessThan(state, 'tablet'),
});

export default connect(mapStateToProps)(SocialMedia);
