import React from 'react';
import styled from 'styled-components';
import { a11yOutline, defaultSvgShadowForA11yContrast } from '@peloton/accessibility';
import { TogglePause, TogglePlay } from '@peloton/icons';
import { media } from '@peloton/styles';

export type ToggleButtonTheme = { theme?: 'light' | 'dark' };

type ToggleProps = ToggleButtonTheme & {
  clickHandler: () => void;
  pauseButtonLabel: string;
  playButtonLabel: string;
  autoPlay?: boolean;
};

const PlayPauseToggle: React.FC<React.PropsWithChildren<ToggleProps>> = ({
  clickHandler,
  pauseButtonLabel,
  playButtonLabel,
  autoPlay,
  theme,
  ...otherProps
}) => {
  const [isPlaying, setIsPlaying] = React.useState(autoPlay);

  const onClick = () => {
    clickHandler();
    setIsPlaying(!isPlaying);
  };

  return (
    <StyledButton onClick={onClick} theme={theme} {...otherProps}>
      {isPlaying ? (
        <TogglePause label={pauseButtonLabel} theme={theme} />
      ) : (
        <TogglePlay label={playButtonLabel} theme={theme} />
      )}
    </StyledButton>
  );
};

PlayPauseToggle.displayName = 'PlayPauseToggle';

const StyledButton = styled.button<ToggleButtonTheme>`
  z-index: 1;
  padding: 16px;
  ${a11yOutline};
  ${media.desktopLarge`
    padding: 24px;
  `}

  svg {
    width: 26px;
    ${props => props.theme === 'light' && defaultSvgShadowForA11yContrast}

    ${media.desktopLarge`
      width: 34px;
    `}
  }

  transition: opacity 250ms ease;
  &:hover {
    opacity: 0.6;
  }
`;

export default PlayPauseToggle;
