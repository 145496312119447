import { LONDON_LOCATION, NY_LOCATION } from './constants';

const EMAIL_REGEX = '^.+@.+\\..{2,}$';

const calcMinMaxSpotsRequired = (
  studio: string | undefined,
  modality: string | undefined,
) => {
  const studioCleaned = studio?.toLowerCase() || '';
  const modalityCleaned = modality?.toLowerCase() || '';
  const DEFAULT_VALUE = { min: 0, max: 0 };

  if (studioCleaned === NY_LOCATION) {
    switch (modalityCleaned) {
      case 'cycle':
        return { min: 10, max: 39 };
      case 'tread':
        return { min: 5, max: 16 };
      case 'strength':
      case 'kraft': // german translation for strength
        return { min: 4, max: 8 };
      case 'yoga':
        return { min: 4, max: 7 };
    }
  } else if (studioCleaned === LONDON_LOCATION) {
    switch (modalityCleaned) {
      case 'cycle':
        return { min: 7, max: 28 };
      case 'tread':
        return { min: 7, max: 14 };
    }
  }
  return DEFAULT_VALUE;
};

const determinePricing = (studio: string | undefined, modality: string | undefined) => {
  const studioCleaned = studio?.toLowerCase() || '';
  const modalityCleaned = modality?.toLowerCase() || '';
  const DEFAULT_VALUE = '';

  if (studioCleaned === NY_LOCATION) {
    switch (modalityCleaned) {
      case 'cycle':
        return 'nyCycle';
      case 'tread':
        return 'nyTread';
      case 'strength':
      case 'kraft': // german translation for strength
        return 'nyStrength';
      case 'yoga':
        return 'nyYoga';
    }
  } else if (studioCleaned === LONDON_LOCATION) {
    switch (modalityCleaned) {
      case 'cycle':
        return 'londonCycle';
      case 'tread':
        return 'londonTread';
    }
  }
  return DEFAULT_VALUE;
};

const isNA = (input: string | number) => (input ? input.toString() : 'N/A');

export { EMAIL_REGEX, calcMinMaxSpotsRequired, isNA, determinePricing };
