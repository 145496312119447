import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface BaseProps {
  src: string;
  autoPlay?: boolean;
  muted?: boolean;
}

type LazyVideoProps = BaseProps &
  React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>;

const LazyVideo: React.FC<React.PropsWithChildren<LazyVideoProps>> = ({
  src,
  muted = true,
  autoPlay,
  ...baseVideoProps
}) => {
  const { ref: viewRef, inView, entry } = useInView({ rootMargin: '200px' });
  const [resolvedSrc, setResolvedSrc] = useState<string | undefined>(undefined);

  const onCanPlay = () => {
    const target = entry?.target as HTMLMediaElement;
    if (resolvedSrc && target && target.autoplay && target.paused) {
      target.play();
      return;
    }
  };

  useEffect(() => {
    if (inView && entry && !resolvedSrc) {
      setResolvedSrc(src);
    }
  }, [inView, entry, resolvedSrc, src]);
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      {...baseVideoProps}
      autoPlay={autoPlay}
      muted={muted}
      ref={viewRef}
      src={resolvedSrc}
      onCanPlay={onCanPlay}
    >
      <source data-src={src} />
    </video>
  );
};

export default LazyVideo;
