import { Label, brand, grey, Support } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import type { ExtLink } from '@peloton/external-links';
import { media } from '@peloton/styles';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';

export type LinkSectionProps = {
  header: FooterLink;
  linkCollection: FooterLink[];
};

type FooterLink = {
  text: string;
  link: ExtLink;
  opensNewTab?: boolean;
};

const LinkSection: React.FC<React.PropsWithChildren<LinkSectionProps>> = ({
  header,
  linkCollection,
}) => {
  return (
    <Container data-test-id="linkSectionContainer">
      <UpdatedHeader is="h2" size="large" weight="medium">
        {header.text}
      </UpdatedHeader>
      <ul>
        {linkCollection.map(footerLink => (
          <ListItem key={footerLink.text || ''}>
            <StyledLink
              key={footerLink.text || ''}
              target={footerLink.opensNewTab ? '_blank' : '_self'}
              to={footerLink.link}
            >
              <Support is="span" size="large">
                <Markdown content={footerLink.text} />
              </Support>
            </StyledLink>
          </ListItem>
        ))}
      </ul>
    </Container>
  );
};

export default LinkSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 50px 40px 0;
  max-width: '141px';

  ${media.tabletXLarge`
    margin: 0 80px 80px 0;
  `}

  ${media.desktopLarge`
    margin: 0 80px 0 0;
  `}
`;

const StyledLink = styled(Link)`
  color: ${grey[70]};
  margin-bottom: 10px;
` as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const UpdatedHeader = styled(Label)`
  color: ${brand.darkest};
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 4px;
`;
