import { Label, brand, grey, spacing, Icon, Body } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ContentSizedDiv } from '@peloton/content-sized-div/ContentSizedDiv';
import { Markdown } from '@peloton/copy';
import { defaultTransition, hover } from '@peloton/styles';
import { unorphanLast } from '@peloton/text';
import type { StudioFaq } from '@studio/copy';

type FaqProps = {
  id: StudioFaq;
  question: string;
  answer: string;
  className?: string;
  propagateOpenState?: (x: boolean) => void;
};

const ariaQuestionId = (id: string) => `${id}-question`;
const ariaAnswerId = (id: string) => `${id}-answer`;

const FaqView: React.FC<React.PropsWithChildren<FaqProps>> = ({
  id,
  question,
  answer,
  className,
  propagateOpenState = () => undefined,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const answerId = ariaAnswerId(id);
  const questionId = ariaQuestionId(id);

  const QuestionContainer = ({ children }: { children: React.ReactNode }) => (
    <QuestionUpdated
      data-test-id="tu-update-faqview"
      is="h3"
      weight="medium"
      size="extraLarge"
    >
      {children}
    </QuestionUpdated>
  );

  const QuestionAnswerContainer = ({
    children,
    className1,
  }: {
    children: React.ReactNode;
    className1: string;
  }) => (
    <QuestionAnswerPairUpdated data-test-id="tu-update-faqview" className={className1}>
      {children}
    </QuestionAnswerPairUpdated>
  );

  return (
    <QuestionAnswerContainer className1={className || ''}>
      <HeightTransition>
        <QuestionContainer>
          <QuestionButtonUpdated
            data-test-id="tu-update-faqview"
            aria-controls={answerId}
            aria-expanded={isOpen}
            id={questionId}
            onClick={() => {
              setIsOpen(!isOpen);
              propagateOpenState(!isOpen);
            }}
          >
            {unorphanLast(question)}
            <ChevronIcon name="chevron" rotate={isOpen ? 90 : -90} />
          </QuestionButtonUpdated>
        </QuestionContainer>
        {isOpen && (
          <div aria-labelledby={questionId} id={answerId} role="region">
            <AnswerUpdated size="small">
              <Markdown content={answer} />
            </AnswerUpdated>
          </div>
        )}
      </HeightTransition>
    </QuestionAnswerContainer>
  );
};

export default FaqView;

const HeightTransition = styled(ContentSizedDiv)`
  ${defaultTransition()}
`;

const QuestionUpdated = styled(Label)`
  width: 100%;

  button {
    font-weight: inherit;
  }
`;

const QuestionAnswerPairUpdated = styled.div`
  ${defaultTransition('background-color')}
  align-items: center;
  border-bottom: 1px solid ${grey[60]};
  cursor: pointer;
  width: 100%;
  padding-bottom: ${spacing[8]};

  ${hover`
    background-color: ${brand.light};
  `}
`;

const QuestionButtonUpdated = styled.button`
  width: 100%;
  padding-top: ${spacing[24]};
  padding-bottom: ${spacing[16]};
  margin-right: -20px;
  display: flex;
  justify-content: space-between;

  :focus {
    background-color: ${brand.light};
  }
`;

const ChevronIcon = styled(Icon)`
  margin-right: 2px;
`;

const AnswerUpdated = styled(Body)`
  padding-bottom: ${spacing[16]};
  color: ${grey[70]};

  > span {
    display: block;

    :not(:last-child) {
      margin-bottom: 1em;
    }
  }

  a {
    text-decoration: underline;

    :hover {
      opacity: 0.7;
    }
  }

  h4 {
    font-weight: bold;
    margin-bottom: 8px;
  }

  ul + h4 {
    margin-top: 24px;
  }
`;
