import { black, white } from '@pelotoncycle/design-system';
import { createGlobalStyle } from 'styled-components';

type VideoModalStyleProps = {
  darkMode?: boolean;
  transparent?: boolean;
};

const ModalGlobalStyles = createGlobalStyle`
  .ReactModalPortal > * {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    overflow: auto;
    overscroll-behavior: contain;
    scroll-behavior: smooth;
    transition: opacity 400ms;
    -webkit-overflow-scrolling: touch;
  }

  .ReactModalPortal .ReactModal__Content--after-open {
    opacity: 1;

    &.video-modal {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props: VideoModalStyleProps) => {
        if (props.transparent === true) {
          return 'none';
        }
        return props.darkMode ? black : white;
      }};
    }
  }

  .ReactModalPortal .ReactModal__Content--before-close {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModalPortal .ReactModal__Overlay--before-close {
    pointer-events: none;
    opacity: 0;
  }

	.ReactModal__Body--open {
		overflow: hidden;
	}

`;

export default ModalGlobalStyles;
