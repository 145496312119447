import {
  brand,
  Display,
  spacing,
  white,
  Eyebrow,
  Body,
} from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { useStudioKeyValueData } from '@studio/copy';
import { MetadataTags } from '@studio/metadata';
import {
  GroupBookingsAndEventsCard,
  StudioInformationCard,
  StudioQuestionsCard,
} from './SupportCards';

const Contact: React.FC<React.PropsWithChildren<unknown>> = () => {
  const header = useStudioKeyValueData('pgSupport.header');
  const hoursTitle = useStudioKeyValueData('pgSupport.hours.title');
  const hoursOfOperation = useStudioKeyValueData(
    'studioSite.support.faq.hoursOfOperation',
  ).split('\n');

  return (
    <>
      <MetadataTags canonicalLink={Links.studioSupport} id="studioSite.pgSupport" />
      <Container>
        <>
          <DesktopHeadline size="large" is="h1" data-test-id="tu-update-contact">
            {header}
          </DesktopHeadline>
          <MobileHeadline size="medium" is="h1">
            {header}
          </MobileHeadline>
        </>
        <ContentMargin>
          <DesktopHoursOfOperationTitleUpdate
            size="medium"
            data-test-id="tu-update-contact"
          >
            {hoursTitle}
          </DesktopHoursOfOperationTitleUpdate>
          <MobileHoursOfOperationTitleUpdate size="small">
            {hoursTitle}
          </MobileHoursOfOperationTitleUpdate>
          <OperationContainer>
            {hoursOfOperation.map(hour => (
              <OperationInfo size="small" key={hour}>
                {hour}
              </OperationInfo>
            ))}
          </OperationContainer>
        </ContentMargin>
        <SupportCardsContainer>
          <StudioQuestionsCard />
          <GroupBookingsAndEventsCard />
          <StudioInformationCard />
        </SupportCardsContainer>
      </Container>
    </>
  );
};

export default Contact;

const Container = styled.div`
  background: linear-gradient(
    to bottom,
    ${brand.dark} 0%,
    ${brand.dark} 78%,
    ${brand.light} 78%,
    ${brand.light} 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 60px 20px 80px 20px;

  ${media.tabletXLarge`
    background: linear-gradient(
      to bottom,
      ${brand.dark} 0%,
      ${brand.dark} 57%,
      ${brand.light} 57%,
      ${brand.light} 100%
    );
    padding: 116px 0 100px 0;
  `}
`;

const ContentMargin = styled.div`
  margin: 0 auto;
  width: 280px;

  ${media.tabletXLarge`
    width: 580px;
  `}
  ${media.desktopLarge`
    width: 760px;
  `}
  ${media.desktopXLarge`
      width: 928px;
  `}
`;

const SupportCardsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${media.desktopLarge`
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

const HeadlineUpdate = styled(Display)`
  color: ${white};
  text-align: center;
  padding-bottom: ${spacing[48]};
`;

const DesktopHeadline = styled(HeadlineUpdate)`
  display: none;

  ${media.desktopXLarge`
    display: block;
  `}
`;

const MobileHeadline = styled(HeadlineUpdate)`
  display: block;

  ${media.desktopXLarge`
    display: none;
  `}
`;

const HoursOfOperationTitleUpdate = styled(Eyebrow)`
  color: ${white};
  text-align: center;
  padding-bottom: 10px;
`;

const DesktopHoursOfOperationTitleUpdate = styled(HoursOfOperationTitleUpdate)`
  display: none;

  ${media.desktopXLarge`
    display: block;
  `}
`;

const MobileHoursOfOperationTitleUpdate = styled(HoursOfOperationTitleUpdate)`
  display: block;

  ${media.desktopXLarge`
    display: none;
  `}
`;

const OperationInfo = styled(Body)`
  color: ${white};
  text-align: center;
`;

const OperationContainer = styled.div`
  margin-bottom: 70px;
`;
