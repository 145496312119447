import type React from 'react';

type ComponentRouteConfig = {
  path: string;
  component: React.ComponentType<React.PropsWithChildren<unknown>>;
};

export type RouteConfig = ComponentRouteConfig;

export const isComponentRouteConfig = (
  config: RouteConfig,
): config is ComponentRouteConfig => 'component' in config;

export type RouteTree = RouteConfig[];
