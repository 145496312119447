import { pathOr, pickAll, zipObj } from 'ramda';
import type { ImageData, ImageBreakpoints } from '@ecomm/copy/helpers/utils';
import { IMAGE_BREAKPOINT_NAMES, imageGuard } from '@ecomm/copy/helpers/utils';
import { useImageQuery } from '../hooks.generated';
import type { Image } from '../models.generated';

export type StudioImageType = {
  mobile: ImageData;
  tablet: ImageData;
  desktop: ImageData;
  desktopLarge: ImageData;
};

export const transformStudioImageData = (response: object) => {
  const {
    image = {
      mobile: { url: '', description: '' },
      tablet: { url: '', description: '' },
      desktop: { url: '', description: '' },
      desktopLarge: { url: '', description: '' },
    },
  }: { image: ImageBreakpoints } = pickAll(['image'], pathOr({}, ['data'], response));

  const imageDetails = Object.values(image)
    .filter(value => typeof value === 'object')
    .map(breakpointImage =>
      breakpointImage
        ? { url: breakpointImage.url, description: breakpointImage.description }
        : breakpointImage,
    );

  const safeImage = zipObj(
    IMAGE_BREAKPOINT_NAMES,
    imageGuard(imageDetails),
  ) as StudioImageType;
  IMAGE_BREAKPOINT_NAMES.forEach((breakpointName: string) => {
    safeImage[breakpointName].description = safeImage[breakpointName].description || '';
  });

  return {
    image: {
      ...safeImage,
    },
  };
};

const useStudioImageData = (id: Image) => {
  const res = useImageQuery({ variables: { id } });

  return transformStudioImageData(res);
};

export default useStudioImageData;
