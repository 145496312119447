import { Headline, spacing, Body, white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { unorphanLast } from '@peloton/text';
import { useStudioKeyValueData } from '@studio/copy';

const WelcomeSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  const welcomeHeader = useStudioKeyValueData('pgHome.welcome.header');
  const welcomeDescription = useStudioKeyValueData('studioSite.pgHome.welcome.body');

  return (
    <Container>
      <DesktopHeadline is="h2" size="medium" data-test-id="tu-update-welcome">
        {unorphanLast(welcomeHeader)}
      </DesktopHeadline>
      <MobileHeadline is="h2" size="small">
        {unorphanLast(welcomeHeader)}
      </MobileHeadline>
      <Body size="small">{unorphanLast(welcomeDescription)}</Body>
    </Container>
  );
};

export default WelcomeSection;

const Container = styled.div`
  background: ${white};
  padding: 60px 20px 60px 20px;
  text-align: center;

  ${media.tabletXLarge`
    padding: 100px 55px 55px 60px;
  `}

  ${media.desktopLarge`
    padding: 100px 183px 80px 183px;
  `}

  ${media.desktopXLarge`
    padding-left: 25%;
    padding-right: 25%;
  `}
`;

const DesktopHeadline = styled(Headline)`
  display: none;
  padding-bottom: ${spacing[16]};

  ${media.desktopXLarge`
    display: block;
  `}
`;

const MobileHeadline = styled(Headline)`
  display: block;
  padding-bottom: ${spacing[16]};

  ${media.desktopXLarge`
    display: none;
  `}
`;
