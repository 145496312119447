import { white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { unorphanLast } from '@peloton/text';
import { Image } from '@ecomm/image';
import { useStudioImageData, useStudioKeyValueData } from '@studio/copy';
import DescriptionComponent from './DescriptionComponent';

const DesktopLayout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const experienceHeader = useStudioKeyValueData('pgHome.info.experienceHeader');
  const experienceDescription = useStudioKeyValueData(
    'studioSite.pgHome.info.experienceBody',
  );
  const broadcastHeader = useStudioKeyValueData('pgHome.info.broadcastHeader');
  const broadcastDescription = useStudioKeyValueData('pgHome.info.broadcastBody');
  const spaceHeader = useStudioKeyValueData('studioSite.pgHome.info.spaceHeader');
  const spaceDescription = useStudioKeyValueData('studioSite.pgHome.info.spaceBody');
  const {
    image: {
      desktop: { url: experienceImageSrc, description: experienceImageAlt },
    },
  } = useStudioImageData('studioSite.pgHome.info.experienceImage');
  const {
    image: {
      desktop: { url: broadcastImageSrc, description: broadcastImageAlt },
    },
  } = useStudioImageData('studioSite.pgHome.info.broadcastImage');
  const {
    image: {
      mobile: { url: spaceImageSrc, description: spaceImageAlt },
    },
  } = useStudioImageData('studioSite.pgHome.info.spaceImage');
  return (
    <Container data-test-id="desktop-layout">
      <ColumnContainer>
        <LeftColumn>
          <ExperienceImage
            src={experienceImageSrc}
            alt={experienceImageAlt}
            breakpointOptions={{
              mobile: {
                src: experienceImageSrc,
                width: 458,
              },
            }}
          />
          <BroadcastDescriptionContainer>
            <DescriptionComponent
              header={unorphanLast(broadcastHeader)}
              description={unorphanLast(broadcastDescription)}
            />
          </BroadcastDescriptionContainer>
        </LeftColumn>
        <RightColumn>
          <ExperienceDescriptionContainer>
            <DescriptionComponent
              header={unorphanLast(experienceHeader)}
              description={unorphanLast(experienceDescription)}
            />
          </ExperienceDescriptionContainer>
          <BroadcastImageContainer>
            <BroadcastImage
              src={broadcastImageSrc}
              alt={broadcastImageAlt}
              breakpointOptions={{
                mobile: {
                  src: broadcastImageSrc,
                  width: 378,
                },
              }}
            />
          </BroadcastImageContainer>
        </RightColumn>
      </ColumnContainer>
      <Row>
        <SpaceImage
          src={spaceImageSrc}
          alt={spaceImageAlt}
          breakpointOptions={{
            mobile: {
              src: spaceImageSrc,
              width: 537,
            },
          }}
        />
        <SpaceDescriptionContainer>
          <DescriptionComponent
            header={unorphanLast(spaceHeader)}
            description={unorphanLast(spaceDescription)}
          />
        </SpaceDescriptionContainer>
      </Row>
    </Container>
  );
};

export default DesktopLayout;

const Container = styled.div`
  background: ${white};
  padding: 0 0 120px 0;
  display: flex;
  flex-direction: column;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
`;

const LeftColumn = styled(Column)`
  align-items: flex-end;
`;

const RightColumn = styled(Column)`
  align-items: center;
  justify-content: space-around;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 50px;
`;

const BroadcastImageContainer = styled.div`
  align-self: flex-start;
  padding-left: 60px;

  ${media.desktopXLarge`
    padding-left: 100px;
  `}
`;

const ExperienceImage = styled(Image)`
  width: 458px;
`;

const BroadcastImage = styled(Image)`
  width: 378px;
`;

const SpaceImage = styled(Image)`
  width: 537px;
`;

const ExperienceDescriptionContainer = styled.div`
  align-self: start;
  margin: 0 0 0 110px;

  ${media.desktopXLarge`
    margin-left: 150px;
  `}
`;

const BroadcastDescriptionContainer = styled.div`
  margin: 120px 90px 120px 0;

  ${media.desktopXLarge`
    margin-right: 130px;
  `}
`;

const SpaceDescriptionContainer = styled.div`
  padding-left: 75px;
  align-self: center;

  ${media.desktopXLarge`
    padding-left: 90px;
  `}
`;
