import { connect } from 'react-redux';
import type { ReducerState } from '@ecomm/feature-toggle';
import { _getIsToggleActive } from '@ecomm/feature-toggle';
import type { ToggleId } from './models';

export const getIsToggleActive = (t: ToggleId) => _getIsToggleActive<ToggleId>(t);

const toMapStateToProps = <T extends ToggleId>(toggleId: T) => (state: ReducerState) =>
  ({
    [toggleId]: getIsToggleActive(toggleId)(state),
  } as { [P in T]: boolean });

export default (toggleId: ToggleId) => connect(toMapStateToProps(toggleId));
