import { path, pickAll } from 'ramda';
import type { Metadata } from '../hooks.generated';
import { useMetadataQuery } from '../hooks.generated';
import type { Metadata as StudioMetadataEnum } from '../models.generated';

export type StudioMetadataType = {
  description: string;
  image: { url: string };
  keywords: string[];
  ogtype: string;
  title: string;
};

export const transformStudioMetadataData = (response: object) => {
  const { description, image, key, keywords, ogtype, title } = pickAll<any, Metadata>(
    ['description', 'image', 'key', 'keywords', 'ogtype', 'title'],
    path(['data', 'metadata'], response) || {},
  );

  const fallbackText = `Studio Metadata: ${key}`;

  return {
    description: description || fallbackText,
    image: image || { url: '' },
    keywords: (keywords || []) as string[],
    ogtype: ogtype || fallbackText,
    title: title || fallbackText,
  };
};

const useStudioMetadataData = (id: StudioMetadataEnum) => {
  const res = useMetadataQuery({ variables: { id } });

  return transformStudioMetadataData(res);
};

export default useStudioMetadataData;
