import { localSubdomainToAppName } from './localSubdomainToAppName';
import { APPS } from './meta';
import { toHostnameComponents } from './toHostnameComponents';
import { toSubSubdomainComponents } from './toSubSubdomainComponents';

export type LocalHostnameEnv = {
  type: 'local';
  app: string; // TODO: limit to actual apps?
  tld: string; // TODO: limit to actual TLDs?
};

export type ProdHostnameEnv = {
  type: 'prod';
  app: string; // TODO: limit to actual apps?
  tld: string; // TODO: limit to actual TLDs?
  prodTestID?: string; // For prod-test only
  urlApp?: string;
};

export type UatHostnameEnv = {
  type: 'uat';
  api?: string;
  app: string; // TODO: limit to actual apps?
  features?: string;
  instance?: string;
  subdomain: string;
  tld: string; // TODO: limit to actual TLDs?
  urlApp?: string;
};

export type HostnameEnv = LocalHostnameEnv | ProdHostnameEnv | UatHostnameEnv;

/**
 * Given a certain hostname, returns a `HostnameEnv` containing information
 * about the environment that the hostname is intended to be run in.
 *
 * @example
 * toHostnameEnv('www.onepeloton.com')
 * -> { type: 'prod', app: 'www', tld: 'com' }
 *
 * @example
 * toHostnameEnv('deploy-preview-1234--members--lit.test.onepeloton.co.uk')
 * -> {
 *      type: 'uat',
 *      app: 'members',
 *      features: 'lit',
 *      instance: 'deploy-preview-1234',
 *      subdomain: 'test',
 *      tld: 'co.uk',
 *    }
 *
 * @param hostname A valid hostname, such as from window.location.hostname
 */
export const toHostnameEnv = (hostname: string | undefined): HostnameEnv | undefined => {
  const hostnameComponents = toHostnameComponents(hostname);

  if (!hostnameComponents) {
    logInvalidEnvError(hostname);
    return undefined;
  }

  const { subdomain, subSubdomain, subSubSubdomain, tld } = hostnameComponents;

  if (([...APPS, 'prod-test'] as ReadonlyArray<string>).includes(subdomain)) {
    // If the subdomain *is* an app-name, then we're in production.
    // If the subdomain *is* prod-test, then the app-name is the subSubdomain
    let app = subdomain;

    // If the subSubdomain is 'cra', then the app is 'www'
    if (subdomain === 'prod-test' && subSubdomain) {
      app = subSubdomain === 'cra' ? 'www' : subSubdomain;
    }

    return {
      type: 'prod',
      app,
      tld,
      prodTestID: subSubSubdomain,
      urlApp: subSubdomain,
    };
  } else if (subSubdomain) {
    // If there is a sub-subdomain, parse it as the correct environment
    const subSubdomainComponents = toSubSubdomainComponents(subSubdomain);

    if (!subSubdomainComponents) {
      logInvalidEnvError(hostname);
      return undefined;
    }

    const { api, app, features, instance, urlApp, isUATProdApp } = subSubdomainComponents;

    // If is a private uat prod URL
    if (isUATProdApp) {
      return {
        type: 'prod',
        app,
        tld,
        prodTestID: instance,
      };
    }

    return {
      type: 'uat',
      api,
      app,
      features,
      instance,
      subdomain,
      tld,
      urlApp,
    };
  } else {
    // If the subdomain isn't an app-name, and there's no sub-subdomain,
    // then we should be looking at a local environment url.
    const app = localSubdomainToAppName(subdomain);

    if (!app) {
      logInvalidEnvError(hostname);
      return undefined;
    }

    return {
      type: 'local',
      app,
      tld,
    };
  }
};

const logInvalidEnvError = (hostname: string | undefined) => {
  if (hostname !== 'localhost') {
    console.trace();
    console.error(`Could not parse environment from hostname: ${hostname}`);
  }
};
