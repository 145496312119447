import { useHeroQuery } from '../hooks.generated';
import type { CtaWithUrl, Hero } from '../models.generated';
import type { StudioCtaWithUrlDataType } from './useStudioCtaWithUrlData';
import { transformCtaWithUrlData } from './useStudioCtaWithUrlData';

export type StudioHeroType = {
  eyebrow: string | null;
  headline: string | null;
  ctasWithUrls?: StudioCtaWithUrlDataType[];
  video: string | null;
};

export const transformStudioHeroData = (response: any, key: Hero) => {
  const eyebrow = response.data?.hero?.eyebrow;
  const headline = response.data?.hero?.headline;
  const ctasWithUrlsCollection = response.data?.hero?.ctasWithUrlsCollection;
  const video = response.data?.hero?.video?.url;
  const poster = response.data?.hero?.poster?.url;

  const ctasWithUrls = ctasWithUrlsCollection.items.map(
    (item: {
      __typename: 'CtaWithUrl';
      key: CtaWithUrl;
      targetBlank?: boolean;
      text?: string;
      link?: {
        url?: string;
      };
    }) => transformCtaWithUrlData({ data: { ctaWithUrl: item } }, item?.key),
  ) as StudioCtaWithUrlDataType[];

  const fallbackText = !headline && !eyebrow ? `Hero: ${key}` : '';

  return {
    headline: headline || fallbackText,
    eyebrow: eyebrow || fallbackText,
    video: video || fallbackText,
    poster: poster || fallbackText,
    ctasWithUrls,
  };
};

const useStudioHeroData = (id: Hero) => {
  const res = useHeroQuery({ variables: { id } });

  return transformStudioHeroData(res, id);
};

export default useStudioHeroData;
