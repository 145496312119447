import React from 'react';
import styled from 'styled-components';

import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { useStudioCtaWithUrlData, useStudioKeyValueData } from '@studio/copy';

import type { LinkSectionProps } from './LinkSection';
import LinkSection from './LinkSection';

const FooterLinks: React.FC<React.PropsWithChildren<unknown>> = () => {
  const studioHomeLinkText = useStudioKeyValueData('footer.studioLinks.studioHome');
  const updatedScheduleLinkText = useStudioKeyValueData(
    'studioSite.footer.studioLinks.schedule',
  );
  const instructorsLinkText = useStudioKeyValueData('footer.studioLinks.instructors');
  const supportLinkText = useStudioKeyValueData('footer.studioLinks.support');

  const updatedStudioLinkSectionProps: LinkSectionProps = {
    header: {
      text: studioHomeLinkText,
      link: Links.studioHome,
    },
    linkCollection: [
      {
        text: updatedScheduleLinkText,
        link: Links.studioSchedule,
      },
      {
        text: instructorsLinkText,
        link: Links.instructors,
        opensNewTab: true,
      },
      {
        text: supportLinkText,
        link: Links.studioSupport,
      },
    ],
  };

  const pelotonHomeLinkText = useStudioKeyValueData('footer.studioLinks.pelotonHome');
  const ourProductsLinkText = useStudioKeyValueData('footer.studioLinks.ourProducts');
  const ourStoryLinkText = useStudioKeyValueData('footer.studioLinks.ourStory');
  const boutiqueLinkText = useStudioKeyValueData('footer.studioLinks.boutique');
  const blogLinkText = useStudioKeyValueData('footer.studioLinks.blog');
  const careersLinkText = useStudioKeyValueData('footer.studioLinks.careers');

  const pelotonLinkSectionProps: LinkSectionProps = {
    header: {
      text: pelotonHomeLinkText,
      link: Links.peloton,
    },
    linkCollection: [
      {
        text: ourProductsLinkText,
        link: Links.peloton,
      },
      {
        text: ourStoryLinkText,
        link: Links.story,
      },
      {
        text: boutiqueLinkText,
        link: Links.boutique,
      },
      {
        text: blogLinkText,
        link: Links.blog,
      },
      {
        text: careersLinkText,
        link: Links.careers,
      },
    ],
  };

  const visitUsLinkText = useStudioKeyValueData('footer.studioLinks.visitUs');
  const { targetBlank, text, url } = useStudioCtaWithUrlData(
    'studioSite.studioStreetAddress',
  );

  const visitUsLinkSectionProps: LinkSectionProps = {
    header: {
      link: Links.studioLocation(url),
      opensNewTab: targetBlank,
      text: visitUsLinkText,
    },
    linkCollection: [
      {
        link: Links.studioLocation(url),
        opensNewTab: targetBlank,
        text,
      },
    ],
  };

  return (
    <LinkContainer data-test-id="linkContainer">
      <LinkSection {...updatedStudioLinkSectionProps} />
      <LinkSection {...pelotonLinkSectionProps} />
      <LinkSection {...visitUsLinkSectionProps} />
    </LinkContainer>
  );
};

export default FooterLinks;

const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.desktopLarge`
    margin-bottom: 40px;
  `}
`;
