import { throttle } from 'frame-throttle';
import React from 'react';
import ReactDOM from 'react-dom';
import type { StyledComponent } from 'styled-components';
import styled, { css } from 'styled-components';
import { isMsEdge } from '@peloton/browser';
import type { PlayPauseToggleProps } from '@ecomm/buttons';
import { PlayPauseHelpers, PlayPauseToggle } from '@ecomm/buttons';
import type { BreakpointOptions } from '@ecomm/image';
import LazyVideo from '@ecomm/video/LazyVideo';
import BackgroundImage from './BackgroundImage';

export type Props = React.VideoHTMLAttributes<HTMLVideoElement> & {
  posterBreakpointOptions: BreakpointOptions;
  hidePlayPause?: boolean;
  sideOfPlayPauseToggle?: string;
  videoShouldPlay?: boolean;
  playButtonLabel?: string;
  pauseButtonLabel?: string;
  verticalPositionOfPlayPauseToggle?: 'top' | 'bottom';
  handlePlayPauseClick?: () => void;
  hasExternalControl?: boolean;
  lazy?: boolean;
  src: string;
};

type SnapAxisState = {
  /**
   * The axis dimension which will be held at 100%
   */
  snapAxis?: 'width' | 'height';
};

type State = SnapAxisState & {
  defaultVideoShouldPlay: boolean;
};

/**
 * TODO: replace this class with just a `video` that sets `object-fit: cover`.
 *
 * The necessity for this contraption of a class comes from the lack of support
 * for `object-fit: cover` on videos in most browsers. The result being that the
 * poster image rarely actually lines up with the video. Because we often use the
 * first frame of the video as the poster image, it can be jarring if it doesn't
 * line up just right.
 */
export default class BackgroundVideo extends React.Component<Props, State> {
  public state: State = {
    snapAxis: undefined,
    defaultVideoShouldPlay: true,
  };

  private wrapperEl: HTMLDivElement;
  private videoEl: HTMLVideoElement | null;
  private videoAspectRatio: number;

  public componentDidMount() {
    window.addEventListener('resize', this.setSnapAxis);
  }

  public componentDidUpdate(prevProps: Props) {
    if (!this.props.hasExternalControl) {
      PlayPauseHelpers.playOrPauseVideo(this.shouldVideoPlay(), true, this.videoEl);
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.setSnapAxis);
  }

  private shouldVideoPlay = () => {
    if (this.props.hasExternalControl) {
      return Boolean(this.props.autoPlay);
    }

    if (this.props.videoShouldPlay === undefined) {
      return this.state.defaultVideoShouldPlay;
    }
    return this.props.videoShouldPlay;
  };

  private defaultHandlePlayPauseClick = () => {
    this.setState({ defaultVideoShouldPlay: !this.state.defaultVideoShouldPlay });
  };

  private handleLoadedData = (e: React.SyntheticEvent) =>
    e.currentTarget.classList.add('is-active');

  public render() {
    const {
      className,
      poster,
      posterBreakpointOptions,
      children,
      handlePlayPauseClick = this.defaultHandlePlayPauseClick,
      hidePlayPause,
      verticalPositionOfPlayPauseToggle = 'bottom',
      sideOfPlayPauseToggle = 'left',
      playButtonLabel,
      pauseButtonLabel,
      lazy = false,
      ...videoProps
    } = this.props;

    const VideoSwitch = lazy ? StyledLazyVideo : StyledVideo;
    return (
      <StyledBackgroundImage
        breakpointOptions={posterBreakpointOptions}
        className={className}
        ref={element => {
          this.getWrapperEl(element!);
        }}
        src={poster || ''}
      >
        <VideoSwitch
          {...videoProps}
          ref={(el: HTMLVideoElement | null) => {
            this.videoEl = el;
          }}
          snapAxis={this.state.snapAxis}
          onCanPlay={this.setVideoAspectRatio}
          onLoadedData={this.handleLoadedData}
          muted={true}
          autoPlay={this.shouldVideoPlay()}
        />
        {!hidePlayPause && (
          <StyledPlayPauseToggle
            theme="light"
            bearing={sideOfPlayPauseToggle}
            verticalPosition={verticalPositionOfPlayPauseToggle}
            autoPlay={this.shouldVideoPlay()}
            playButtonLabel={playButtonLabel}
            pauseButtonLabel={pauseButtonLabel}
            clickHandler={handlePlayPauseClick}
          />
        )}
        {children && <ContentDiv>{children}</ContentDiv>}
      </StyledBackgroundImage>
    );
  }

  private getWrapperEl = (component: BackgroundImage) =>
    component
      ? // eslint-disable-next-line react/no-find-dom-node
        (this.wrapperEl = ReactDOM.findDOMNode(component) as HTMLDivElement)
      : null;

  private setSnapAxis = throttle(() => {
    if (!this.wrapperEl || !this.videoAspectRatio) {
      return;
    }

    // Don't bother calculating snap axis on browsers that support object-fit
    if (supportsObjectFit()) {
      return;
    }

    const wrapperRect = this.wrapperEl.getBoundingClientRect();
    const wrapperAspectRatio = wrapperRect.width / wrapperRect.height;
    const snapAxis = wrapperAspectRatio >= this.videoAspectRatio ? 'width' : 'height';
    if (snapAxis !== this.state.snapAxis) {
      this.setState({ snapAxis });
    }
  });

  private setVideoAspectRatio = () => {
    if (this.videoEl) {
      this.videoAspectRatio = this.videoEl.videoWidth / this.videoEl.videoHeight;
      this.setSnapAxis();
    }
  };
}

const StyledBackgroundImage = styled(BackgroundImage)`
  overflow: hidden;
`;

const videoSnapCss = ({ snapAxis }: SnapAxisState) => {
  if (supportsObjectFit()) {
    return 'height: 100%; width: 100%;';
  }
  switch (snapAxis) {
    case 'height':
      return 'height: 100%;';
    case 'width':
      return 'width: 100%;';
    default:
      // undefined
      // hide the video if the snapAxis is unknown
      return 'opacity: 0;';
  }
};

type StylingProps = { bearing: string; verticalPosition: string };

const StyledPlayPauseToggle = (styled(PlayPauseToggle)`
  display: none;

  position: absolute;
  ${(props: StylingProps) => props.verticalPosition}: 0;
  ${(props: StylingProps) => props.bearing}: 0;
` as any) as StyledComponent<
  React.ComponentType<React.PropsWithChildren<PlayPauseToggleProps>>,
  {},
  StylingProps
>;

const StyledVideoCSS = css`  
  object-fit: cover;
  object-position: top center;

  position: relative;
  ${videoSnapCss}

  opacity: 0;
  &.is-active {
    opacity: 1;

    + ${StyledPlayPauseToggle} {
      display: block;
    }
  }
}`;

const StyledLazyVideo = styled(LazyVideo)`
  ${StyledVideoCSS}
`;

const StyledVideo = styled.video`
  ${StyledVideoCSS}
`;

const ContentDiv = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

let _supportsObjectFit: boolean;
const supportsObjectFit = () => {
  if (_supportsObjectFit === undefined) {
    const videoEl = document.createElement('video');
    _supportsObjectFit =
      (videoEl.style as any).objectFit !== undefined &&
      (videoEl.style as any).objectPosition !== undefined;

    // Edge supports objectFit and objectPosition on HtmlVideoElement, but not in the actual CSS,
    // So for edge we must mask _supportsObjectFit false.
    if (_supportsObjectFit && isMsEdge()) {
      _supportsObjectFit = false;
    }
  }
  return _supportsObjectFit;
};
