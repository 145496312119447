import React from 'react';
import styled from 'styled-components';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { trackSelectedLinkItem } from '../analytics';
import { MenuListItem, UpdatedLinkText } from './styles';

export type MenuItemLink = {
  dataTestId: string;
  disabled?: boolean;
  linkText: string;
  target?: string;
  to: any;
};

type MenuListItemWithLinkProps = {
  item: MenuItemLink;
  handleMenuItemKeyDown: any;
  menuItemRef: React.RefObject<HTMLElement>;
};

const MenuListItemWithLink: React.FC<
  React.PropsWithChildren<MenuListItemWithLinkProps>
> = ({
  item: { dataTestId, linkText, target, to },
  handleMenuItemKeyDown,
  menuItemRef,
}) => {
  return (
    <MenuListItem role="none" key={dataTestId}>
      <DropdownLink
        data-test-id={dataTestId}
        onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
          handleMenuItemKeyDown(e);
        }}
        onClick={() => {
          trackSelectedLinkItem(linkText);
        }}
        ref={menuItemRef}
        role="menuitem"
        tabIndex={-1}
        target={target}
        to={to}
      >
        <UpdatedLinkText is="span" size="medium">
          {linkText}
        </UpdatedLinkText>
      </DropdownLink>
    </MenuListItem>
  );
};

export default MenuListItemWithLink;

const DropdownLink = (styled(Link)`
  display: block;
  &:focus {
    outline: auto;
  }
` as any) as React.ComponentType<
  React.PropsWithChildren<
    { tabIndex?: number; ref?: React.RefObject<HTMLElement> } & LinkProps
  >
>;
