import type { AnalyticsInterface } from './analytics';

const NullAnalytics: AnalyticsInterface = {
  track() {
    // No-op
  },

  identify() {
    // No-op
  },

  group() {
    // No-op
  },

  reset() {
    // No-op
  },

  load() {
    // No-Op
  },

  page() {
    // No-Op
  },

  ready() {
    // No-Op
  },

  anonymousId() {
    // No-Op
  },
};

export default NullAnalytics;
