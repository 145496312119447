import { css } from 'styled-components';

export const buttonCaretBookAClass = () => css`
  &[aria-expanded='true']::before {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    border-top: 8px solid transparent;
    bottom: -17px;
    content: '';
    display: block;
    height: 0;
    left: calc((100% / 2) - 4px);
    position: absolute;
    transform: rotate(90deg);
    width: 0;
  }
`;

export const buttonCaretFindAClass = () => css`
  &[aria-expanded='true']::before {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    border-top: 8px solid transparent;
    bottom: -12px;
    content: '';
    display: block;
    height: 0;
    left: calc((100% / 2) - 4px);
    position: absolute;
    transform: rotate(90deg);
    width: 0;
  }
`;

export const buttonCaretTeamUp = () => css`
  &[aria-expanded='true']::before {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    border-top: 8px solid transparent;
    bottom: -22px;
    content: '';
    display: block;
    height: 0;
    left: calc((100% / 2) - 4px);
    position: absolute;
    transform: rotate(90deg);
    width: 0;
  }
`;

export const buttonCaretZingfit = () => css`
  &[aria-expanded='true']::before {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fff;
    border-top: 8px solid transparent;
    bottom: -17px;
    content: '';
    display: block;
    height: 0;
    left: calc((100% / 2) - 8px);
    position: absolute;
    transform: rotate(90deg);
    width: 0;
  }
`;
