import styled from 'styled-components';
import { media } from '@peloton/styles';

const HeaderButton = styled.button`
  display: inline-flex;
  margin: auto 16px;
  padding: 2px;
  width: 24px;
  ${media.desktopLarge`
        width: 30px;
      `}
  margin: 6px 32px auto 26px;
  padding: 0;
  height: 28px;
  width: 28px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export default HeaderButton;
