import React, { useEffect } from 'react';
import { Markdown } from '@peloton/copy';
import { useErrorReporter } from '@peloton/error-reporting';
import { Links } from '@peloton/links';
import { unorphanLast } from '@peloton/text';
import { InlineLink } from '@ecomm/links';
import { useStudioKeyValueData } from '@studio/copy';
import { useDatadogRum } from '../DatadogRUMContext';
import Layout from '../Layout';
import { StudioPageError } from '../PageError';
import { trackErrorPage } from './analytics';
import { ContentContainer, InnerContent, Title, Subtitle } from './shared';

const ErrorPage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Layout>
    <ErrorContent />
  </Layout>
);

export default ErrorPage;

const ErrorContent: React.FC<React.PropsWithChildren<unknown>> = () => {
  const title = useStudioKeyValueData('pgError.title');
  const subtitle = useStudioKeyValueData('pgError.subtitle');

  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  useEffect(() => {
    reportError(new StudioPageError());
  }, [reportError]);

  const datadogRum = useDatadogRum();

  useEffect(() => {
    if (datadogRum) {
      datadogRum.addAction('Viewed Error Page');
    }
  }, [datadogRum]);

  useEffect(() => {
    trackErrorPage();
  }, []);

  return (
    <ContentContainer>
      <InnerContent>
        <Title>{unorphanLast(title)}</Title>
        <Subtitle>
          <Markdown
            content={unorphanLast(subtitle)}
            values={{
              supportUrl: Links.studioSupport.path,
            }}
            markdown={{ renderers: { link: InlineLink } }}
          />
        </Subtitle>
      </InnerContent>
    </ContentContainer>
  );
};
