import React from 'react';
import { useStudioKeyValueData } from '@studio/copy';
import FooterBar from './FooterBar';

const BottomBar: React.FC<React.PropsWithChildren<unknown>> = () => {
  const caPrivacyNoticeText = useStudioKeyValueData('footer.footerBar.caPrivacyNotice');
  const updatedCopyrightText = useStudioKeyValueData(
    'studioSite.footer.footerBar.copyright',
  );
  const ipPolicyText = useStudioKeyValueData('footer.footerBar.ipPolicy');
  const membershipTermsText = useStudioKeyValueData('footer.footerBar.membershipTerms');
  const privacyPolicyText = useStudioKeyValueData('footer.footerBar.privacyPolicy');
  const termsOfServiceText = useStudioKeyValueData('footer.footerBar.termsOfService');
  return (
    <FooterBar
      caPrivacyNoticeText={caPrivacyNoticeText}
      ccpaNotice={true}
      copyrightText={updatedCopyrightText}
      ipPolicyText={ipPolicyText}
      membershipTermsText={membershipTermsText}
      privacyPolicyText={privacyPolicyText}
      termsOfServiceText={termsOfServiceText}
      newLocaleNav={false}
    />
  );
};

export default BottomBar;
