import { slate } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { superRed } from '@ecomm/colors';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { body, secondaryHeading } from '@ecomm/typography';
import { Layout } from '@studio/layout';
import { flexCenterColumn } from '@studio/styles';

const UnsubscribePage = () => (
  <Layout>
    <Container>
      <Heading>Sorry to see you&nbsp;go!</Heading>
      <Body>You have been unsubscribed from Peloton Studios NY&nbsp;emails.</Body>
      <Body>
        Return to studio class&nbsp;
        <StyledLink to={Links.studioSchedule}>schedule</StyledLink>.
      </Body>
    </Container>
  </Layout>
);

export default UnsubscribePage;

const Container = styled.div`
  ${flexCenterColumn}
  justify-content: center;
  padding: 180px 20px 360px;
  text-align: center;
`;

const Heading = styled.h1`
  ${secondaryHeading}
  font-size: 36px;
  margin-bottom: 20px;
`;

const Body = styled.p`
  ${body}
  color: ${slate[50]};
  font-size: 16px;
`;
const StyledLink = styled(Link)`
  color: ${superRed};
` as React.ComponentType<React.PropsWithChildren<LinkProps>>;
