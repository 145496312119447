import { Headline, grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { StudioFaq } from '@studio/copy';
import { useStudioFaqData } from '@studio/copy';
import FaqView from './FaqView';

type Props = {
  id: StudioFaq;
};

const Faqs: React.FC<React.PropsWithChildren<Props>> = ({ id }) => {
  const faqGroup = useStudioFaqData(id);

  return (
    <section data-test-id={id}>
      <SectionContent>
        <FaqCategory>
          <FAQHeader is="h2" size="small" data-test-id="tu-updtae-faqs">
            {faqGroup.category}
          </FAQHeader>
          {faqGroup.faqs.map(({ key, question, answer }) => (
            <StyledFaq key={key} id={key} question={question} answer={answer} />
          ))}
        </FaqCategory>
      </SectionContent>
    </section>
  );
};

const SectionContent = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 20px 40px 20px;
  ${media.tablet`
    padding: 0 30px 60px 30px;
  `}
  ${media.desktop`
    padding: 0 30px 80px 30px;
  `}
`;

const FaqCategory = styled.div`
  margin-top: 60px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const StyledFaq = styled(FaqView)`
  ul {
    list-style-type: disc;
    margin-left: 25px;
  }
`;

const FAQHeader = styled(Headline)`
  border-bottom: 1px solid ${grey[60]};
  padding-bottom: 20px;
`;

export default Faqs;
