import { brand, grey } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import { CloseIcon } from '@ecomm/icons';

export const Container = styled.button`
  border-radius: 100%;
  cursor: pointer;
  fill: ${grey[70]};
  padding: 12.5px;
  position: absolute;
  right: 20px;
  top: 7.5px;

  ${hoverTransition({
    property: 'fill',
    to: brand.darkest,
  })}

  svg {
    width: 15px;
    height: 15px;
    display: block;
  }
`;

const CloseIconContainer: React.FC<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  onCloseClick,
  testId = '',
}) => (
  <Container
    style={{ backgroundColor: transparentize(0.05, backgroundColor) }}
    onClick={onCloseClick}
    aria-label="Close"
  >
    <CloseIcon data-test-id={testId ? `${testId}CloseIcon` : 'flyoutCloseIcon'} />
  </Container>
);

export type Props = {
  backgroundColor: string;
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
  testId?: string;
};

export default CloseIconContainer;
