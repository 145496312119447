export const URL = 'https://graphql.contentful.com/content/v1/spaces/6jnflt57iyzx';

export const QUERY_TYPENAME_MAP = {
  keyValue: 'KeyValue',
  image: 'Image',
  metadata: 'Metadata',
  hero: 'Hero',
  ctaWithUrl: 'CtaWithUrl',
  valueProp: 'ValueProp',
  faq: 'Faq',
  section: 'Section',
} as const;

export const TAG = 'studio';
