import { Display, spacing, LinkButton } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { BreakpointEither } from '@peloton/responsive';
import { Hero, HeroMedia } from '@ecomm/hero/base';
import { useStudioHeroData, useStudioKeyValueData } from '@studio/copy';
import type { StudioCtaWithUrlDataType } from '@studio/copy/helpers/useStudioCtaWithUrlData';

interface IIntructorsVideoContainerProps {
  poster: string;
  playButtonLabel: string;
  pauseButtonLabel: string;
  video: string;
  headline: string;
  ctasWithUrls: StudioCtaWithUrlDataType[];
}

type InstructorButtonProps = {
  url: string;
  text: string;
  size: 'medium' | 'large';
  targetBlank: boolean;
};

const InstructorButtonProps = ({
  url,
  text,
  targetBlank,
  size,
}: InstructorButtonProps) => {
  return (
    <LinkButton
      href={url}
      data-test-id="instructorCtaButton"
      target={targetBlank ? '_blank' : undefined}
      size={size}
      color="light"
    >
      {text}
    </LinkButton>
  );
};

const IntructorsVideoContainer = ({
  poster,
  playButtonLabel,
  pauseButtonLabel,
  video,
  headline,
  ctasWithUrls,
}: IIntructorsVideoContainerProps) => (
  <Hero heroSize="short">
    <HeroMedia
      heroSize="standard"
      imageSrc={poster}
      playButtonLabel={playButtonLabel}
      pauseButtonLabel={pauseButtonLabel}
      minimumVideoBreakpoint="mobile"
      sideOfPlayPauseToggle="right"
      videoOptions={{
        videoProps: {
          videoPoster: poster,
          videoSrc: video,
          videoPosterOptions: {
            mobile: { width: 1400 },
          },
        },
      }}
    >
      <Container data-test-id="tu-update-video">
        <HeadlineContainer size="large" is="h2">
          {headline}
        </HeadlineContainer>
        {ctasWithUrls.map(({ key, text, url, targetBlank }, index) => (
          <BreakpointEither breakpoint="desktopLarge" key={`${key}${index}`}>
            <InstructorButtonProps
              url={url}
              targetBlank={targetBlank}
              size="medium"
              text={text}
            />
            <InstructorButtonProps
              url={url}
              targetBlank={targetBlank}
              size="large"
              text={text}
            />
          </BreakpointEither>
        ))}
      </Container>
    </HeroMedia>
  </Hero>
);

const InstructorsVideo: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { headline, ctasWithUrls, video, poster } = useStudioHeroData(
    'studioSite.pgHome.instructorsHero',
  );
  const playButtonLabel = useStudioKeyValueData('playPauseToggleDefaultPlayLabel');
  const pauseButtonLabel = useStudioKeyValueData('playPauseToggleDefaultPauseLabel');

  return (
    video && (
      <div data-test-id="meetInstructorsButton">
        <IntructorsVideoContainer
          poster={poster}
          playButtonLabel={playButtonLabel}
          pauseButtonLabel={pauseButtonLabel}
          video={video}
          headline={headline}
          ctasWithUrls={ctasWithUrls}
        />
      </div>
    )
  );
};

export default InstructorsVideo;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: white;
  height: 100%;
  width: 100%;
  position: relative;
`;

const HeadlineContainer = styled(Display)`
  padding-bottom: ${spacing[24]};
`;
