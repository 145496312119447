import { client as api } from '@studio/api';
import {
  SANDBOX_PSL_GROUP_BOOKING_FIELD_ID,
  SANDBOX_PSNY_GROUP_BOOKING_FIELD_ID,
  PROD_PSL_GROUP_BOOKING_FIELD_ID,
  PROD_PSNY_GROUP_BOOKING_FIELD_ID,
  LONDON_LOCATION,
  PROD_PSL_GROUP_ID,
  PROD_PSNY_GROUP_ID,
  SANDBOX_PSL_GROUP_ID,
  SANDBOX_PSNY_GROUP_ID,
  STRENGTH_OPTION_GERMAN,
  STRENGTH_OPTION,
} from './constants';
import type {
  RequestFormInputsType,
  ZendeskRequestFormType,
  SalesforceRequestFormType,
} from './models';
import { isNA } from './validations';

export const descriptionFormat = (data: RequestFormInputsType) => {
  const output = `
        Name: ${isNA(data.name)}
        Email: ${isNA(data.email)}
        Group Type: ${isNA(data.inquiryType)}
        Team Social Handle: ${isNA(data.teamSocialHandle)}
        Location: ${isNA(data.location)}
        Modality: ${isNA(data.modality)}
        Spots Required: ${isNA(data.spotsRequired)}
        1st Instructor Preference: ${isNA(data.instructorOnePreference)}
        2nd Instructor Preference: ${isNA(data.instructorTwoPreference)}
        3rd Instructor Preference: ${isNA(data.instructorThreePreference)}
        1st Date Preference: ${isNA(data.dateOnePreference)}
        2nd Date Preference: ${isNA(data.dateTwoPreference)}
        3rd Date Preference: ${isNA(data.dateThreePreference)}
        Tell us about your group: ${isNA(data.groupInfo)}
        Anything Else: ${isNA(data.anythingElse)}
      `;
  return output;
};
export const formatSubmitData = (data: RequestFormInputsType, isProd: boolean) => {
  // find region and make the request with it
  let fieldId;
  let groupId;

  if (data.location.toLowerCase() == LONDON_LOCATION) {
    if (isProd) {
      fieldId = PROD_PSL_GROUP_BOOKING_FIELD_ID;
      groupId = PROD_PSL_GROUP_ID;
    } else {
      fieldId = SANDBOX_PSL_GROUP_BOOKING_FIELD_ID;
      groupId = SANDBOX_PSL_GROUP_ID;
    }
  } else {
    if (isProd) {
      fieldId = PROD_PSNY_GROUP_BOOKING_FIELD_ID;
      groupId = PROD_PSNY_GROUP_ID;
    } else {
      fieldId = SANDBOX_PSNY_GROUP_BOOKING_FIELD_ID;
      groupId = SANDBOX_PSNY_GROUP_ID;
    }
  }

  const requestFormat = {
    ticket: {
      subject: 'Group Inquiry Form Submission',
      description: descriptionFormat(data),
      group_id: groupId,
      email: isNA(data.email),
      priority: 'normal',
      custom_fields: {
        id: fieldId,
        value: true,
      },
      requester: { name: isNA(data.name), email: isNA(data.email) },
    },
  };

  return requestFormat;
};

export const formatSalesforceSubmitData = (data: RequestFormInputsType) => {
  const castGermanToEnglish = (input: string) => {
    if (input.toLowerCase() === STRENGTH_OPTION_GERMAN) {
      return STRENGTH_OPTION;
    }
    return input;
  };

  const requestFormat = {
    ticket: {
      ticketData: {
        subject: 'Group Inquiry Form Submission',
        description: descriptionFormat(data),
        priority: 'medium',
        origin: 'Studio Inquiry Form',
        location: isNA(data.location),
        modality: castGermanToEnglish(isNA(data.modality)),
      },
      member: {
        lastName: isNA(data.name),
        email: isNA(data.email),
      },
    },
  };

  return requestFormat;
};

export const createZendeskRequestTicket = async (
  formData: ZendeskRequestFormType,
): Promise<any> => {
  const response = await api.post('ecomm/studio/create_request_ticket', formData);
  return response;
};

export const createSalesforceRequestTicket = async (
  formData: SalesforceRequestFormType,
): Promise<any> => {
  const response = await api.post(
    'ecomm/studio/create_salesforce_request_ticket',
    formData,
  );
  return response;
};
