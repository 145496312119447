import React from 'react';
import { createGlobalStyle } from 'styled-components';

const Overlay = () => {
  return <GlobalStyle />;
};

const GlobalStyle = createGlobalStyle`
.overlay-xray {
  outline: 2px dashed green;
  outline-offset: 0.5em;
  position: relative;
}

.overlay-xray:hover {
  cursor: pointer;
  outline: 2px dashed purple;
}

.overlay-xray-selected, .overlay-xray-selected:hover  {
  outline: 2px solid purple;
}
`;

// Links have :after that overrides

// Break down keys and fields and show only a key
// Show all info on the tooltip
// Add click event on the tooltip
// Make a pretty tootlip
//  reset styles
//  clean up text

// show ALL entries - optional

// Summary page slide out
// Markdown entries with nested html tags

// Add entry id

export default Overlay;
