import { Display, spacing, LinkButton } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { track } from '@peloton/analytics';
import { BreakpointEither } from '@peloton/responsive';
import { ScrolledIntoView } from '@peloton/scroll';
import { media } from '@peloton/styles';
import { ChevronWrapper } from '@ecomm/hero';
import { Hero, HeroMedia } from '@ecomm/hero/base';
import { Link } from '@ecomm/links';
import { cascadingContent } from '@ecomm/styles';
import {
  useStudioHeroData,
  useStudioImageData,
  useStudioKeyValueData,
} from '@studio/copy';

type HeroButtonProps = {
  url: string;
  text: string;
  size: 'medium' | 'large';
  targetBlank: boolean;
};

const HeroButton = ({ url, text, targetBlank, size }: HeroButtonProps) => {
  return (
    <Link
      to={url}
      onClick={trackClickBookClassCTA}
      target={targetBlank ? '_blank' : undefined}
      data-test-id="homeHeroCtaButton"
    >
      <LinkButton component={'span'} size={size} color="light">
        {text}
      </LinkButton>
    </Link>
  );
};

const HomeHero: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { headline, ctasWithUrls, video, poster } = useStudioHeroData(
    'studioSite.pgHome.hero',
  );
  const playButtonLabel = useStudioKeyValueData('playPauseToggleDefaultPlayLabel');
  const pauseButtonLabel = useStudioKeyValueData('playPauseToggleDefaultPauseLabel');
  const chevronLabel = useStudioKeyValueData('pgHome.chevronLabel');
  const { image } = useStudioImageData('studioSite.pgHome.londonCalling.hero');

  const videoOptions = video
    ? {
        videoProps: {
          videoPoster: poster,
          videoSrc: video,
          videoPosterOptions: {
            mobile: { width: 1400 },
          },
        },
      }
    : undefined;

  const heroPoster = {
    mobile: video ? poster : image?.mobile?.url || '',
    tablet: video ? poster : image?.tablet?.url || '',
    desktop: video ? poster : image?.desktop?.url || '',
    desktopLarge: video ? poster : image?.desktopLarge?.url || '',
  };

  return (
    <Hero heroSize="viewport">
      <ChevronWrapper label={chevronLabel}>
        <HeroMedia
          breakpointOverrides={{
            mobile: {
              width: 370,
              src: heroPoster.mobile,
            },
            tablet: {
              src: heroPoster.tablet,
            },
            desktop: {
              src: heroPoster.desktop,
            },
            desktopLarge: {
              src: heroPoster.desktopLarge,
            },
          }}
          heroSize="viewport"
          imageSrc={heroPoster.mobile}
          playButtonLabel={playButtonLabel}
          pauseButtonLabel={pauseButtonLabel}
          minimumVideoBreakpoint="mobile"
          sideOfPlayPauseToggle="right"
          videoOptions={videoOptions}
          style={{ backgroundPosition: 'center' }}
        >
          <Container>
            <CascadingText>
              <>
                <HeadlineContainer size="large" is="h1" data-test-id="tu-update-hero">
                  {headline}
                </HeadlineContainer>
                {ctasWithUrls.map(({ key, text, url, targetBlank }, index) => (
                  <BreakpointEither breakpoint="desktopLarge" key={`${key}${index}`}>
                    <HeroButton
                      url={url}
                      targetBlank={targetBlank}
                      size="medium"
                      text={text}
                    />
                    <HeroButton
                      url={url}
                      targetBlank={targetBlank}
                      size="large"
                      text={text}
                    />
                  </BreakpointEither>
                ))}
              </>
            </CascadingText>
          </Container>
        </HeroMedia>
      </ChevronWrapper>
    </Hero>
  );
};

export default HomeHero;

const trackClickBookClassCTA = () => track({ event: 'Clicked Book Class CTA' });

const CascadingText = styled(ScrolledIntoView)`
  ${cascadingContent}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: white;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 20px;
`;

const HeadlineContainer = styled(Display)`
  padding-bottom: ${spacing[24]};

  ${media.tabletLarge`
    padding-left: 30px;
    padding-right: 30px;
  `}
`;
