import { RegionSlug } from '@studio/regions';

export type TeamUpView = 'schedule.day' | 'my_account';

export const specificPages = {
  'schedule.day': ['schedule.month', 'schedule.week', 'schedule.day'],
  my_account: [
    'my_account',
    'my_account.upcoming_registrations',
    'my_account.profile',
    'my_account.memberships',
    'my_account.registrations',
    'my_account.payments',
    'my_account.documents',
    'my_account.notifications',
    'my_account.contact',
  ],
};

export const providerIds = {
  prod: {
    [RegionSlug.NewYork]: '7248695',
    [RegionSlug.London]: '7248663',
  },
  test: {
    [RegionSlug.NewYork]: '7881647',
    [RegionSlug.London]: '7881644',
  },
};

export const teamupIdentityProvider = {
  prod: 'peloton-customer',
  test: 'peloton-customer-stage',
};
