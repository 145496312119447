import type { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import type { LogoutOptions } from '@peloton/auth';
import { useOauth } from '@peloton/auth';
import { useLocale } from '@peloton/internationalize';
import { LOCAL_STORAGE_REDIRECT_PATH_KEY } from './constants';
import { getOauthFragment } from './oAuthFragment';

const useStudioOAuth = (redirectPath?: string, ignoreQueryParams?: boolean) => {
  const { loginWithRedirect, logout, ...rest } = useOauth();
  const locale = useLocale();

  return {
    ...rest,
    loginWithRedirect: (options?: RedirectLoginOptions) => {
      const redirectTo = redirectPath || window.location.pathname;
      const queryParams = ignoreQueryParams ? '' : window.location.search || '';
      localStorage.setItem(
        LOCAL_STORAGE_REDIRECT_PATH_KEY,
        [redirectTo, queryParams].join(''),
      );
      loginWithRedirect({
        fragment: getOauthFragment(locale),
        ...options,
        appState: {
          redirectApp: 'studio',
        },
      });
    },
    logout: (options: LogoutOptions, b?: boolean | undefined) => {
      logout({ logoutParams: { returnTo: window.location.origin } }, true);
    },
  };
};

export default useStudioOAuth;
