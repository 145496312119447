import { brand } from '@pelotoncycle/design-system';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';
import { BreakpointSwitch } from '@peloton/responsive';
import type { Omit } from '@peloton/types';
import type { BackgroundMediaProps } from '@ecomm/background-media';
import { BackgroundMedia } from '@ecomm/background-media';
import { PlayPauseHelpers } from '@ecomm/buttons';
import type { BreakpointOptions } from '@ecomm/image';
import type { HeroSize } from './Hero';
import { heroStyles } from './styles';
import type { VideoOptions } from './utils';
import { resolveBreakpointOptions, resolveVideoSrc, resolveVideoOptions } from './utils';

type BackgroundProps = Omit<BackgroundMediaProps, 'breakpointOptions' | 'videoProps'>;

type Props = BackgroundProps & {
  breakpointOverrides?: BreakpointOptions;
  children: JSX.Element;
  heroSize: HeroSize;
  minimumVideoBreakpoint?: 'mobile' | 'desktop';
  overlayOpacity?: number;
  pauseButtonLabel?: string;
  playButtonLabel?: string;
  sideOfPlayPauseToggle?: string;
  videoOptions?: VideoOptions;
  verticalPositionOfPlayPauseToggle?: 'top' | 'bottom';
};

const HeroMedia: React.FC<React.PropsWithChildren<Props>> = ({ ...props }) => {
  const { videoShouldPlay, handlePlayPauseClick } = PlayPauseHelpers.usePlayPause(true);

  return (
    <BreakpointSwitch
      breakpoints={{
        mobile: (
          <Media
            {...props}
            isMobile
            videoShouldPlay={videoShouldPlay}
            handlePlayPauseClick={handlePlayPauseClick}
          />
        ),
        desktop: (
          <Media
            {...props}
            videoShouldPlay={videoShouldPlay}
            handlePlayPauseClick={handlePlayPauseClick}
          />
        ),
      }}
    />
  );
};

export default HeroMedia;

const Media: React.FC<React.PropsWithChildren<Props & { isMobile?: boolean }>> = ({
  breakpointOverrides,
  children,
  heroSize,
  isMobile = false,
  minimumVideoBreakpoint = 'mobile',
  overlayOpacity = 0.2,
  pauseButtonLabel,
  playButtonLabel,
  videoOptions,
  ...props
}) => {
  const breakpointOpts = resolveBreakpointOptions(breakpointOverrides, heroSize);
  const opts = resolveVideoSrc(videoOptions, isMobile);
  const videoOpts = resolveVideoOptions(opts, minimumVideoBreakpoint);

  return (
    <StyledBackgroundMedia
      heroSize={heroSize}
      pauseButtonLabel={pauseButtonLabel}
      playButtonLabel={playButtonLabel}
      {...breakpointOpts}
      {...videoOpts}
      {...props}
    >
      <BackgroundOverlay opacity={overlayOpacity} />
      <Contents>{children}</Contents>
    </StyledBackgroundMedia>
  );
};

const StyledBackgroundMedia = styled(BackgroundMedia)`
  ${heroStyles}
`;

const BackgroundOverlay = styled.div`
  background-color: ${({ opacity }: { opacity: number }) =>
    transparentize(1 - opacity, brand.darkest)};
  height: 100%;
  position: absolute;
  width: 100%;
`;

const Contents = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;
