import React, { useState } from 'react';

export const playOrPauseVideo = (
  videoShouldPlay: boolean,
  isActive?: boolean,
  video?: HTMLVideoElement | null,
) => {
  if (!video) return;
  if (video.paused) {
    if (videoShouldPlay && isActive) {
      video.play();
    }
  } else if (!videoShouldPlay) {
    video.pause();
  }
};

export const usePlayPause = (shouldPlay: boolean) => {
  const [videoShouldPlay, setVideoShouldPlay] = useState(shouldPlay);
  const handlePlayPauseClick = () => {
    setVideoShouldPlay(!videoShouldPlay);
  };

  return {
    videoShouldPlay,
    handlePlayPauseClick,
  };
};

export const usePlayPauseWithHandler = (shouldPlay: boolean) => {
  const { videoShouldPlay, handlePlayPauseClick } = usePlayPause(shouldPlay);
  const vidWrapperRef = React.useRef<HTMLDivElement>(null);

  const videoClickHandler = (videoEl: HTMLVideoElement | null | undefined) => {
    if (!videoEl) {
      return;
    }
    if (videoShouldPlay) {
      videoEl.pause();
      handlePlayPauseClick();
      return;
    }
    if (!videoShouldPlay || videoEl.paused) {
      videoEl.play();
      handlePlayPauseClick();
    }
  };

  return {
    vidWrapperRef,
    videoClickHandler,
  };
};
