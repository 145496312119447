import { Eyebrow, brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import Flyout from '@peloton/flyout';
import { Links } from '@peloton/links';
import CloseIconContainer from '@ecomm/flyout/CloseIconContainer';
import { useStudioKeyValueData } from '@studio/copy';
import PanelAccountSection from '@studio/header/PanelAccountSection';
import { DarkLink, LinkSection } from './links';

type Props = {
  isOpen: boolean;
  ariaLabel: string;
  handleRequestClose(): void;
};

const PanelNav: React.FC<React.PropsWithChildren<Props>> = ({
  handleRequestClose,
  ...props
}) => {
  const instructorsText = useStudioKeyValueData('header.instructors');
  const faqText = useStudioKeyValueData('header.faq');

  return (
    <Flyout
      backgroundColor={brand.light}
      handleRequestClose={handleRequestClose}
      {...props}
    >
      <ContentContainer>
        <LinkSection>
          <DarkLink
            data-test-id="navPanelInstructorsLink"
            target="_blank"
            to={Links.instructors}
            onClick={handleRequestClose}
          >
            <Eyebrow is="span" size="medium">
              {instructorsText}
            </Eyebrow>
          </DarkLink>
          <DarkLink
            data-test-id="navPanelFaqLink"
            to={Links.studioSupport}
            onClick={handleRequestClose}
          >
            <Eyebrow is="span" size="medium">
              {faqText}
            </Eyebrow>
          </DarkLink>
        </LinkSection>
        <LinkSection>
          <PanelAccountSection handleRequestClose={handleRequestClose} />
        </LinkSection>
        <CloseIconContainer
          backgroundColor={brand.light}
          onCloseClick={handleRequestClose}
        />
      </ContentContainer>
    </Flyout>
  );
};

export default PanelNav;

const ContentContainer = styled.nav`
  padding: 75px 30px 50px;
  width: 260px;
`;
