import { useDecision } from '@optimizely/react-sdk';
import { Input, spacing, Body, Button } from '@pelotoncycle/design-system';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Markdown } from '@peloton/copy';
import { getIsProdEnv } from '@peloton/env';
import { media } from '@peloton/styles';
import { ErrorBanner } from '@ecomm/banners';
import { useStudioKeyValueData } from '@studio/copy';
import {
  formatSubmitData,
  createZendeskRequestTicket,
  createSalesforceRequestTicket,
  formatSalesforceSubmitData,
} from './api';
import {
  LONDON_LOCATION,
  ENGLISH_COMMUNITY_GROUP,
  GERMAN_COMMUNITY_GROUP,
  ENGLISH_CELEBRATION_GROUP,
  GERMAN_CELEBRATION_GROUP,
  ENGLISH_OTHER_GROUP,
  GERMAN_OTHER_GROUP,
  ENGLISH_CORPORATE_GROUP,
  GERMAN_CORPORATE_GROUP,
} from './constants';
import DateInput from './DateInput';
import type { RequestFormInputsType } from './models';
import Select from './Select';
import { EMAIL_REGEX, calcMinMaxSpotsRequired, determinePricing } from './validations';

interface IInquiryFormProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function InquiryForm({ setIsOpen }: IInquiryFormProps) {
  // Form State
  const [name, setName] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');
  const [inquiryType, setInquiryType] = useState<string | undefined>(undefined);
  const [socialHandle, setSocialHandle] = useState<string | undefined>('');
  const [location, setLocation] = useState<string | undefined>('');
  const [instructor1, setInstructor1] = useState<string | undefined>('');
  const [instructor2, setInstructor2] = useState<string | undefined>('');
  const [instructor3, setInstructor3] = useState<string | undefined>('');
  const [date1, setDate1] = useState('');
  const [date2, setDate2] = useState('');
  const [date3, setDate3] = useState('');
  const [groupInfo, setGroupInfo] = useState<string | undefined>('');
  const [modality, setModality] = useState<string | undefined>(undefined);
  const [spots, setSpots] = useState<string | undefined>('');
  const [anythingElse, setAnythingElse] = useState<string | undefined>('');
  const [isError, setIsError] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const isProd = useSelector(getIsProdEnv);
  const [sfInquiryForm] = useDecision('inquiry_form_salesforce_transition', {
    autoUpdate: true,
  });
  const issfInquiryFormOn = sfInquiryForm?.enabled;

  // If location changes, clear modality and spots
  useEffect(() => {
    setModality('');
    setSpots('');
  }, [location, setModality, setSpots]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const values: RequestFormInputsType = {
      email: email || '',
      name: name || '',
      inquiryType: inquiryType || '',
      teamSocialHandle: socialHandle || '',
      location: location || '',
      instructorOnePreference: instructor1 || '',
      instructorTwoPreference: instructor2 || '',
      instructorThreePreference: instructor3 || '',
      dateOnePreference: date1,
      dateTwoPreference: date2,
      dateThreePreference: date3,
      groupInfo: groupInfo || '',
      modality: modality || '',
      spotsRequired: spots || '',
      anythingElse: anythingElse || '',
    };

    let formattedData;

    // to be refactored after salesforce transition completes
    if (issfInquiryFormOn) {
      formattedData = formatSalesforceSubmitData(values);
      setIsError(false);
      setIsProcessing(true);

      try {
        await createSalesforceRequestTicket(formattedData);

        setIsOpen(false);
      } catch (err) {
        setIsProcessing(false);
        setIsError(true);
      }
    } else {
      formattedData = formatSubmitData(values, isProd);
      setIsError(false);
      setIsProcessing(true);

      try {
        await createZendeskRequestTicket(formattedData);

        setIsOpen(false);
      } catch (err) {
        setIsProcessing(false);
        setIsError(true);
      }
    }
  };

  // Copy
  const submitButtonCopy = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.submitButton',
  );
  const spotsRequiredDescription = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.spotsRequiredDescription',
  );
  const groupDescription = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.groupDescription',
  );
  const anythingElseDescription = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.anythingElseDescription',
  );
  const socialHandleDescription = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.socialHandleDescription',
  );
  const bannerErrorText = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.bannerErrorText',
  );
  const datePreferenceInfoLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.datePreferenceInfoLabel',
  );
  const nyModalityOptions = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.nyModalityOptions',
  ).split('\n');
  const londonModalityOptions = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.londonModalityOptions',
  ).split('\n');
  const locationOptions = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.locationOptions',
  ).split('\n');
  const inquiryTypeOptions = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.inquiryTypeOptions',
  ).split('\n');
  const nameFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.nameFieldLabel',
  );
  const emailFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.emailFieldLabel',
  );
  const inquiryTypeFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.inquiryTypeFieldLabel',
  );
  const modalityFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.modalityFieldLabel',
  );
  const spotsRequiredFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.spotsRequiredFieldLabel',
  );
  const instructorOnePreferenceFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.instructorOnePreferenceFieldLabel',
  );
  const instructorTwoPreferenceFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.instructorTwoPreferenceFieldLabel',
  );
  const instructorThreePreferenceFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.instructorThreePreferenceFieldLabel',
  );
  const dateOnePreferenceFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.dateOnePreferenceFieldLabel',
  );
  const dateTwoPreferenceFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.dateTwoPreferenceFieldLabel',
  );
  const dateThreePreferenceFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.dateThreePreferenceFieldLabel',
  );
  const groupInfoFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.groupInfoFieldLabel',
  );
  const locationFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.locationFieldLabel',
  );
  const teamSocialHandleFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.teamSocialHandleFieldLabel',
  );
  const anythingElseFieldLabel = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.anythingElseFieldLabel',
  );
  const communityOrCelebration = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.communityOrCelebration',
  );
  const corporateOrOther = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.corporateOrOther',
  );
  const nyCycle = useStudioKeyValueData('studioSite.support.groupBookingsForm.nyCycle');
  const nyTread = useStudioKeyValueData('studioSite.support.groupBookingsForm.nyTread');
  const nyYoga = useStudioKeyValueData('studioSite.support.groupBookingsForm.nyYoga');
  const nyStrength = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.nyStrength',
  );
  const londonCycle = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.londonCycle',
  );
  const londonTread = useStudioKeyValueData(
    'studioSite.support.groupBookingsForm.londonTread',
  );

  const isCommunityOrCelebration =
    inquiryType?.toLowerCase() === ENGLISH_COMMUNITY_GROUP ||
    inquiryType?.toLowerCase() === GERMAN_COMMUNITY_GROUP ||
    inquiryType?.toLowerCase() === ENGLISH_CELEBRATION_GROUP ||
    inquiryType?.toLowerCase() === GERMAN_CELEBRATION_GROUP;

  const isCorporateOrOther =
    inquiryType?.toLowerCase() === ENGLISH_CORPORATE_GROUP ||
    inquiryType?.toLowerCase() === GERMAN_CORPORATE_GROUP ||
    inquiryType?.toLowerCase() === ENGLISH_OTHER_GROUP ||
    inquiryType?.toLowerCase() === GERMAN_OTHER_GROUP;

  const generatePricing = (loc: string, mod: string) => {
    const pricing = determinePricing(loc, mod);
    const pricings = {
      nyCycle,
      nyTread,
      nyYoga,
      nyStrength,
      londonCycle,
      londonTread,
    };

    return pricings[pricing];
  };

  const generateMinMaxSpots = (loc: string, mod: string) => {
    return `Min: ${calcMinMaxSpotsRequired(loc, mod).min} Max: ${
      calcMinMaxSpotsRequired(loc, mod).max
    }`;
  };

  return (
    <form onSubmit={handleSubmit} data-test-id="inquiry-form">
      <InputContainer>
        <Input
          name="name"
          label={`${nameFieldLabel} *`}
          type="text"
          value={name}
          handleChange={(s: string | undefined) => setName(s)}
          required
          data-test-id="name"
        />
      </InputContainer>
      <InputContainer>
        <Input
          name="email"
          label={`${emailFieldLabel} *`}
          type="email"
          value={email}
          handleChange={(s: string | undefined) => setEmail(s)}
          required
          data-test-id="email"
          pattern={EMAIL_REGEX}
        />
      </InputContainer>
      <InputContainer>
        <Select
          id="inquiryType"
          name="inquiryType"
          label={`${inquiryTypeFieldLabel} *`}
          items={inquiryTypeOptions}
          value={inquiryType || ''}
          onChange={setInquiryType}
          required
          data-test-id="inquiryType"
        />
      </InputContainer>
      {(inquiryType?.toLowerCase() === ENGLISH_COMMUNITY_GROUP ||
        inquiryType?.toLowerCase() === GERMAN_COMMUNITY_GROUP) && (
        <InputContainerWithDesc>
          <InputDescription size="small" id="socialHandleDesc">
            {socialHandleDescription}
          </InputDescription>
          <Input
            name="socialHandle"
            label={teamSocialHandleFieldLabel}
            type="text"
            value={socialHandle}
            handleChange={(s: string | undefined) => setSocialHandle(s)}
            data-test-id="socialHandle"
            aria-describedby="socialHandleDesc"
          />
        </InputContainerWithDesc>
      )}
      <InputContainer>
        <Select
          id="location"
          name="location"
          label={`${locationFieldLabel} *`}
          items={locationOptions}
          value={location || ''}
          onChange={setLocation}
          required
          data-test-id="location"
        />
      </InputContainer>
      <InputContainer>
        <Input
          name="instructorOne"
          label={`${instructorOnePreferenceFieldLabel} *`}
          type="text"
          value={instructor1}
          handleChange={(s: string | undefined) => setInstructor1(s)}
          required
          data-test-id="instructor1"
        />
      </InputContainer>
      <InputContainer>
        <Input
          name="instructorTwo"
          label={instructorTwoPreferenceFieldLabel}
          type="text"
          value={instructor2}
          handleChange={(s: string | undefined) => setInstructor2(s)}
          data-test-id="instructor2"
        />
      </InputContainer>
      <InputContainer>
        <Input
          name="instructorThree"
          label={instructorThreePreferenceFieldLabel}
          type="text"
          value={instructor3}
          handleChange={(s: string | undefined) => setInstructor3(s)}
          data-test-id="instructor3"
        />
      </InputContainer>
      <InputContainerWithDesc>
        <fieldset>
          <InputLegend size="small">{datePreferenceInfoLabel}</InputLegend>
          <InputContainer>
            <DateInput
              id="datePreferenceOne"
              name="datePreferenceOne"
              value={date1}
              onChange={setDate1}
              testID="datePreferenceOne"
              label={`${dateOnePreferenceFieldLabel} *`}
              required
            />
          </InputContainer>
          <InputContainer>
            <DateInput
              id="datePreferenceTwo"
              name="datePreferenceTwo"
              value={date2}
              onChange={setDate2}
              testID="datePreferenceTwo"
              label={`${dateTwoPreferenceFieldLabel} *`}
              required
            />
          </InputContainer>
          <InputContainer>
            <DateInput
              id="datePreferenceThree"
              name="datePreferenceThree"
              value={date3}
              onChange={setDate3}
              testID="datePreferenceThree"
              label={`${dateThreePreferenceFieldLabel} *`}
              required
            />
          </InputContainer>
        </fieldset>
      </InputContainerWithDesc>
      <InputContainerWithDesc>
        <InputDescription size="small" id="groupDesc">
          {groupDescription}
        </InputDescription>
        <Input
          name="groupInfo"
          label={groupInfoFieldLabel}
          type="text"
          value={groupInfo}
          handleChange={(s: string | undefined) => setGroupInfo(s)}
          data-test-id="groupInfo"
          aria-describedby="groupDesc"
        />
      </InputContainerWithDesc>
      <InputContainer>
        <Select
          name="modality"
          id="modality"
          label={`${modalityFieldLabel} *`}
          items={
            location?.toLowerCase() === LONDON_LOCATION
              ? londonModalityOptions
              : nyModalityOptions
          }
          value={modality || ''}
          onChange={setModality}
          required
          data-test-id="modality"
        />
      </InputContainer>
      <InputContainerWithDesc>
        <InputDescription size="small" id="spotsDesc">
          {spotsRequiredDescription}
        </InputDescription>
        {location && modality && (
          <InputDescription size="small" id="spotsDesc">
            {isCorporateOrOther && generateMinMaxSpots(location, modality)}
            {isCommunityOrCelebration && (
              <Markdown
                dataTestId="group-inquiry-pricing"
                content={generatePricing(location, modality)}
              />
            )}
          </InputDescription>
        )}
        <Input
          name="spots"
          label={`${spotsRequiredFieldLabel} *`}
          type="number"
          value={spots}
          handleChange={(s: string | undefined) => setSpots(s)}
          data-test-id="spots"
          required
          aria-describedby="spotsDesc"
          min={calcMinMaxSpotsRequired(location, modality).min}
          max={calcMinMaxSpotsRequired(location, modality).max}
        />
      </InputContainerWithDesc>
      <InputContainerWithDesc>
        <InputDescription size="small" id="anythingElseDesc">
          {anythingElseDescription}
        </InputDescription>
        <Input
          name="anythingElse"
          label={anythingElseFieldLabel}
          type="text"
          value={anythingElse}
          handleChange={(s: string | undefined) => setAnythingElse(s)}
          data-test-id="anythingElse"
          aria-describedby="anythingElseDesc"
        />
      </InputContainerWithDesc>
      <FormCopy size="small">
        {isCommunityOrCelebration && (
          <span data-test-id="group-inquiry-community-desc">
            <Markdown content={communityOrCelebration} />
          </span>
        )}
        {isCorporateOrOther && (
          <span data-test-id="group-inquiry-corporate-desc">
            <Markdown content={corporateOrOther} />
          </span>
        )}
      </FormCopy>
      <ButtonContainer>
        <SubmitMobile
          type="submit"
          size="small"
          color="dark"
          width="adaptive"
          isLoading={isProcessing}
        >
          {submitButtonCopy}
        </SubmitMobile>
        <SubmitDesktop
          type="submit"
          size="medium"
          color="dark"
          width="adaptive"
          isLoading={isProcessing}
          data-test-id="submit-button"
        >
          {submitButtonCopy}
        </SubmitDesktop>
        {isError && (
          <StyledErrorBanner data-test-id="form-error">
            {bannerErrorText}
          </StyledErrorBanner>
        )}
      </ButtonContainer>
    </form>
  );
}

const InputContainer = styled.div`
  margin-bottom: ${spacing[8]};
`;

const InputContainerWithDesc = styled.div`
  margin-bottom: ${spacing[8]};
  padding-top: ${spacing[16]};
`;

const FormCopy = styled(Body)`
  text-align: left;
  margin-bottom: ${spacing[8]};
  padding-top: ${spacing[16]};

  > span > span {
    display: block;
  }
`;

const InputDescription = styled(Body)`
  margin-bottom: ${spacing[8]};
  text-align: left;

  > span {
    display: block;
  }
`;

const InputLegend = styled(Body)`
  margin-bottom: ${spacing[8]};
  text-align: left;
`;

const ButtonContainer = styled.div`
  margin-top: 34px;

  ${media.tablet`
    margin-top: 24px;
  `}
`;

const SubmitMobile = styled(Button)`
  display: block !important;

  ${media.tablet`
    display: none !important;
  `}
`;

const SubmitDesktop = styled(Button)`
  display: none !important;

  ${media.tablet`
    display: block !important;
  `}
`;

const StyledErrorBanner = styled(ErrorBanner)`
  margin-top: 10px;
`;

export default InquiryForm;
