import { throttle } from 'frame-throttle';
import React from 'react';
import { isRobot } from '@peloton/browser';
import { GlobalUiStateContext } from '@acme-ui/global/GlobalUiStateProvider';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  activeClass?: string;
  alreadyInViewDelay?: number;
  className?: string;
  speed?: number;
  Element?: React.ElementType;
};

type State = {
  active: boolean;
};

const isCrawler = typeof window !== 'undefined' && isRobot(window.navigator.userAgent);

class ScrolledIntoView extends React.Component<Props, State> {
  context: any;
  static contextType = GlobalUiStateContext;

  static defaultProps = {
    activeClass: 'scrolled-into-view',
    alreadyInViewDelay: 500,
    className: '',
    speed: 0.5,
  };
  state: State = { active: false };
  ref: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => this.checkScrollPosition(1), this.props.alreadyInViewDelay);
  }

  componentWillUnMount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public render() {
    const {
      className,
      activeClass,
      alreadyInViewDelay,
      speed,
      Element = 'div',
      ...attrs
    } = this.props;
    const isActive = this.state.active || isCrawler || this.context.reduceMotion;

    return (
      <Element
        ref={this.ref}
        {...attrs}
        className={isActive ? `${className} ${this.props.activeClass}` : className}
        data-test-id={isActive ? 'scrolled-into-view' : 'not-scrolled-into-view'}
      />
    );
  }

  private handleScroll = throttle(() =>
    this.checkScrollPosition(this.props.speed || 0.5),
  );

  private checkScrollPosition = (speedAdjustment: number) => {
    const ref = this.ref.current;
    if (ref) {
      const containerTop = ref.getBoundingClientRect().top;
      if (containerTop < window.innerHeight * speedAdjustment) {
        this.setState({ active: true });
        window.removeEventListener('scroll', this.handleScroll);
      }
    }
  };
}

export default ScrolledIntoView;
