import React from 'react';
import { Route } from 'react-router';
import { ConnectedSwitch } from '@peloton/redux';
import { TrackedPage } from '@studio/router';
import type { RouteConfig, RouteTree } from './models';
import { isComponentRouteConfig } from './models';
import NotFoundPage from './NotFoundPage';

type Props = {
  root: RouteTree;
};

const Routes: React.FC<React.PropsWithChildren<Props>> = ({ root }) => {
  return (
    <ConnectedSwitch>
      {...root.map(toRoute)}
      <NotFoundPage />
    </ConnectedSwitch>
  );
};

// NOTE: *** Do not change the toRoute function into a component ***
//       The <Switch> component switches between its direct children,
//       so if toRoute were a component, we would get undefined behavior.
const toRoute = (config: RouteConfig) => {
  if (isComponentRouteConfig(config)) {
    const { component: Component } = config;
    return (
      <Route
        exact
        key={config.path}
        {...config}
        component={() => (
          <TrackedPage>
            <Component />
          </TrackedPage>
        )}
      />
    );
  }

  throw Error('Invalid RouteConfig');
};

export default Routes;
