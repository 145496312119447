import { Icon, black, white, grey, brand } from '@pelotoncycle/design-system';
import { addWeeks, format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useStudioKeyValueData } from '@studio/copy';

type DateInputProps = {
  id: string;
  name: string;
  value: string;
  label: string;
  testID: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  required?: boolean;
};

interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  hasDate?: boolean;
}

interface CustomInputElement extends HTMLInputElement {
  showPicker: () => void;
}

function ExtendedDiv({ hasDate, children, ...props }: DivProps) {
  return <div {...props}>{children}</div>;
}

function DateInput({
  id,
  name,
  value,
  onChange,
  label,
  testID,
  required,
}: DateInputProps) {
  const datePickerRef = useRef<CustomInputElement | null>(null);
  const [hasDatePicker, setHasDatePicker] = useState(false);
  const hasDate = !!value;
  const minDate = format(addWeeks(new Date(), 10), 'yyyy-MM-dd');
  const chooseDateLabel = useStudioKeyValueData('studioSite.form.dateInput');

  useEffect(() => {
    if (datePickerRef?.current?.showPicker) {
      setHasDatePicker(true);
    }
  }, [datePickerRef, setHasDatePicker]);

  const openDatePicker = () => {
    datePickerRef?.current?.focus();
    datePickerRef?.current?.showPicker();
  };

  return (
    <>
      <DateInputContainer hasDate={hasDate}>
        <DateLabel htmlFor={id}>{label}</DateLabel>
        <StyledDateInput
          ref={datePickerRef}
          id={id}
          type="date"
          name={name}
          data-test-id={testID}
          placeholder=""
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
          required={required}
          min={minDate}
        />
        {hasDatePicker && (
          <IconStyled type="button" aria-label={chooseDateLabel} onClick={openDatePicker}>
            <Icon name="calendarEmpty" height={20} />
          </IconStyled>
        )}
      </DateInputContainer>
    </>
  );
}

export default DateInput;

const DateInputContainer = styled(ExtendedDiv)`
  line-height: normal;
  font-weight: normal;
  -webkit-font-smoothing: auto;
  position: relative;
  color: ${black};
  background-color: ${white};
  border: 1px solid ${grey[70]};
  border-radius: 4px;
  -webkit-transition: box-shadow 100ms ease-in-out;
  transition: box-shadow 100ms ease-in-out;
  -webkit-transition: border-color 100ms ease-in-out;
  transition: border-color 100ms ease-in-out;
  outline: none;
  box-shadow: 0 0 0 0 transparent;

  > input {
    color: ${props => (props.hasDate ? brand.darkest : 'transparent')};

    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

  ${props =>
    props.hasDate &&
    `
      > label {
        font-size: 10px;
        -webkit-transform: translateY(-7px);
        -ms-transform: translateY(-7px);
        transform: translateY(-7px);
      }
  `}

  &:focus-within {
    border-color: ${brand.darkest};

    > label {
      font-size: 10px;
      -webkit-transform: translateY(-7px);
      -ms-transform: translateY(-7px);
      transform: translateY(-7px);
    }

    > input {
      color: ${brand.darkest};
    }
  }
`;

const DateLabel = styled.label`
  line-height: normal;
  font-weight: normal;
  -webkit-font-smoothing: auto;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  -webkit-transition: font-size 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  -webkit-transition: font-size 100ms ease-in-out, transform 100ms ease-in-out;
  transition: font-size 100ms ease-in-out, transform 100ms ease-in-out;
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: transparent;
  color: ${grey[70]};
  font-size: 1rem;
`;

const StyledDateInput = styled.input`
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: calc((1.5rem + 0.125rem) - 4px) 1rem 0.75rem;
  font-size: 1rem;
  line-height: 20px;
  height: 54px;

  &:focus {
    box-shadow: inset 0 0 0 1px;
    border-radius: 3px;
  }

  &::-webkit-date-and-time-value {
    text-align: left;
  }
`;

const IconStyled = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  height: 20px;
`;
