import { Eyebrow } from '@pelotoncycle/design-system';
import React from 'react';
import { connect } from 'react-redux';
import useSWR from 'swr';
import type { UserSelectorState } from '@peloton/auth';
import { isUserSignedIn } from '@peloton/auth';
import { useStudioOAuth } from '@studio/auth';
import { useStudioKeyValueData } from '@studio/copy';
import HeaderAccountDropdown from './HeaderAccountDropdown';
import NavLink from './NavLink';

const LoginButton = () => {
  const headerLogin = useStudioKeyValueData('header.logIn');
  const { loginWithRedirect } = useStudioOAuth();

  return (
    <NavLink onClick={() => loginWithRedirect()} data-test-id="logInButton">
      <Eyebrow is="span" size="small">
        {headerLogin}
      </Eyebrow>
    </NavLink>
  );
};

type Props = { isLoggedIn: boolean };

const HeaderAccountSection: React.FC<React.PropsWithChildren<Props>> = ({
  isLoggedIn,
}) => {
  const { logout, isAuthenticated, getIsAuthenticated } = useStudioOAuth();

  const checkAuthenticated = async () => {
    await getIsAuthenticated();
    return {};
  };

  useSWR('updatingGetIsAuthenticated', checkAuthenticated, {
    revalidateOnFocus: true,
  });

  return isLoggedIn || isAuthenticated ? (
    <HeaderAccountDropdown onLogOutClick={() => logout({})} />
  ) : (
    <li>
      <LoginButton />
    </li>
  );
};
const mapStateToProps = (state: UserSelectorState) => ({
  isLoggedIn: isUserSignedIn(state),
});

export default connect(mapStateToProps)(HeaderAccountSection);
