import { throttle } from 'frame-throttle';
import React from 'react';

/**
 * Calculates the viewport height, *excluding any banners or navigation bars*
 * Also meant as a fix for Safari Mobile, whose `vh` does not shrink to account
 * for the address bar on top and toolbar on bottom.
 *
 * @param ref Reference to the element that you intend to set to the size of the viewport
 * By passing a ref to a page element, we can use its offset on the page to figure out
 * all the banner / nav height we need to factor out.
 */
const useViewportHeight = (ref: React.RefObject<HTMLElement> | null) => {
  const [height, setHeight] = React.useState('100vh');

  const onResize = throttle(() => {
    if (!ref?.current) {
      return;
    }
    const { top: docTop, height: docHeight } = document.body.getBoundingClientRect();
    const newHeight = Math.min(
      docHeight - (ref?.current.getBoundingClientRect().top - docTop),
      docHeight,
    );
    setHeight(`${newHeight}px`);
  });

  React.useEffect(() => {
    onResize();
  }, [ref]);

  React.useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  });

  return height;
};

export default useViewportHeight;
