import { Icon } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { toSocialHref } from '@peloton/external-links';
import { Links } from '@peloton/links';
import { media } from '@peloton/styles';
import { L2 } from '@ecomm/links';

const SocialLinks: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Container>
    <SocialLink href={toSocialHref(Links.getFacebookLink())} label="facebook">
      <Icon name={'facebook'} />
    </SocialLink>
    <SocialLink href={toSocialHref(Links.studioInstagram)} label="instagram">
      <Icon name={'instagram'} />
    </SocialLink>
    <SocialLink href={toSocialHref(Links.twitter)} label="twitter">
      <Icon name={'twitter'} />
    </SocialLink>
    <SocialLink href={toSocialHref(Links.youtube)} label="youtube">
      <Icon name={'youtube'} />
    </SocialLink>
  </Container>
);

export default SocialLinks;

const Container = styled.ul`
  margin-top: 40px;

  ${media.smallScreen`
    margin-top: 22px;
  `}

  ${media.tablet`
    margin-top: 7px;
  `}

  ${media.desktopLarge`
    margin-top: 25px;
  `}
`;

type LinkProps = {
  href: string;
  label: string;
};

const SocialLink: React.FC<React.PropsWithChildren<LinkProps>> = props => (
  <LinkItem>
    <StyledLink href={props.href} target="_blank" rel="noopener" aria-label={props.label}>
      {props.children}
    </StyledLink>
  </LinkItem>
);

const LinkItem = styled.li`
  display: inline-block;
`;

const StyledLink = styled(L2)`
  margin-right: 15px;
  display: block;
`;
