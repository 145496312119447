import { camelize } from 'humps';
import React from 'react';
import styled from 'styled-components';
import { getCurrentRelativePathname } from '@peloton/internationalize/models/path';
import { SPACE_ID } from '@ecomm/contentful/toContentfulClient';
import type { EncodedKeyParts } from '@ecomm/copy-xray/toEncodedKey';
import parseCopyXray from './copyXrayParser';

type Props = {
  selectedKeyHierarchy: EncodedKeyParts[] | null;
  allowExport: boolean;
};

class CopyXrayDisabledException extends Error {
  constructor() {
    super('Copy Xray not enabled.');
  }
}

const saveFile = (blob: Blob, filename: string) => {
  const anchor = document.createElement('a');
  anchor.download = filename;
  anchor.href = URL.createObjectURL(blob);
  anchor.addEventListener('click', e => {
    setTimeout(() => URL.revokeObjectURL(anchor.href), 30 * 1000);
  });
  anchor.click();
};

const downloadCopyData = (filename: string) => {
  try {
    const content = parseCopyXray(extractCopyData(document));
    saveFile(new Blob([JSON.stringify(content, null, 2)]), filename);
    alert(`${content.length} content entries saved in ${filename}`);
  } catch (e) {
    if (e instanceof CopyXrayDisabledException) {
      const shouldTurnOnCopyXray = window.confirm(
        'Copy Xray export requires Copy Xray mode to be enabled?\n\nWould you like to enable Copy Xray mode?',
      );
      if (shouldTurnOnCopyXray) {
        const joinChar = window.location.search === '' ? '?' : '&';
        window.location.replace(
          `${window.location.toString()}${joinChar}toggleOn=copyXray&toggleOn=copyXrayExport`,
        );
      }
    } else {
      alert(e.message);
    }
  }
};

const extractCopyData = (document: Document): string[] => {
  const copyXrayElements = document.querySelectorAll('[data-contentful-key]');
  if (copyXrayElements.length === 0) {
    throw new CopyXrayDisabledException();
  }
  // @ts-expect-error bypass possible null items
  return Array.from(copyXrayElements)
    .map(e => e.getAttribute('data-contentful-key'))
    .filter(el => el !== null);
};

export const POPUP_CSS_CLASS = 'overlay-xray-popup';
export const XrayPopup: React.FC<React.PropsWithChildren<Props>> = ({
  selectedKeyHierarchy,
  allowExport = false,
}) => {
  const pathname = getCurrentRelativePathname() || '';
  const filename = `${(pathname === '/' ? ' homepage' : pathname)
    .slice(1)
    .replace(/\//g, '.')}.json`;
  return (
    <>
      {(selectedKeyHierarchy || allowExport) && (
        <Popup data-test-id="overlay-xray-popup" className={POPUP_CSS_CLASS}>
          {allowExport && (
            <button
              data-test-id="overlay-xray-export"
              data-download-file={filename}
              onClick={() => {
                downloadCopyData(filename);
              }}
            >
              Download all visible copy xray on page
            </button>
          )}
          {selectedKeyHierarchy &&
            selectedKeyHierarchy.map(({ parentKey, parentType, field }, idx) => {
              const showSearchUrl = parentType !== 'Asset' && parentKey !== 'undefined';
              const searchUrl = showSearchUrl
                ? `https://app.contentful.com/spaces/${SPACE_ID}/views/entries?searchText=${encodeURIComponent(
                    parentKey,
                  )}&contentTypeId=${camelize(parentType)}`
                : null;

              return (
                <div key={parentKey}>
                  <div data-test-id={`overlay-xray-popup-key-${idx}`}>
                    Key: {parentKey}
                  </div>
                  <div data-test-id={`overlay-xray-popup-type-${idx}`}>
                    Content Type: {parentType}
                  </div>
                  <div data-test-id={`overlay-xray-popup-field-${idx}`}>
                    Field: {field}
                  </div>
                  {searchUrl && (
                    <Link
                      href={searchUrl}
                      target="_blank"
                      rel="noreferrer"
                      data-test-id={`overlay-xray-popup-link-${idx}`}
                    >
                      Contentful Search (ALPHA)
                    </Link>
                  )}
                </div>
              );
            })}
        </Popup>
      )}
    </>
  );
};

const Popup = styled.div`
  padding: 1rem;
  white-space: pre;
  top: 0;
  background: #ffffcc;
  z-index: 1000;
  position: sticky;
  width: 100%;
  font-size: 14px;
  line-height: normal;
  display: grid;
  grid-gap: 1rem;
`;

const Link = styled.a`
  text-decoration: underline;
`;
