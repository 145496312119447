import { grey, Eyebrow } from '@pelotoncycle/design-system';
import type React from 'react';
import styled from 'styled-components';

export const UpdatedLinkText = styled(Eyebrow)`
  &:hover {
    color: ${grey[70]};
  }
`;

export const MenuListItem = (styled.li`
  white-space: nowrap;
  &:not(:first-child) {
    margin-top: 14px;
  }
` as any) as React.ComponentType<
  React.PropsWithChildren<
    { ref?: React.RefObject<HTMLElement> } & React.HTMLAttributes<any>
  >
>;
