import { Headline, Body, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';

type Props = {
  header: string;
  description: string;
};

const DescriptionComponent: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  description,
}) => {
  return (
    <ContainerUpdated data-test-id="tu-desc-update">
      <HeadlineContainer is="h3" size="small">
        {header}
      </HeadlineContainer>
      <Body size="small">{description}</Body>
    </ContainerUpdated>
  );
};

export default DescriptionComponent;

const ContainerUpdated = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 60px;

  ${media.tabletXLarge`
    padding-left: 80px;
    padding-right: 80px;
  `}

  ${media.desktopLarge`
    padding: 0;
    width: 242px;
  `}
`;

const HeadlineContainer = styled(Headline)`
  padding-bottom: ${spacing[16]};
`;
