import { pathOr, pickAll } from 'ramda';
import { MissingKeyError } from '@peloton/copy';
import type { ReportError } from '@peloton/error-reporting';
import type { KeyValue } from '../models.generated';

const genericTransformKeyValueData = (
  response: object,
  model: string,
  contentType: string,
  id?: KeyValue,
  reportError?: ReportError,
): string => {
  const { value }: { value?: string } = pickAll(
    ['value'],
    pathOr({}, ['data', model], response),
  );

  if (value) {
    return value;
  } else {
    reportError?.(new MissingKeyError(contentType, id));
    return `${contentType}: ${id}`;
  }
};

export default genericTransformKeyValueData;
