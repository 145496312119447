import { black } from '@pelotoncycle/design-system';
import { css } from 'styled-components';
import { defaultSvgShadowForA11yContrast as sharedDefaultSvgShadowForA11yContrast } from '@peloton/accessibility';
import { highlightBlue, slateBlack } from '@ecomm/colors';

export const focusHighlightStyles = css`
  outline: 2px solid ${highlightBlue};
`;

export const focusHighlight = css`
  &:focus:focus-visible,
  &:focus-within:focus-visible {
    ${focusHighlightStyles}
  }
`;

export const focusHighlightHighContrast = css`
  &:focus,
  &:focus-within {
    ${focusHighlightStyles}
    outline-offset: 1px;
  }
`;

export const defaultTextShadowForA11yContrast = css`
  text-shadow: 2px 2px 6px ${black};
`;

export const lighterTextShadowForA11yContrast = css`
  text-shadow: 1px -1px 5px ${slateBlack};
`;

export const listItemBeforeContent = css`
  &:before {
    content: '\\200B'; /* add zero-width space to work with screenreader */
  }
`;

export const defaultSvgShadowForA11yContrast = css`
  ${sharedDefaultSvgShadowForA11yContrast}
`;
