import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { requiresAuth } from '@studio/auth';
import { Layout } from '@studio/layout';
import type { RegionSlug } from '@studio/regions';
import { TeamUp } from '@studio/teamup';
import type { TeamUpView } from '@studio/teamup';

interface ITeamUpAccountProps {
  region: RegionSlug;
}

export const TeamUpAccount: React.FC<React.PropsWithChildren<ITeamUpAccountProps>> = ({
  region,
}: ITeamUpAccountProps) => {
  const TUView: TeamUpView = 'my_account';

  return (
    <Layout showFooter={false}>
      <TeamUp view={TUView} region={region} />
    </Layout>
  );
};

export default compose(requiresAuth, withRouter)(TeamUpAccount);
