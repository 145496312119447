import React from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';
import Chevron, { ChevronWrapperName } from './Chevron';

type ChevronHeroContentProps = {
  scrollOffset?: number;
  label: string;
};

const ChevronWrapper: React.FC<React.PropsWithChildren<ChevronHeroContentProps>> = ({
  scrollOffset,
  label,
  children,
}) => (
  <ChevronContainer name={ChevronWrapperName}>
    {children}
    <Chevron label={label} scrollOffset={scrollOffset} />
  </ChevronContainer>
);

export default ChevronWrapper;

const ChevronContainer = styled(Element)`
  height: 100%;
  width: 100%;
  position: relative;
`;
