import React from 'react';
import type { ToggleButtonTheme } from '@peloton/buttons';
import { PlayPauseToggle } from '@peloton/buttons';
import { usePromotionalTextData } from '@ecomm/copy';

export type EcommToggleProps = ToggleButtonTheme & {
  clickHandler: () => void;
  pauseButtonLabel?: string;
  playButtonLabel?: string;
  autoPlay?: boolean;
};

export const EcommPlayPauseToggle: React.FC<
  React.PropsWithChildren<EcommToggleProps>
> = ({ clickHandler, pauseButtonLabel, playButtonLabel, ...otherProps }) => {
  const playPauseToggleDefaultPauseLabel = usePromotionalTextData(
    'playPauseToggleDefaultPauseLabel',
  );
  const playPauseToggleDefaultPlayLabel = usePromotionalTextData(
    'playPauseToggleDefaultPlayLabel',
  );

  return (
    <PlayPauseToggle
      clickHandler={clickHandler}
      pauseButtonLabel={pauseButtonLabel || playPauseToggleDefaultPauseLabel}
      playButtonLabel={playButtonLabel || playPauseToggleDefaultPlayLabel}
      {...otherProps}
    />
  );
};

EcommPlayPauseToggle.displayName = 'EcommPlayPauseToggle';

export default EcommPlayPauseToggle;
