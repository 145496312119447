import React from 'react';
import { useStudioOAuth } from '@studio/auth';

export default function Logout() {
  const { logout } = useStudioOAuth();

  React.useEffect(() => {
    logout({});
  }, [logout]);

  return <div data-test-id="logout"></div>;
}
