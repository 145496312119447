import React, { useEffect, useState } from 'react';
import type { Locale } from '@peloton/internationalize';
import {
  theUserIsInTheUnitedStates,
  TEAM_UP_LOCALE_MAP,
  useLocale,
} from '@peloton/internationalize';
import { useStudioOAuth } from '@studio/auth';
import { environmentFlags } from '@studio/env';
import { RegionSlug } from '@studio/regions';
import { generateTUIframe } from './generateTeamUpScript';
import type { TeamUpView } from './models';
import { providerIds, specificPages, teamupIdentityProvider } from './models';

interface ITeamUpProps {
  view?: TeamUpView;
  region?: RegionSlug;
}

interface ITeamUpViewProps {
  view: TeamUpView;
  data?: string;
  providerId?: string;
  specificPage?: string[];
  locale: Locale;
  identityProvider: string;
}

const TeamUpViewContainer = ({
  data,
  view,
  providerId,
  locale,
  specificPage,
  identityProvider,
}: ITeamUpViewProps) => {
  useEffect(() => {
    const iframeScript = document.createElement('script');
    iframeScript.appendChild(
      generateTUIframe(
        data || '',
        view,
        TEAM_UP_LOCALE_MAP[locale] || 'en',
        providerId || '',
        specificPage || [],
        identityProvider,
      ),
    );
    iframeScript.type = 'text/javascript';
    iframeScript.setAttribute('data-test-id', 'team-up-script');
    document.body.appendChild(iframeScript);

    return () => {
      document.body.removeChild(iframeScript);
    };
  }, [data, view, providerId, locale, specificPage, identityProvider]);
  return (
    <div>
      <div id="teamup" data-test-id="TeamUpIframe"></div>
    </div>
  );
};

export const getProviderIdByRegionSlug = (
  regionSlug: RegionSlug | undefined,
  locale: Locale,
): string => {
  const providers = environmentFlags().isProd ? providerIds.prod : providerIds.test;
  let region: RegionSlug;

  if (!regionSlug) {
    region = theUserIsInTheUnitedStates(locale) ? RegionSlug.NewYork : RegionSlug.London;
  } else {
    region = regionSlug;
  }

  return providers[region];
};

function TeamUp({ view = 'schedule.day', region }: ITeamUpProps) {
  const [idToken, setIdToken] = useState<string | undefined>(undefined);
  const { getIdTokenClaims, isLoading, isAuthenticated } = useStudioOAuth();
  const locale = useLocale();
  const providerId = getProviderIdByRegionSlug(region, locale);
  const identityProvider =
    teamupIdentityProvider[environmentFlags().isProd ? 'prod' : 'test'];

  useEffect(() => {
    const checkIdToken = async () => {
      const token = await getIdTokenClaims();
      const resolvedToken = token?.__raw || '';
      setIdToken(resolvedToken === '-1' ? '' : resolvedToken);
    };

    if (isAuthenticated && !idToken) {
      checkIdToken();
    }
  }, [isAuthenticated, getIdTokenClaims, idToken]);

  if (isLoading || (isAuthenticated && !idToken)) {
    return null;
  }

  return (
    <TeamUpViewContainer
      data={idToken || ''}
      view={view}
      providerId={providerId}
      locale={locale}
      specificPage={specificPages[view]}
      identityProvider={identityProvider}
    />
  );
}

export default TeamUp;
