import { Button } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Links } from '@peloton/links';
import { BreakpointEither } from '@peloton/responsive';
import { media, defaultTransition } from '@peloton/styles';
import { ButtonThemes, XSmallSize } from '@ecomm/buttons';
import { Chevron, Orientation } from '@ecomm/icons';
import { useStudioKeyValueData } from '@studio/copy';
import { RegionSlug } from '@studio/regions';
import { buttonCaretBookAClass, buttonCaretFindAClass } from '@studio/styles';
import type { DropdownButtonProps } from './HeaderDropdown';
import HeaderDropdown from './HeaderDropdown';

const HeaderClassDropdown: React.FC<React.PropsWithChildren<unknown>> = () => {
  const bookAClassText = useStudioKeyValueData('studioSite.header.bookAClass');
  const bookClassText = useStudioKeyValueData('studioSite.header.bookClass');
  const updatedNewYorkStudioText = useStudioKeyValueData(
    'studioSite.header.findAClass.newYorkStudio',
  );
  const updatedLondonStudioText = useStudioKeyValueData(
    'studioSite.header.findAClass.londonStudio',
  );
  const menuItems = [
    {
      dataTestId: 'NewYorkStudioLink',
      linkText: updatedNewYorkStudioText,
      to: Links.toStudioSchedule(RegionSlug.NewYork),
    },
    {
      dataTestId: 'LondonStudioLink',
      linkText: updatedLondonStudioText,
      to: Links.toStudioSchedule(RegionSlug.London),
    },
  ];

  return (
    <BreakpointEither breakpoint="tablet">
      <HeaderDropdown
        ButtonComponent={BookAClassButton}
        buttonText={bookClassText}
        dropdownWidth="148px"
        menuItems={menuItems}
      />

      <HeaderDropdown
        ButtonComponent={BookAClassButton}
        buttonText={bookAClassText}
        dropdownWidth="148px"
        menuItems={menuItems}
      />
    </BreakpointEither>
  );
};

const BookAClassButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ text, isOpen, ...rest }, ref) => (
    <BreakpointEither breakpoint="smallScreen">
      <StyledButton
        size="small"
        width={'adaptive'}
        style={{ height: '30px', fontSize: '14px', padding: '0 5px' }}
        {...rest}
        ref={ref}
      >
        {text}
      </StyledButton>
      <BreakpointEither breakpoint="tablet">
        <StyledButton
          size="small"
          width={'adaptive'}
          style={{ height: '30px' }}
          {...rest}
          ref={ref}
        >
          {text}
        </StyledButton>
        <StyledButton size="small" width={'adaptive'} {...rest} ref={ref}>
          {text}
        </StyledButton>
      </BreakpointEither>
    </BreakpointEither>
  ),
);

const FindAClassButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ text, isOpen, ...rest }, ref) => (
    <CtaButton isOpen={isOpen} {...rest} ref={ref}>
      {text}
      <StyledChevron orientation={isOpen ? Orientation.Up : Orientation.Down} />
    </CtaButton>
  ),
);

FindAClassButton.displayName = 'FindAClassButton';
BookAClassButton.displayName = 'BookAClassButton';

const StyledButton = styled(Button)`
  ${buttonCaretBookAClass()}
  align-items: center;
  border-radius: 3px;
  display: flex;
`;

const CtaButton = styled.button<{ isOpen: boolean }>`
  ${ButtonThemes.Button1}
  ${XSmallSize}
  ${buttonCaretFindAClass()}
  align-items: center;
  border-radius: 3px;
  display: flex;
  height: 25px;

  ${media.tablet`
    height: 30px;
  `}
`;

const StyledChevron = styled(Chevron)`
  ${defaultTransition('transform')}
  margin-left: 5px;
  width: 8px;
`;

export default HeaderClassDropdown;
