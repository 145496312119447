import React from 'react';
import { connect } from 'react-redux';
import { scroller } from 'react-scroll';
import styled, { keyframes } from 'styled-components';
import { FocusManager } from '@peloton/accessibility';
import type { ResponsiveSelectorState } from '@peloton/responsive';
import { getIsBreakpointLessThan, getIsBreakpointGreaterThan } from '@peloton/responsive';
import { media } from '@peloton/styles';
import { a11yOutline } from '@ecomm/buttons';
import { white } from '@ecomm/colors';
import {
  HEADER_HEIGHT_MOBILE,
  HEADER_HEIGHT_TABLET_XLARGE,
  HEADER_HEIGHT_DESKTOP_LARGE,
} from '@ecomm/header';
import { ChevronLight, Orientation } from '@ecomm/icons';
import { defaultSvgShadowForA11yContrast } from '@ecomm/styles';

type BreakpointProps = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

type ChevronProps = BreakpointProps & {
  scrollOffset?: number;
  label: string;
};

const mapStateToProps = (state: ResponsiveSelectorState) => ({
  isMobile: getIsBreakpointLessThan(state, 'tabletXLarge'),
  isTablet:
    getIsBreakpointGreaterThan(state, 'tabletXLarge') &&
    getIsBreakpointLessThan(state, 'desktopLarge'),
  isDesktop: getIsBreakpointGreaterThan(state, 'desktopLarge'),
});

const breakpointOffsetMap = {
  isMobile: HEADER_HEIGHT_MOBILE,
  isTablet: HEADER_HEIGHT_TABLET_XLARGE,
  isDesktop: HEADER_HEIGHT_DESKTOP_LARGE,
};

const Chevron: React.FC<React.PropsWithChildren<ChevronProps>> = ({
  scrollOffset,
  label,
  ...breakpointBools
}) => {
  const breakpoint = Object.keys(breakpointBools).find(bp => !!breakpointBools[bp]);
  const offset = breakpoint ? breakpointOffsetMap[breakpoint] : 0;

  return (
    <ChevronWrapper
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        handleChevronClick(offset, e.target as HTMLButtonElement, scrollOffset)();
      }}
    >
      <ScrollChevron title={label} />
    </ChevronWrapper>
  );
};

export default connect(mapStateToProps)(Chevron);

const ChevronWrapper = styled.button`
  position: absolute;
  bottom: 0;
  margin-top: 40px;
  transform: translateX(-50%);
  left: 50%;
  ${a11yOutline};

  ${media.tablet`
    margin-top: 60px;
  `}

  svg {
    pointer-events: none;
  }
`;

const bounce = keyframes`
  from {
    transform: translate3d(0, -8px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

export const ScrollChevron = styled(ChevronLight).attrs({
  orientation: Orientation.Down,
  role: 'presentation',
})`
  ${defaultSvgShadowForA11yContrast};
  animation: ${bounce} 0.8s infinite alternate ease-in-out;
  cursor: pointer;
  fill: ${white};
  width: 33px;

  ${media.tablet`
    width: 48px;
  `}

  ${media.desktop`
    width: 68px;
    height: 50px;
  `}
`;

export const ChevronWrapperName = 'HeroContentWrapper';

const handleChevronClick = (
  offset: number,
  target: HTMLButtonElement,
  scrollOffset?: number,
) => () => {
  const hero = document.querySelector('.hero-wrapper') as HTMLElement;
  if (hero) {
    const yPos = hero.getBoundingClientRect().height - (scrollOffset || offset || 0);
    scroller.scrollTo(ChevronWrapperName, {
      duration: 500,
      smooth: 'easeInOutQuad',
      offset: yPos,
    });

    target.focus();

    FocusManager.passFocusToNextInQuerySelector('#root', {
      shouldIncludeElementsWithTabIndex: true,
    });
  }
};
