import React from 'react';
import { Helmet } from 'react-helmet';
import { ErrorBoundary } from '@peloton/error-reporting';
import type { Locale } from '@peloton/internationalize';

type Props = {
  description: string;
  imageUrl: string;
  keywords: string;
  lang: string;
  linkElements: JSX.Element[];
  locale: Locale;
  ogType: string;
  ogUrl: string;
  title: string;
};

const MetadataTags: React.FC<React.PropsWithChildren<Props>> = ({
  description,
  imageUrl,
  keywords,
  lang,
  linkElements,
  locale,
  ogType,
  ogUrl,
  title,
}) => (
  <ErrorBoundary renderError={() => <></>}>
    <Helmet htmlAttributes={{ lang }}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:description" content={description} />
      <meta property="og:image:height" content={'627'} />
      <meta property="og:image:width" content={'1200'} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={ogUrl} />
      {linkElements}
    </Helmet>
  </ErrorBoundary>
);

export default MetadataTags;
