import { white } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { unorphanLast } from '@peloton/text';
import { Image } from '@ecomm/image';
import { useStudioImageData, useStudioKeyValueData } from '@studio/copy';
import DescriptionComponent from './DescriptionComponent';

const MobileLayout: React.FC<React.PropsWithChildren<unknown>> = () => {
  const experienceHeader = useStudioKeyValueData('pgHome.info.experienceHeader');
  const experienceDescription = useStudioKeyValueData(
    'studioSite.pgHome.info.experienceBody',
  );
  const broadcastHeader = useStudioKeyValueData('pgHome.info.broadcastHeader');
  const broadcastDescription = useStudioKeyValueData('pgHome.info.broadcastBody');
  const spaceHeader = useStudioKeyValueData('studioSite.pgHome.info.spaceHeader');
  const spaceDescription = useStudioKeyValueData('studioSite.pgHome.info.spaceBody');
  const {
    image: {
      mobile: { url: experienceImageSrc, description: experienceImageAlt },
    },
  } = useStudioImageData('studioSite.pgHome.info.experienceImage');
  const {
    image: {
      mobile: { url: broadcastImageSrc, description: broadcastImageAlt },
    },
  } = useStudioImageData('studioSite.pgHome.info.broadcastImage');
  const {
    image: {
      mobile: { url: spaceImageSrc, description: spaceImageAlt },
    },
  } = useStudioImageData('studioSite.pgHome.info.spaceImage');
  return (
    <Container data-test-id="mobile-layout">
      <ImageContainer>
        <Image
          src={experienceImageSrc}
          alt={experienceImageAlt}
          breakpointOptions={{
            mobile: {
              src: experienceImageSrc,
              width: 380,
            },
            tabletXLarge: {
              src: experienceImageSrc,
              width: 540,
            },
          }}
        />
      </ImageContainer>
      <DescriptionComponent
        header={unorphanLast(experienceHeader)}
        description={unorphanLast(experienceDescription)}
      />
      <ImageContainer>
        <Image
          src={broadcastImageSrc}
          alt={broadcastImageAlt}
          breakpointOptions={{
            mobile: {
              src: broadcastImageSrc,
              width: 380,
            },
            tabletXLarge: {
              src: broadcastImageSrc,
              width: 540,
            },
          }}
        />
      </ImageContainer>
      <DescriptionComponent
        header={unorphanLast(broadcastHeader)}
        description={unorphanLast(broadcastDescription)}
      />
      <ImageContainer>
        <Image
          src={spaceImageSrc}
          alt={spaceImageAlt}
          breakpointOptions={{
            mobile: {
              src: spaceImageSrc,
              width: 380,
            },
            tabletXLarge: {
              src: spaceImageSrc,
              width: 540,
            },
          }}
        />
      </ImageContainer>
      <DescriptionComponent
        header={unorphanLast(spaceHeader)}
        description={unorphanLast(spaceDescription)}
      />
    </Container>
  );
};

export default MobileLayout;

const Container = styled.div`
  background: ${white};
  padding: 0 20px 0 20px;

  ${media.tablet`
    padding: 0 50px 0 50px;
  `}

  ${media.tabletXLarge`
    padding: 0 115px 0 115px;
  `}
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: 80%;
  }
`;
