import { Icon, black, white, grey, brand } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';

interface ISelectProps {
  items: string[];
  value: string;
  label: string;
  id: string;
  name: string;
  required?: boolean;
  onChange: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface DivProps extends React.HTMLAttributes<HTMLDivElement> {
  hasSelection?: boolean;
}

function ExtendedDiv({ hasSelection, children, ...props }: DivProps) {
  return <div {...props}>{children}</div>;
}

function Select(props: ISelectProps) {
  const { value, items, id, label, onChange } = props;
  const hasSelection = !!value && value.length > 0;

  return (
    <SelectContainer hasSelection={hasSelection}>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledSelectInput
        {...props}
        value={value}
        id={id}
        onChange={(e: any) => onChange(e.target.value)}
      >
        <option value=""></option>
        {items.map(x => (
          <option key={x} value={x}>
            {x}
          </option>
        ))}
      </StyledSelectInput>
      <IconContainer>
        <Icon name="chevron" height={12} rotate={-90} />
      </IconContainer>
    </SelectContainer>
  );
}

const SelectContainer = styled(ExtendedDiv)`
  line-height: normal;
  font-weight: normal;
  -webkit-font-smoothing: auto;
  position: relative;
  color: ${black};
  background-color: ${white};
  border: 1px solid ${grey[70]};
  border-radius: 4px;
  -webkit-transition: box-shadow 100ms ease-in-out;
  transition: box-shadow 100ms ease-in-out;
  -webkit-transition: border-color 100ms ease-in-out;
  transition: border-color 100ms ease-in-out;
  outline: none;
  box-shadow: 0 0 0 0 transparent;

  ${props =>
    props.hasSelection &&
    `
      > label {
        font-size: 10px;
        -webkit-transform: translateY(-7px);
        -ms-transform: translateY(-7px);
        transform: translateY(-7px);
      }
  `}

  &:focus-within {
    border-color: ${brand.darkest};

    > label {
      font-size: 10px;
      -webkit-transform: translateY(-7px);
      -ms-transform: translateY(-7px);
      transform: translateY(-7px);
    }
  }
`;

const StyledLabel = styled.label`
  line-height: normal;
  font-weight: normal;
  -webkit-font-smoothing: auto;
  display: inline-block;
  font-family: 'Inter', sans-serif;
  -webkit-transition: font-size 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  -webkit-transition: font-size 100ms ease-in-out, transform 100ms ease-in-out;
  transition: font-size 100ms ease-in-out, transform 100ms ease-in-out;
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: transparent;
  color: ${grey[70]};
  font-size: 1rem;
`;

const StyledSelectInput = styled.select`
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  padding: calc((1.5rem + 0.125rem) - 4px) 1rem 0.75rem;
  font-size: 1rem;
  line-height: 20px;
  height: 54px;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: ${brand.darkest};

  &::-ms-expand {
    display: none;
  }

  &:focus {
    box-shadow: inset 0 0 0 1px;
    border-radius: 3px;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  height: auto;
`;

export default Select;
