import React from 'react';
import type { MenuItemType } from './HeaderDropdown';
import MenuListItemWithButton from './menuListItems/MenuListItemWithButton';
import type { MenuItemLink } from './menuListItems/MenuListItemWithLink';
import MenuListItemWithLink from './menuListItems/MenuListItemWithLink';

const isLink = (item: any): item is MenuItemLink => 'to' in item;

enum ItemType {
  'LINK' = 'link',
  'BUTTON' = 'button',
}
const getItemType = (item: any): ItemType =>
  isLink(item) ? ItemType.LINK : ItemType.BUTTON;

type UniversalMenuListItemProps = {
  handleMenuItemKeyDown: (e: React.KeyboardEvent) => void;
  item: MenuItemType;
  menuItemRef: React.RefObject<HTMLElement>;
};

const renderLink = (props: any) => <MenuListItemWithLink {...props} />;
const renderButton = (props: any) => <MenuListItemWithButton {...props} />;

const MenuItem: React.FC<React.PropsWithChildren<UniversalMenuListItemProps>> = props => {
  return (
    <>
      {getItemType(props.item) === ItemType.BUTTON
        ? renderButton(props)
        : renderLink(props)}
    </>
  );
};

export default MenuItem;
