import React from 'react';
import { ClientProvider as PClientProvider } from '@peloton/api';
import type { PropsOf } from '@peloton/react';
import {
  useAuthorizationHeader,
  useInvalidateTokenHeader,
  useLoadAuthorizedUser,
} from './authorizationHooks';

export const ClientProvider: React.FC<
  React.PropsWithChildren<PropsOf<typeof PClientProvider>>
> = ({ client, children }) => {
  useAuthorizationHeader(client);
  useInvalidateTokenHeader(client);
  useLoadAuthorizedUser();
  // useStudioCheckSession(); will fail login due to race condition, unsure if needed for now.

  return <PClientProvider children={children} client={client} />;
};
