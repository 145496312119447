import { track } from '@peloton/analytics';

export const trackSelectedLinkItem = (linkText: string) => {
  const loweredCaseLinkText = linkText.toLowerCase();
  if (
    ['london studio', 'new york studio', 'london', 'new york'].includes(
      loweredCaseLinkText,
    )
  ) {
    const cleanedLinkText = loweredCaseLinkText.replace(' studio', '');

    track({
      event: 'Selected Studio',
      properties: {
        studio: cleanedLinkText,
      },
    });
  } else if (loweredCaseLinkText === 'instructors') {
    track({
      event: 'Clicked Experience Instructors',
    });
  }
};
