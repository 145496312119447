import React from 'react';
import { useSelector } from 'react-redux';
import PelotonInternationalize, {
  toCountry,
  toCurrency,
  toLanguage,
  toLocale,
} from '@peloton/internationalize';
import type { PropsOf } from '@peloton/react';
import { Context as ContentfulContext, useContentfulClient } from '@studio/copy';
import localizedStrings from '@studio/copy/static';
import { getIsToggleActive } from '@studio/feature-toggles';
import { TAG } from '../copy/config';

type Props = PropsOf<typeof PelotonInternationalize>;

const Internationalize: React.FC<React.PropsWithChildren<Props>> = ({
  country = toCountry(),
  currency = toCurrency(country),
  language = toLanguage(country),
  locale = toLocale(language, country),
  ...props
}) => {
  const isPreview = useSelector(getIsToggleActive('previewCopy'));
  const client = useContentfulClient(isPreview, locale, TAG);

  return (
    <ContentfulContext.Provider value={client}>
      <PelotonInternationalize
        locale={locale}
        messages={localizedStrings[locale]}
        {...props}
      />
    </ContentfulContext.Provider>
  );
};

export default Internationalize;
