import { useLocale } from '@peloton/internationalize';
import { useStudioKeyValueData } from '@studio/copy';
import { trackViewedCallbackPage } from './analytics';
import { LOCAL_STORAGE_REDIRECT_PATH_KEY } from './constants';
import { getOauthFragment } from './oAuthFragment';
import useStudioOAuth from './useStudioOAuth';

const SignupRedirect = () => {
  const successToast = useStudioKeyValueData('studio.signupRedirect.success');
  const redirectPath = localStorage.getItem(LOCAL_STORAGE_REDIRECT_PATH_KEY) || '/';
  const { loginWithRedirect } = useStudioOAuth(redirectPath);
  const locale = useLocale();
  trackViewedCallbackPage('signupRedirect');
  loginWithRedirect({
    fragment: getOauthFragment(locale, successToast),
  });

  return null;
};

export default SignupRedirect;
