import React from 'react';
import { Links } from '@peloton/links';
import { useStudioKeyValueData } from '@studio/copy';
import type { MenuItemType } from './HeaderDropdown';
import HeaderDropdown from './HeaderDropdown';

const HeaderExperienceDropdown: React.FC<React.PropsWithChildren<unknown>> = () => {
  const headerExperienceText = useStudioKeyValueData('header.experience');
  const headerInstructorsText = useStudioKeyValueData('header.instructors');
  const headerFAQText = useStudioKeyValueData('header.faq');
  const experienceMenuItems: MenuItemType[] = [
    {
      dataTestId: 'instructors',
      linkText: headerInstructorsText,
      target: '_blank',
      to: Links.instructors,
    },
    {
      dataTestId: 'faqs',
      linkText: headerFAQText,
      to: Links.studioSupport,
    },
  ];

  return (
    <HeaderDropdown
      buttonText={headerExperienceText}
      testId={'ExperienceMenuButton'}
      dropdownWidth="148px"
      menuItems={experienceMenuItems}
    />
  );
};

export default HeaderExperienceDropdown;
