import { brand } from '@pelotoncycle/design-system';
import { css } from 'styled-components';
import { media } from '@peloton/styles';
import type { HeroSize } from './Hero';

const standardHeroStyle = css`
  height: 370px;

  ${media.tablet`
    height: 550px;
  `}

  ${media.desktop`
    height: 760px;
  `}
`;

const shortHeroStyle = css`
  height: 300px;

  ${media.tablet`
    height: 527px;
  `}

  ${media.desktop`
    height: 577px;
  `}
`;

export const heroStyles = css`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: ${brand.darkest};

  ${({ heroSize }: { heroSize?: HeroSize }) =>
    heroSize === 'standard' && standardHeroStyle}
  ${({ heroSize }: { heroSize?: HeroSize }) => heroSize === 'short' && shortHeroStyle}
`;
